import axios, { AxiosRequestConfig, AxiosResponse, AxiosInstance } from "axios";
import { DEFAULT_VALUE } from "../utils/constant";
import defaultRequest from "./defaultRequest";
import defaultRequestError from "./defaultRequestError";
import defaultResponse from "./defaultResponse";
import defaultResponseError from "./defaultResponseError";

type interceptorType = {
  setRequestInterceptor?: (
    config: AxiosRequestConfig
  ) => AxiosRequestConfig | Promise<AxiosRequestConfig>;
  setRequestInterceptorError?: (config: AxiosRequestConfig) => void;
  setResponseInterceptor?: (
    response: AxiosResponse
  ) => AxiosResponse<any> | Promise<AxiosResponse<any>>;
  setResponseInterceptorError?: (response: AxiosResponse) => void;
};

const defaultInterceptors: interceptorType = {
  setRequestInterceptor: defaultRequest,
  setRequestInterceptorError: defaultRequestError,
  setResponseInterceptor: defaultResponse,
  setResponseInterceptorError: defaultResponseError,
};

const defaultConfig: AxiosRequestConfig = {
  baseURL: "",
  timeout: DEFAULT_VALUE.TIMEOUT,
};

export const createInstance = (
  config: AxiosRequestConfig = defaultConfig,
  interceptors: interceptorType = {}
): AxiosInstance => {
  const instance = axios.create(config);

  // AxiosInstance.defaults.headers.common['Authorization'] = property.AUTH_TOKEN;
  instance.defaults.headers.post["Content-Type"] = DEFAULT_VALUE.CONTENT_TYPE;

  const interceptorToSet = { ...defaultInterceptors, ...interceptors };

  instance.interceptors.request.use(
    interceptorToSet.setRequestInterceptor,
    interceptorToSet.setRequestInterceptorError
  );

  instance.interceptors.response.use(
    interceptorToSet.setResponseInterceptor,
    interceptorToSet.setResponseInterceptorError
  );

  return instance;
};
