import { toast } from "react-toastify";
import { GLOBAL_ERROR_KEYS, GLOBAL_ERROR_MESSAGE } from "./errors";
import { encryptURLParam } from "./security";

export const getEntitlementValueWithPath = () => {
  return {
    MODULE: "",
    ACTION: "",
  };
};
export const loginFormatDate = (dateVal) => {
  var newDate = new Date(dateVal);

  var sMonth = padValue(newDate.getMonth() + 1);
  var sDay = padValue(newDate.getDate());
  var sYear = newDate.getFullYear();
  var sHour = newDate.getHours();
  var sMinute = padValue(newDate.getMinutes());
  var sAMPM = "AM";

  var iHourCheck = sHour;

  if (iHourCheck > 12) {
    sAMPM = "PM";
    sHour = iHourCheck - 12;
  } else if (iHourCheck === 0) {
    sHour = 12;
  }

  sHour = padValue(sHour);

  return (
    sDay +
    "-" +
    sMonth +
    "-" +
    sYear +
    " " +
    sHour +
    ":" +
    sMinute +
    " " +
    sAMPM
  );
};
const padValue = (value) => {
  return value < 10 ? "0" + value : value;
};
export const showToast = (status, message, correlationId = null) => {
  toast.dismiss();
  if (status === "success") {
    toast.success(message, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  }
  if (status === "error") {
    if (correlationId !== null) {
      message += `\n Ref ID: ${correlationId}`;
    }
    toast.error(message, {
      position: "top-center",
      autoClose: false,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      toastId: "errorToast",
    });
  }
  if (status === "warning") {
    toast.warn(message, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  }
  if (status === "info") {
    toast.info(message, {
      position: "top-center",
      autoClose: false,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  }
};

export const getErrorMessage = (errorCode) => {
  let errorMessage = "";
  if (errorCode in GLOBAL_ERROR_KEYS) {
    errorMessage = GLOBAL_ERROR_MESSAGE[errorCode];
  } else {
    if (errorCode !== undefined && errorCode !== null) {
      errorMessage = GLOBAL_ERROR_MESSAGE[errorCode] + " : " + errorCode;
    } else {
      errorMessage = GLOBAL_ERROR_MESSAGE.COMMON_ERROR;
    }
  }

  if (errorCode !== undefined && errorCode !== null) {
    if (errorCode in GLOBAL_ERROR_KEYS) {
      errorMessage = GLOBAL_ERROR_MESSAGE[errorCode];
    } else {
      errorMessage = GLOBAL_ERROR_MESSAGE.COMMON_ERROR + " : " + errorCode;
    }
  } else {
    errorMessage = GLOBAL_ERROR_MESSAGE.COMMON_ERROR + " : " + errorCode;
  }
  return errorMessage;
};
export const getAuthUserData = (contextData) => {
  let authUser =
    contextData !== null &&
    contextData.authuser !== undefined &&
    Object.keys(contextData.authuser).length > 0
      ? contextData.authuser
      : {};
  return authUser;
};
export const replaceStr = (
  originalStr: string,
  matchString: any,
  replacebleString: any
) => {
  if (
    originalStr &&
    typeof matchString !== "undefined" &&
    typeof replacebleString !== "undefined"
  ) {
    return originalStr.replace(matchString, replacebleString);
  }
  return originalStr;
};
export const getDateForDisp = (date) => {
  if (date !== null) {
    var onlyDate = date.getDate();
    var stringOnlyDate = onlyDate.toString();
    if (onlyDate <= 9) {
      stringOnlyDate = "0" + onlyDate.toString();
    }
    var onlyMonth = date.getMonth();
    var onlyYear = date.getFullYear().toString();
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];
    return stringOnlyDate + "-" + monthNames[onlyMonth] + "-" + onlyYear;
  } else {
    return "";
  }
};

export const getDateTimeForDisp = (date) => {
  if (date !== null) {
    var onlyDate = date.getDate();
    var stringOnlyDate = onlyDate.toString();
    if (onlyDate <= 9) {
      stringOnlyDate = "0" + onlyDate.toString();
    }
    var onlyMonth = date.getMonth();
    var onlyYear = date.getFullYear().toString();
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];
    return (
      stringOnlyDate +
      "-" +
      monthNames[onlyMonth] +
      "-" +
      onlyYear +
      " " +
      date.toLocaleTimeString()
    );
  } else {
    return "";
  }
};

export function ISODateTOShortDate(isoDate) {
  const [yyyy, mm, dd, hh, mi, ss] = isoDate.split(/[/:\-T]/);

  return `${yyyy}/${mm}/${dd} ${hh}:${mi}:${ss}`;
}

export function getTimeDiff(dateFuture, dateNow) {
  let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;

  // calculate days
  const days = Math.floor(diffInMilliSeconds / 86400);
  diffInMilliSeconds -= days * 86400;

  // calculate hours
  const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
  diffInMilliSeconds -= hours * 3600;

  // calculate minutes
  const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
  diffInMilliSeconds -= minutes * 60;

  let difference = "";
  if (days > 0) {
    difference += days === 1 ? `${days} day, ` : `${days} days, `;
  }

  if (hours > 0) {
    difference +=
      hours === 0 || hours === 1 ? `${hours} hr, ` : `${hours} hrs, `;
  }
  if (minutes > 0) {
    difference +=
      minutes === 0 || hours === 1 ? `${minutes} min` : `${minutes} mins`;
  } else {
    difference += `${parseInt(diffInMilliSeconds.toString())} secs`;
  }

  return difference;
}
export const setHighlightRow = (tableId, rowIndex) => {
  let elementCell: any = document.getElementsByClassName(`tableRow`);
  if (elementCell !== undefined) {
    for (let row of elementCell) {
      row.style.backgroundColor = "#FFF";
    }
  }
  let tempRowIndex = rowIndex;
  tempRowIndex++;
  let element: any = document.getElementsByClassName(
    `row${tableId}${tempRowIndex}`
  );
  if (element !== undefined) {
    for (let row of element) {
      row.style.backgroundColor = "#ffd3b9";
    }
  }
};
export const replaceAll = (str, find, replace) => {
  return str.replace(new RegExp(find, "g"), replace);
};

export const getSelectedLOVObject = (data, id) => {
  var returnData = {};
  if (data !== undefined) {
    for (var i = 0; i < data.length; i++) {
      if (id !== null) {
        if (data[i]["id"] === parseInt(id)) {
          returnData = data[i];
          break;
        }
      }
    }
  }
  return returnData;
};
export const getSelectedLOVObjectNonInt = (data, id) => {
  var returnData = {};
  if (data !== undefined) {
    for (var i = 0; i < data.length; i++) {
      if (id !== null) {
        if (data[i]["id"] === id) {
          returnData = data[i];
          break;
        }
      }
    }
  }
  return returnData;
};
export const getSelectedLOVObjectByDescription = (data, description) => {
  var returnData = {};
  if (data !== undefined) {
    for (var i = 0; i < data.length; i++) {
      if (description !== "") {
        if (data[i]["description"] === description) {
          returnData = data[i];
          break;
        }
      }
    }
  }
  return returnData;
};
export const getSelectedLOVObjectByLabel = (data, label) => {
  var returnData = {};
  if (data !== undefined) {
    for (var i = 0; i < data.length; i++) {
      if (label !== "") {
        if (data[i]["label"] === label) {
          returnData = data[i];
          break;
        }
      }
    }
  }
  return returnData;
};

export const getSelectedLovObjectbyValue = (data, value) => {
  if (data !== undefined) {
    let returnObject = data.find((obj) => obj.value === value);
    if (returnObject !== undefined) {
      return returnObject;
    } else {
      return { label: value };
    }
  } else {
    return { label: "" };
  }
};
export const getSelectedLovObjectbyCode = (data, value) => {
  let returnObject = data.find((obj) => obj.code === value);
  if (returnObject !== undefined) return returnObject;
  else return {};
};

export const getSelectedLOVObjectByFlexCode = (data, code) => {
  var returnData = {};
  if (data !== undefined) {
    for (var i = 0; i < data.length; i++) {
      if (code !== "") {
        if (data[i]["flexCode"] === code) {
          returnData = data[i];
        }
      }
    }
  }
  return returnData;
};
export const calculatePagesCount = (pageSize, totalCount) => {
  return totalCount < pageSize ? 1 : Math.ceil(totalCount / pageSize);
};
export const getAppParam = (rowData, mode) => {
  let params = {
    taskId: rowData.taskId,
    applicationId: rowData.applicationId,
    stageCode: rowData.stage,
    id: rowData.id,
    cif: rowData.cif,
    exceptions: rowData.exceptions,
    module: rowData.module,
    mode: mode,
    applicationStageId: rowData.applicationStageId,
  };
  return encryptURLParam(JSON.stringify(params));
};

export const getAge = (d1: any, d2: any) => {
  let diff = d2.getTime() - d1.getTime();
  return Math.floor(diff / (1000 * 60 * 60 * 24 * 365.25));
};

export const convertLabelToId = (label) => {
  let newString = replaceAll(label, " ", "-");
  return newString;
};
export const convertDateObjToReqFormat = (date) => {
  if (date !== null) {
    let onlyDate = date.getDate();
    let stringOnlyDate = onlyDate.toString();
    if (onlyDate <= 9) {
      stringOnlyDate = "0" + onlyDate.toString();
    }
    let onlyMonth = date.getMonth() + 1;
    let stringOnlyMonth = onlyMonth.toString();
    if (onlyMonth <= 9) {
      stringOnlyMonth = "0" + onlyMonth.toString();
    }
    let onlyYear = date.getFullYear().toString();
    let fullDateString =
      onlyYear + "-" + stringOnlyMonth + "-" + stringOnlyDate;
    if (fullDateString === "10000-01-01") {
      return null;
    } else {
      return fullDateString;
    }
  } else {
    return null;
  }
};
export const convertDateObjToDBFormat = (date) => {
  if (date !== null) {
    var onlyDate = date.getDate();
    var stringOnlyDate = onlyDate.toString();
    if (onlyDate <= 9) {
      stringOnlyDate = "0" + onlyDate.toString();
    }
    var onlyMonth = date.getMonth();
    let onlyMonthString = onlyMonth.toString();
    if (onlyMonth <= 9) {
      onlyMonthString = "0" + onlyMonth.toString();
    }
    var onlyYear = date.getFullYear().toString();

    let hours = date.getHours();
    let stringHours = hours.toString();
    if (hours <= 9) {
      stringHours = "0" + hours.toString();
    }
    let minutes = date.getMinutes();
    let stringMinutes = minutes.toString();
    if (minutes <= 9) {
      stringMinutes = "0" + minutes.toString();
    }
    let seconds = date.getSeconds();
    let stringSeconds = seconds.toString();
    if (seconds <= 9) {
      stringSeconds = "0" + seconds.toString();
    }

    return `${onlyYear}-${onlyMonthString}-${stringOnlyDate}T${stringHours}:${stringMinutes}:${stringSeconds}.637`;
  } else {
    return "";
  }
};
export const convertIDtoLabel = (value) => {
  let lab = replaceAll(value, "_", " ");
  return lab;
};
export const saveButtonAction = (action, value: any = null) => {
  if (action === "ENABLE") {
    let elementInput = document.getElementById("valueChange");
    if (elementInput !== undefined && elementInput !== null) {
      elementInput["value"] = "1";
    }
    let elementButton = document.getElementById("saveButton");
    if (elementButton !== undefined && elementButton !== null) {
      elementButton.classList.remove("Mui-disabled");
    }
  }
  if (action === "DISABLE") {
    let elementInput = document.getElementById("valueChange");
    if (elementInput !== undefined && elementInput !== null) {
      elementInput["value"] = "0";
    }
    let elementButton = document.getElementById("saveButton");
    if (elementButton !== undefined && elementButton !== null) {
      elementButton.classList.add("Mui-disabled");
    }
  }
  if (action === "INPUT_VAL") {
    let element = document.getElementById("valueChange");
    if (element !== undefined && element !== null) {
      return element["value"];
    } else {
      return "";
    }
  }
  if (action === "SET_INPUT_VAL") {
    let element = document.getElementById("valueChange");
    if (element !== undefined && element !== null) {
      element["value"] = value;
    }
  }
};
export const getGroupDescriptionById = (list, id) => {
  let description = "";
  for (let rows of list) {
    if (rows.groupId === id) {
      description = rows.description;
      break;
    }
  }
  return description;
};
export const setApplicationTitle = (title) => {
  try {
    let span: any = document.getElementById("applicationTitle");
    span.textContent = title;
  } catch (e) {
    console.log(e);
  }
};
