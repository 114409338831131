//@ts-nocheck
import { Grid } from "components/material-ui";
import { SuspenseLoading, UnderlineText } from "components/ui";
import { lazy, Suspense, useEffect, useRef } from "react";
import { SYSTEM_PAGE_TITLE } from "util/constants";

const TeamList = lazy(() => import("./TeamList"));
const TeamUserList = lazy(() => import("./TeamUserList"));

function Teams() {
  const refTeamList = useRef<any>();
  const refreshTeamUserTable = useRef<any>();

  useEffect(() => {
    document.title = SYSTEM_PAGE_TITLE.USER_MANAGEMENT_TEAMS;
  }, []);

  return (
    <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
      <span style={{ marginBottom: 10, display: "block" }}>
        <UnderlineText>
          <span className="heading">CASA/NEO - UPA | Teams</span>
        </UnderlineText>
      </span>
      <Grid container spacing={2} columns={16}>
        <Grid item xs={8}>
          <Suspense fallback={SuspenseLoading}>
            <TeamList
              ref={refTeamList}
              refreshTeamUserTable={refreshTeamUserTable}
            />
          </Suspense>
        </Grid>
        <Grid item xs={8}>
          <Suspense fallback={SuspenseLoading}>
            <TeamUserList
              ref={refreshTeamUserTable}
              refTeamList={refTeamList}
            />
          </Suspense>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Teams;
