import { SideBarWrapper } from "components/ui";
import { useNavigate } from "react-router-dom";
import { Grid, Icons } from "components/material-ui";
import * as RoutePath from "routes/config";
import { Outlet } from "react-router-dom";
import rcmsLogo from "assets/images/rjlogolg.png";
import mashreqLogo from "assets/images/rjlogosm.png";
import Header from "common/header";
import {
  BTEC_ENT_MOD_KEY,
  BTEC_KEY,
  CANCELLATION_MOD_KEY,
  DEPOST_ENT_MOD_KEY,
  DEPOST_MOD_KEY,
  DE_POST_SEARCH_APP_KEY,
  PAC_SEARCH_APP_KEY,
  PICK_CAPTURE_KEY,
  PICK_CAPTURE_MOD_KEY,
  REPLACEMENT_MOD_KEY,
  SEARCH_APP_KEY,
  TASK_MANAGEMENT_ENT_MOD_KEY,
  USER_MANAGEMENT_ENT_MOD_KEY,
} from "util/constants";
import { StateContext } from "context/context";
import { useContext } from "react";
import { setApplicationTitle } from "util/helper";

export default function Entry() {
  const navigate = useNavigate();
  const contextData = useContext(StateContext);
  var userType =
    contextData.authuser !== undefined ? contextData.authuser.userType : "";
  var entitlements =
    contextData.authuser !== undefined ? contextData.authuser.entitlements : {};
  var groups =
    contextData.authuser !== undefined ? contextData.authuser.groups : {};
  var isSupervisor =
    contextData.authuser !== undefined
      ? contextData.authuser.isSupervisor
      : false;

  const options: any = [
    {
      name: "Dashboard",
      link: RoutePath.DASHBOARD,
      Icon: <Icons.DashboardGridIcon />,
      Id: 1,
    },
  ];

  if (userType !== "" && (userType === "Customer" || userType === "Admin")) {
    options.push({
      name: "New Request",
      link: RoutePath.NEW_REQUEST,
      Icon: <Icons.PencilEdit sx={{ color: "black" }} />,
      Id: 1,
    });
  }

  if (userType !== "" && userType === "Admin") {
    options.push({
      name: "Create User",
      link: RoutePath.CREATE_USERS,
      Icon: <Icons.UserAddIcon />,
      Id: 2,
    });
    options.push({
      name: "User Management",
      link: RoutePath.USERS,
      Icon: <Icons.MultipleUsersIcon />,
      Id: 2,
    });
  }

  if (entitlements !== undefined && entitlements !== null) {
    if (
      entitlements.hasOwnProperty(TASK_MANAGEMENT_ENT_MOD_KEY) &&
      groups !== null &&
      Object.keys(groups).length > 0
    ) {
      options.push({
        name: "My Team Task",
        link: RoutePath.MY_TASKLIST,
        Icon: <Icons.MultipleUsersIcon />,
        Id: 2,
      });
      if (isSupervisor) {
        options.push({
          name: "Task Reassignment",
          link: RoutePath.REASSIGN_TASKLIST,
          Icon: <Icons.ToDoIcon />,
          Id: 3,
        });
      }
    }

    if (entitlements.hasOwnProperty(PICK_CAPTURE_MOD_KEY)) {
      if (entitlements[PICK_CAPTURE_MOD_KEY].includes(PICK_CAPTURE_KEY)) {
        options.push({
          name: "Pick and Capture",
          link: RoutePath.DASHBOARD,
          Icon: <Icons.BoxBallotIcon width={20} height={20} />,
          Id: 6,
        });
      }
    }

    if (entitlements.hasOwnProperty(BTEC_ENT_MOD_KEY)) {
      if (entitlements[BTEC_ENT_MOD_KEY].includes(BTEC_KEY)) {
        options.push({
          name: "Balance Transfer - EC",
          link: RoutePath.DASHBOARD,
          Icon: <Icons.TransferIcon width={20} height={20} />,
          Id: 6,
        });
      }
    }
    if (entitlements.hasOwnProperty(DEPOST_ENT_MOD_KEY)) {
      if (entitlements[DEPOST_ENT_MOD_KEY].includes(DEPOST_MOD_KEY)) {
        options.push({
          name: "DE Post",
          link: RoutePath.DASHBOARD,
          Icon: <Icons.InvoiceIcon width={20} height={20} />,
          Id: 6,
        });
      }
    }
    if (
      entitlements.hasOwnProperty(DEPOST_ENT_MOD_KEY) ||
      entitlements.hasOwnProperty(PICK_CAPTURE_MOD_KEY) ||
      entitlements.hasOwnProperty(BTEC_ENT_MOD_KEY) ||
      entitlements.hasOwnProperty(CANCELLATION_MOD_KEY) ||
      entitlements.hasOwnProperty(REPLACEMENT_MOD_KEY)
    ) {
      if (
        (entitlements.hasOwnProperty(DEPOST_ENT_MOD_KEY) &&
          entitlements[DEPOST_ENT_MOD_KEY].includes(DE_POST_SEARCH_APP_KEY)) ||
        (entitlements.hasOwnProperty(PICK_CAPTURE_MOD_KEY) &&
          entitlements[PICK_CAPTURE_MOD_KEY].includes(PAC_SEARCH_APP_KEY)) ||
        (entitlements.hasOwnProperty(BTEC_ENT_MOD_KEY) &&
          entitlements[BTEC_ENT_MOD_KEY].includes(SEARCH_APP_KEY)) ||
        (entitlements.hasOwnProperty(CANCELLATION_MOD_KEY) &&
          entitlements[CANCELLATION_MOD_KEY].includes(SEARCH_APP_KEY)) ||
        (entitlements.hasOwnProperty(REPLACEMENT_MOD_KEY) &&
          entitlements[REPLACEMENT_MOD_KEY].includes(SEARCH_APP_KEY))
      ) {
        options.push({
          name: "Search Application",
          link: RoutePath.SEARCH_APP,
          Icon: <Icons.SearchAppOutlineIcon width={20} height={20} />,
          Id: 4,
        });
      }
    }
    if (entitlements.hasOwnProperty(USER_MANAGEMENT_ENT_MOD_KEY)) {
      // User Management
      options.push({
        name: "User Management",
        Icon: <Icons.UserAddIcon />,
        items: [],
        Id: 5,
      });

      options[options.length - 1].items.push({
        name: "Users",
        link: RoutePath.USERS,
        route: "management",
      });

      options[options.length - 1].items.push({
        name: "Teams",
        link: RoutePath.TEAMS,
        route: "management",
      });
    }
  }

  return (
    <>
      <SideBarWrapper
        options={options}
        onRedirect={(link) => {
          window.document.getElementById("hideSideWrapper")?.click();
          navigate(link);
          setApplicationTitle("");
        }}
        rcmsLogo={rcmsLogo}
        mashreqLogo={mashreqLogo}
      >
        <Header />
        <Grid
          container
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ padding: "15px" }}
        >
          <Outlet />
        </Grid>
      </SideBarWrapper>
    </>
  );
}
