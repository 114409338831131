import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
  Icons,
} from "components/material-ui";
import "./index.css";

export interface ExpandablePanelProps {
  content: any;
  title: any;
  expanded: boolean;
  onChange?: any;
  marginTop?: any;
  paddingTop?: any;
  hideArrow?: boolean;
  status?: string;
  style?: any;
}

const ExpandablePanel: React.FC<ExpandablePanelProps> = ({
  title,
  content,
  expanded,
  onChange,
  marginTop,
  paddingTop,
  hideArrow,
  status,
  style,
}): JSX.Element => {
  return (
    <Accordion
      expanded={expanded}
      onChange={onChange}
      style={{
        width: "100%",
        ...style,
      }}
    >
      <AccordionSummary
        expandIcon={!hideArrow ? <Icons.ArrowDown /> : ""}
        aria-controls={"idEx" + title.toString().charAt(0)}
        id={"idEx" + title.toString().charAt(0)}
      >
        <Grid container item={true} xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
            <Typography>{title}</Typography>
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
            {status !== undefined && status !== "" ? (
              <>
                {status === "In Progress" ? (
                  <span
                    style={{
                      width: 100,
                      float: "right",
                      marginRight: 10,
                      background: "orange",
                      color: "white",
                      borderRadius: 50,
                      textAlign: "center",
                      paddingTop: 2,
                    }}
                  >
                    In Progress
                  </span>
                ) : status === "Rejected" ? (
                  <span
                    style={{
                      width: 100,
                      float: "right",
                      marginRight: 10,
                      background: "rgb(231, 76, 60)",
                      color: "white",
                      borderRadius: 50,
                      textAlign: "center",
                      paddingTop: 2,
                    }}
                  >
                    Failed
                  </span>
                ) : status === "Completed" ? (
                  <span
                    style={{
                      width: 100,
                      float: "right",
                      marginRight: 10,
                      background: "rgb(75, 139, 59)",
                      color: "white",
                      borderRadius: 50,
                      textAlign: "center",
                      paddingTop: 2,
                    }}
                  >
                    {status === "Completed" ? "completed" : ""}
                  </span>
                ) : (
                  <span
                    style={{
                      width: 100,
                      float: "right",
                      marginRight: 10,
                      background: "rgb(60,115,168)",
                      color: "white",
                      borderRadius: 50,
                      textAlign: "center",
                      paddingTop: 2,
                    }}
                  >
                    {status === "Waiting" ? "Waiting" : ""}
                  </span>
                )}
              </>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
      </AccordionSummary>

      <AccordionDetails
        style={{
          padding:
            paddingTop !== undefined
              ? `${paddingTop}px 15px 12px 12px`
              : "15px 15px 12px 12px",
        }}
      >
        {expanded && content}
      </AccordionDetails>
    </Accordion>
  );
};
export default ExpandablePanel;
