import { memo } from "react";
import App from "../App";
import AppErrorBoundry from "./AppErrorBoundry";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ConfirmDialog from "common/confirm_dialog";
import AlertDialog from "components/AlertDialog";

const Setup = () => {
  return (
    <>
      <AppErrorBoundry>
        <ConfirmDialog />
        <AlertDialog />
        <App />
      </AppErrorBoundry>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ width: "500px" }}
      />
    </>
  );
};

export default memo(Setup);
