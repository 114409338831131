import { Box, Grid, styled, FormLabel } from "components/material-ui";
import {
  FormControlLabel,
  Radio as BaseRadio,
  RadioGroup,
} from "components/material-ui";
import { Controller } from "react-hook-form";
import { radioButtonProps, radioButtonStyleProps } from "./type";
import "./index.css";

export const CustomRadio = styled(BaseRadio)(
  ({ disabled }: radioButtonStyleProps) => ({
    paddingRight: 3,
    "&.Mui-checked": {
      color: disabled ? "none" : "#e92220",
      backgroundColor: disabled ? "none" : "rgba(255, 94, 0, 0.04)",
    },
    "&:hover": {
      backgroundColor: "rgba(255, 94, 0, 0.04)",
    },
    "& .MuiSvgIcon-root": {
      height: 15,
      width: 15,
    },
  })
);

const MRadio = (props: radioButtonProps) => {
  let {
    name,
    disabled,
    grid,
    control,
    label,
    required,
    ruleList,
    onInputChange,
    options,
    inputValue,
    className,
  } = props;

  let rules: object = {};

  if (required) {
    rules = {
      required: " Required",
      ...ruleList,
    };
  } else {
    rules = {
      required: false,
      ...ruleList,
    };
  }

  return (
    <Grid
      item
      xs={grid}
      sm={grid}
      md={grid}
      lg={grid}
      xl={grid}
      className={className}
    >
      {control !== null ? (
        <Controller
          name={name}
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Box className={"mRadioBox"}>
              <FormLabel
                style={{
                  fontSize: "1rem",
                  display: "inline-block",
                  fontWeight: "500",
                  color: "#000",
                }}
              >
                {label}
              </FormLabel>
              <RadioGroup
                value={value}
                onChange={(e: any) => {
                  onChange(e);
                  if (onInputChange !== null) {
                    onInputChange(e);
                  }
                }}
                style={{
                  display: "inline-block",
                  marginLeft: 15,
                  verticalAlign: 2,
                }}
              >
                <div className="radioButton">
                  {options !== undefined && options.length > 0 ? (
                    <>
                      {options.map((item: any, index: any) => (
                        <FormControlLabel
                          value={item.value}
                          control={<CustomRadio />}
                          label={item.value}
                          disabled={disabled}
                        />
                      ))}
                    </>
                  ) : (
                    <>
                      <FormControlLabel
                        value="Y"
                        control={<CustomRadio />}
                        label="Yes"
                        disabled={disabled}
                      />
                      <FormControlLabel
                        value="N"
                        control={<CustomRadio />}
                        label="No"
                        disabled={disabled}
                      />
                    </>
                  )}
                </div>
              </RadioGroup>
            </Box>
          )}
          rules={rules}
        />
      ) : (
        <Box className={"mRadioBox"}>
          <FormLabel
            style={{
              fontSize: "1rem",
              display: "inline-block",
              fontFamily: "Google-Sans !important",
            }}
          >
            {label}
          </FormLabel>
          <RadioGroup
            value={inputValue}
            onChange={(e: any) => {
              if (onInputChange !== null) {
                onInputChange(e);
              }
            }}
            style={{
              display: "inline-block",
              marginLeft: 15,
              verticalAlign: 2,
            }}
          >
            <div className="radioButton">
              {options !== undefined && options.length > 0 ? (
                <>
                  {options.map((item: any, index: any) => (
                    <FormControlLabel
                      value={item.value}
                      control={<CustomRadio />}
                      label={item.value}
                      disabled={disabled}
                    />
                  ))}
                </>
              ) : (
                <>
                  <FormControlLabel
                    value="Y"
                    control={<CustomRadio />}
                    label="Yes"
                    disabled={disabled}
                  />
                  <FormControlLabel
                    value="N"
                    control={<CustomRadio />}
                    label="No"
                    disabled={disabled}
                  />
                </>
              )}
            </div>
          </RadioGroup>
        </Box>
      )}
    </Grid>
  );
};
MRadio.defaultProps = {
  onChange: null,
  disabled: false,
  control: null,
  sideLabel: false,
  required: false,
  ruleList: {},
};
export default MRadio;
