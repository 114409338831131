import React from "react";
import AppMenuItem from "./AppMenuItem";
import { List } from "components/material-ui";

interface AppMenuProps {
  isHideShowMenu: boolean;
  appMenuItems: any[];
  onRedirect: Function;
  rcmsLogo: any;
  mashreqLogo: any;
}
const AppMenu: React.FC<AppMenuProps> = ({
  isHideShowMenu,
  appMenuItems,
  onRedirect,
  rcmsLogo,
  mashreqLogo,
}) => {
  return (
    <List component="nav" className={"appMenu"} disablePadding>
      {isHideShowMenu ? (
        <img src={rcmsLogo} className="sidemenulogo" alt="mashreqLogo" />
      ) : (
        <img src={mashreqLogo} className="sidemenuIcon" alt="mashreqLogo" />
      )}

      {appMenuItems.map((item, index) => (
        <AppMenuItem
          {...item}
          key={index}
          isHideShowMenu={isHideShowMenu}
          onRedirect={onRedirect}
        />
      ))}
    </List>
  );
};

export default AppMenu;
