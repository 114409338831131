import { Action } from "redux";
import {
  AUTHENTICATE_USER,
  ERROR,
  SIDEMENU,
} from "../actions/appContextActions";

export interface ReduxAction extends Action {
  payload: any;
}

const initialState: any = {
  authuser: {},
  sidemenu: {},
  errorState: {},
};

export const AppContextReducer = (state, action: ReduxAction) => {
  switch (action.type) {
    case AUTHENTICATE_USER:
      return {
        ...state,
        authuser: action.payload,
      };
    case SIDEMENU:
      return {
        ...state,
        sidemenu: action.payload,
      };
    case ERROR:
      return {
        ...state,
        errorState: action.payload,
      };
    default:
      return initialState;
  }
};
