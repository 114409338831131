import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Divider,
  ListItemText,
  ListItem,
  ListItemIcon as BaseListItemIcon,
  List,
  Collapse,
  styled,
  Icons,
} from "components/material-ui";
import { Box, Tooltip } from "components/material-ui";

// React runtime PropTypes
export const AppMenuItemPropTypes = {
  name: PropTypes.string.isRequired,
  link: PropTypes.string,
  Id: PropTypes.number,
  route: PropTypes.string,
  Icon: PropTypes.any,
  items: PropTypes.array,
  isHideShowMenu: PropTypes.bool,
  history: PropTypes.object,
  onRedirect: PropTypes.any,
};

type AppMenuItemPropType = PropTypes.InferProps<typeof AppMenuItemPropTypes>;
type AppMenuItemPropsWithoutItems = Omit<AppMenuItemPropType, "items">;

// Improve child items declaration
export type AppMenuItemProps = AppMenuItemPropsWithoutItems & {
  items?: AppMenuItemProps[];
};

export const MUIListItemIcon = styled(BaseListItemIcon)(({}: any) => ({
  minWidth: 35,
  fontSize: "1.2rem",
}));
const AppMenuItem: React.FC<AppMenuItemProps> = (props) => {
  const {
    name,
    Icon,
    Id,
    link,
    items = [],
    isHideShowMenu,
    onRedirect,
  } = props;
  const [showListItem, setShowListItem] = React.useState(false);

  const [openId, setOpenId] = useState(0);

  const isExpandable = items && items.length > 0;

  const handleClick = (id) => {
    if (openId === id) {
      setOpenId(0);
    } else {
      setOpenId(id);
    }
    if (link !== undefined) {
      onRedirect(link);
    }
  };
  const onOverMouse = () => {
    setShowListItem(true);
  };
  const onOutMouse = () => {
    setShowListItem(false);
  };
  const MenuItemRoot = (
    <ListItem
      button
      key={Id}
      onClick={() => handleClick(Id)}
      style={isHideShowMenu ? {} : { display: "inline-block" }}
      className={"menuItem"}
    >
      {Icon !== undefined && Icon !== null && (
        <MUIListItemIcon className={"menuItemIcon"}>{Icon}</MUIListItemIcon>
      )}

      <ListItemText
        primary={isHideShowMenu ? name : !Icon ? name : ""}
        inset={!Icon}
        className={!Icon ? "menuItemText" : ""}
      />
      {isHideShowMenu ? (
        <>
          {isExpandable && openId !== Id && <Icons.ArrowDown />}
          {isExpandable && openId === Id && <Icons.ArrowUp />}
        </>
      ) : (
        ""
      )}
    </ListItem>
  );

  const MenuItemChildren = isExpandable ? (
    <Collapse
      in={openId === Id}
      timeout="auto"
      unmountOnExit
      className={
        !isHideShowMenu
          ? showListItem
            ? "displayOnMouse"
            : "displayOnMouseOff"
          : ""
      }
    >
      <Divider />

      <List component="div" disablePadding key={Id}>
        {items.map((item, index) => (
          <AppMenuItem {...item} key={index} onRedirect={onRedirect} />
        ))}
      </List>
    </Collapse>
  ) : null;
  return (
    <>
      <Box onMouseEnter={onOverMouse} onMouseLeave={onOutMouse}>
        {Icon ? (
          !isHideShowMenu && ((isExpandable && !openId) || !isExpandable) ? (
            <Tooltip title={name} placement="right" arrow={true}>
              {MenuItemRoot}
            </Tooltip>
          ) : (
            MenuItemRoot
          )
        ) : (
          MenuItemRoot
        )}
        {MenuItemChildren}
      </Box>
    </>
  );
};

AppMenuItem.propTypes = AppMenuItemPropTypes;

export default AppMenuItem;
