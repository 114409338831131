//@ts-nocheck
import React, { useEffect, useState } from "react";
import { Grid } from "components/material-ui";
import PrimeDetails from "../general/primeDetails";
import { MButton, UnderlineText } from "components/ui";
import { API_STATUS_SUCCESS } from "util/constants";
import { getErrorMessage, replaceAll, showToast } from "util/helper";
import * as Endpoints from "network/Endpoints";
import { useFetchAPI } from "network/useFetchAPI";
import MakerConclusion from "../general/makerConclusion";
import { confirmDialog } from "common/confirm_dialog";

export const CCardDetailsFunc = React.forwardRef((props: any, ref) => {
  const { tabData, loading } = props;
  const [decisionList, setDecisionList] = useState([]);

  const {
    execute: getDecisionList,
    response: getDecisionListResponse,
    loading: getDecisionListLoading,
  } = useFetchAPI(
    replaceAll(Endpoints.GET_DECISION_LIST, "stagecode", "CSU_CHECKER"),
    {
      method: "GET",
    }
  );

  useEffect(() => {
    if (!getDecisionListLoading && getDecisionListResponse) {
      let { status, data, errorCode } = getDecisionListResponse;
      if (status === API_STATUS_SUCCESS) {
        if (data !== undefined) {
          setDecisionList(data);
        } else {
          showToast("error", getErrorMessage(errorCode));
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDecisionListLoading, getDecisionListResponse]);

  useEffect(() => {
    getDecisionList();
  }, []);

  const decisionAction = (decision, status) => {
    if (status === "Approve") {
      confirmDialog(
        "Are you sure want to Approve this card for cancellation?",
        () => {}
      );
    }
  };

  return (
    <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <UnderlineText>
          <span className="heading">Maker Conclusion</span>
        </UnderlineText>
        <MakerConclusion
          makerData={tabData["decisionDetailList"]}
          loading={loading}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={1}>
        <UnderlineText>
          <span className="heading">Prime Details</span>
        </UnderlineText>
        <PrimeDetails tabData={tabData} loading={loading} />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={2}>
        {decisionList.map((decision: any) => (
          <MButton
            theme={decision.nextStageCode === "Reject" ? "dismiss" : "default"}
            style={{ marginLeft: 10 }}
            onClick={(e) => {
              decisionAction(decision, decision.nextStageCode);
            }}
          >
            {decision.nextStageCode}
          </MButton>
        ))}
        {/* <MButton
          onClick={(e) => {
            confirmDialog(
              "Are you sure want to Approve this card for cancellation?",
              () => {}
            );
          }}
        >
          Approve
        </MButton>
        <MButton
          theme="dismiss"
          style={{ marginLeft: 10 }}
          onClick={(e) => {
            confirmDialog(
              "Are you sure want to Reject this card for cancellation?",
              () => {}
            );
          }}
        >
          Reject
        </MButton> */}
      </Grid>
    </Grid>
  );
});
const CCardDetails = React.memo(CCardDetailsFunc);
export default CCardDetails;
