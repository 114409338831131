import { toast } from "react-toastify";
import Routers from "routes/routers";

function App() {
  return (
    <div
      className={"root"}
      onClick={(e) => {
        toast.dismiss("errorToast");
      }}
    >
      <Routers />
    </div>
  );
}

export default App;
