//@ts-nocheck
import { Box } from "components/material-ui";
import { MTextField, MButton, CircularProgress } from "components/ui";
import { useForm } from "react-hook-form";
import { getRule } from "util/validations";
import packageJson from "../../../../package.json";
import Grid from "@mui/material/Grid";
import EmpireAELogo from "assets/images/rjlogolg.png";

const SignUpForm = ({ onSubmit, checkLoginLoading }) => {
  const { control, handleSubmit } = useForm({
    mode: "onTouched",
    defaultValues: {
      firstName: "",
      lastName: "",
      username: "",
      mobileNo: "",
      password: "",
    },
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
        <Grid
          container
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: "25vh" }}
        >
          <Box
            style={{
              padding: "3%",
              borderRadius: 20,
            }}
          >
            <img
              src={EmpireAELogo}
              alt="Empire AE Logo"
              style={{ margin: "auto", width: "45vh", display: "block" }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <span className="loginText1">Sign Up</span>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <span className="loginText2">Sign up with Email address</span>
        </Grid>
        <Box style={{ marginTop: 20, width: "100%" }}>
          <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
            <MTextField
              name={"firstName"}
              label={"First Name"}
              control={control}
              grid={6}
              required={true}
            />
            <MTextField
              name={"lastName"}
              label={"Last Name"}
              control={control}
              grid={6}
              required={true}
            />
          </Grid>
        </Box>
        <Box style={{ marginTop: 20, width: "100%" }}>
          <MTextField
            name={"username"}
            label={"Username (Email)"}
            control={control}
            grid={12}
            required={true}
            ruleList={getRule("emailAddress")}
          />
          <MTextField
            number={true}
            name={"phoneNumber"}
            label={"Phone Number (optional)"}
            control={control}
            grid={12}
            ruleList={getRule("mobileNo")}
          />
        </Box>
        <Box style={{ marginTop: 10, width: "100%" }}>
          <MTextField
            password={true}
            name={"password"}
            label={"Password"}
            control={control}
            grid={12}
            required={true}
            ruleList={getRule("password")}
          />
        </Box>
        <Box
          style={{
            marginTop: 10,
            marginLeft: 5,
            marginRight: 5,
            width: "100%",
          }}
        >
          <MButton
            type={"submit"}
            style={{
              marginTop: 10,
              width: "100%",
            }}
            disabled={checkLoginLoading}
          >
            {checkLoginLoading ? (
              <CircularProgress colors={"white"} size={20} />
            ) : (
              "Sign Up"
            )}
          </MButton>
        </Box>
      </Grid>
    </form>
  );
};

export default SignUpForm;
