import { Tooltip } from "components/material-ui";
import "./index.css";

export const ReadOnlyHeader = (props) => {
  const { children } = props;
  return <span className="readOnlyField_Header">{children}</span>;
};
export const ReadOnlyText = (props) => {
  const { value, style } = props;
  return (
    <Tooltip
      title={value !== undefined && value !== null && value !== "" ? value : ""}
      placement="bottom-start"
    >
      <span className="readOnlyField_Text" style={style}>
        {value !== undefined && value !== null && value !== "" ? (
          value
        ) : (
          <span style={{ opacity: 0 }}>NA</span>
        )}
      </span>
    </Tooltip>
  );
};
