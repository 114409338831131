var crypto = require("crypto");

export const encryptString = (unencryptedString) => {
  try {
    let stringKey = "DEE27856C0695A4ECF7BB417";
    var key = new Buffer(stringKey);
    let ivString = makeid(8);
    var iv = new Buffer(ivString);
    var plaintext = unencryptedString;
    var alg = "des-ede3-cbc";
    var autoPad = true;

    var cipher = crypto.createCipheriv(alg, key, iv);
    cipher.setAutoPadding(autoPad); //default true
    var ciph = cipher.update(plaintext, "utf8", "hex");
    ciph += cipher.final("hex");
    return ciph + ":" + ivString;
  } catch (e) {
    console.log(e);
    return "";
  }
};
export const encryptURLParam = (unencryptedString) => {
  try {
    let stringKey = "DEE27856C0695A4ECF7BB417";
    var key = new Buffer(stringKey);
    var iv = new Buffer("s6GyPoMv");
    var plaintext = unencryptedString;
    var alg = "des-ede3-cbc";
    var autoPad = true;

    var cipher = crypto.createCipheriv(alg, key, iv);
    cipher.setAutoPadding(autoPad); //default true
    var ciph = cipher.update(plaintext, "utf8", "hex");
    ciph += cipher.final("hex");
    return ciph;
  } catch (e) {
    console.log(e);
    return "";
  }
};
export const decryptURLParam = (ciph) => {
  let stringKey = "DEE27856C0695A4ECF7BB417";
  var iv = new Buffer("s6GyPoMv");
  var alg = "des-ede3-cbc";
  var autoPad = true;
  var decipher = crypto.createDecipheriv(alg, stringKey, iv);
  decipher.setAutoPadding(autoPad);
  var txt = decipher.update(ciph, "hex", "utf8");
  txt += decipher.final("utf8");
  return txt;
};
export const makeid = (length) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};
