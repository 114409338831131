import { ReactNode } from "react";
import "./index.css";

interface UnderLineTextProps {
  children: ReactNode;
}

const UnderlineText = (props: UnderLineTextProps) => {
  const { children } = props;

  return <div className="underLineRoot">{children}</div>;
};
export default UnderlineText;
