import { useState } from "react";
import { Box, Grid, styled, FormLabel } from "components/material-ui";
import {
  Checkbox as CheckboxCustom,
  FormGroup,
  FormControlLabel,
} from "components/material-ui";
import { Controller } from "react-hook-form";
import { checkBoxStyleProps, checkBoxProps } from "./type";
import "./index.css";

export const MUICheckbox = styled(CheckboxCustom)(
  ({ sideLabel }: checkBoxStyleProps) => ({
    marginTop: sideLabel ? 13.8 : 0,
    "&.Mui-checked": {
      color: "#e92220",
    },
  })
);

const MCheckBox = (props: checkBoxProps) => {
  let {
    checked,
    onChange,
    name,
    disabled,
    grid,
    control,
    label,
    sideLabel,
    tooltip,
    required,
    ruleList,
    style,
    onInputChange,
  } = props;

  const [showTooltip, setShowTooltip] = useState(false);
  let rules: object = {};

  if (required) {
    rules = {
      required: " Required",
      ...ruleList,
    };
  } else {
    rules = {
      required: false,
      ...ruleList,
    };
  }
  const onCheckValueChange = (onChange: any) => (e) => {
    onChange(e);
    if (onInputChange !== null) {
      onInputChange(e);
    }
  };
  return (
    <Grid item xs={grid} sm={grid} md={grid} lg={grid} xl={grid}>
      {control !== undefined && control !== null ? (
        <Controller
          name={name}
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <>
              <Box
                style={{
                  display: "inline-flex",
                }}
                onMouseEnter={(e) => {
                  if (tooltip !== undefined && tooltip !== null) {
                    setShowTooltip(true);
                  }
                }}
                onMouseLeave={(e) => {
                  if (tooltip !== undefined && tooltip !== null) {
                    setShowTooltip(false);
                  }
                }}
              >
                {sideLabel ? (
                  <Box>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <MUICheckbox
                            name={name}
                            checked={checked}
                            onChange={onCheckValueChange(onChange)}
                            disabled={disabled}
                            sideLabel={sideLabel}
                          />
                        }
                        disabled={disabled}
                        label={label}
                      />
                    </FormGroup>
                  </Box>
                ) : (
                  <div>
                    <FormLabel className={"formLabel"}>{label}</FormLabel>
                    <MUICheckbox
                      name={name}
                      checked={value}
                      onChange={onChange}
                      disabled={disabled}
                      sideLabel={sideLabel}
                    />
                  </div>
                )}
              </Box>
              {showTooltip && tooltip !== "" ? (
                <span className="tooltip">
                  {tooltip} <span className="tooltipTick"></span>
                </span>
              ) : (
                ""
              )}
            </>
          )}
          rules={rules}
        />
      ) : (
        <Box className={""}>
          {sideLabel ? (
            <Box className="checkBoxSide">
              <FormGroup>
                <FormControlLabel
                  control={
                    <MUICheckbox
                      name={name}
                      checked={checked}
                      onChange={onChange}
                      disabled={disabled}
                      sideLabel={sideLabel}
                    />
                  }
                  disabled={disabled}
                  label={label}
                />
              </FormGroup>
            </Box>
          ) : (
            <>
              {label !== "" && (
                <FormLabel className={"formLabel"}>{label}</FormLabel>
              )}
              <MUICheckbox
                name={name}
                checked={checked}
                onChange={onChange}
                disabled={disabled}
                sideLabel={sideLabel}
                style={style}
              />
            </>
          )}
        </Box>
      )}
    </Grid>
  );
};
MCheckBox.defaultProps = {
  onChange: null,
  disabled: false,
  control: null,
  sideLabel: false,
  required: false,
  ruleList: {},
};
export default MCheckBox;
