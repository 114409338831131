import React, { useImperativeHandle, useState } from "react";
import { ExpandablePanel, Grid } from "components/ui";
import RenderGridBox from "components/renderGridBox";

interface CRMDetailsProps {
  loading: any;
}

export const CRMDetailsFunc = React.forwardRef(
  (props: CRMDetailsProps, ref) => {
    const { loading } = props;
    const [expanded, setExpanded] = useState(true);
    const [customerDetails, setCustomerDetails] = useState<any>({
      cif: "",
      bulkUpload: "",
      initatorTeam: "",
      cardNumber: "",
      isCreditCard: "",
      cancellationCode: "",
      MMCFlag: "",
      customerName: "",
      cancellationCodeReason: "",
      crmSettlement: "",
      initiatedBy: "",
    });

    useImperativeHandle(
      ref,
      () => ({
        setDetails: (data) => {
          setCustomerDetails({
            cif: data.cif,
            bulkUpload: data.bulkUpload,
            initatorTeam: data.initatorTeam,
            cardNumber: data.cardNumber,
            isCreditCard: data.isCreditCard,
            cancellationCode: data.cancellationCode,
            MMCFlag: data.MMCFlag,
            customerName: data.customerName,
            cancellationCodeReason: data.cancellationCodeReason,
            crmSettlement: data.crmSettlement,
            initiatedBy: data.initiatedBy,
          });
        },
      }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    );

    const loadContent = () => {
      return (
        <Grid container xs={12} sm={12} md={12} lg={12} xl={12} item={true}>
          <RenderGridBox
            grid={3}
            label={"CIF"}
            value={customerDetails["cif"]}
            isLoading={loading}
          />
          <RenderGridBox
            grid={3}
            label={"Bulk Upload"}
            value={customerDetails["bulkUpload"]}
            isLoading={loading}
          />
          <RenderGridBox
            grid={3}
            label={"Initator Team"}
            value={customerDetails["initatorTeam"]}
            isLoading={loading}
          />
          <RenderGridBox
            grid={3}
            label={"Card Number"}
            value={customerDetails["cardNumber"]}
            isLoading={loading}
          />
          <RenderGridBox
            grid={3}
            label={"Is Credit Card?"}
            value={customerDetails["isCreditCard"]}
            isLoading={loading}
          />
          <RenderGridBox
            grid={3}
            label={"Initiated By"}
            value={customerDetails["initiatedBy"]}
            isLoading={loading}
          />
          <RenderGridBox
            grid={3}
            label={"Cancellation Reason"}
            value={
              customerDetails["cancellationCode"] +
              " - " +
              customerDetails["cancellationCodeReason"]
            }
            isLoading={loading}
          />
          <RenderGridBox
            grid={3}
            label={"CRM Settlement Liability"}
            value={customerDetails["crmSettlement"]}
            isLoading={loading}
          />
        </Grid>
      );
    };
    return (
      <>
        <ExpandablePanel
          marginTop={0}
          content={loadContent()}
          title={<>CRM Details</>}
          expanded={expanded}
          onChange={() => setExpanded(!expanded)}
        />
      </>
    );
  }
);
const CRMDetails = React.memo(CRMDetailsFunc);
export default CRMDetails;
