import { Grid, Skeleton } from "components/material-ui";
import RenderGridBox from "components/renderGridBox";
import React, { useEffect, useState } from "react";

export const MakerConclusionFunc = React.forwardRef((props: any, ref) => {
  const { makerData, loading } = props;

  const [makerDetails, setMakerDetails] = useState<any>(null);

  useEffect(() => {
    let tempMakerDetails = {
      alopCard: "",
      cancellationCode: "",
      cardAgreement: "",
      cardStatus: "",
    };

    const statusUpdate = (item, key) => {
      if (item.status === "PASSED") {
        tempMakerDetails = { ...tempMakerDetails, [key]: item.status };
      } else {
        tempMakerDetails = { ...tempMakerDetails, [key]: item.comment };
      }
    };

    if (makerData) {
      makerData.map((item) => {
        if (item.referReason === "ALOP_CARD_CHECK") {
          statusUpdate(item, "alopCard");
        }
        if (item.referReason === "CANCELLATION_CODE_CHECK") {
          statusUpdate(item, "cancellationCode");
        }
        if (item.referReason === "CARD_STATUS_CHECK") {
          statusUpdate(item, "cardStatus");
        }
        if (item.referReason === "CARD_AGREEMENT_CHECK") {
          statusUpdate(item, "cardAgreement");
        }
      });
    }

    setMakerDetails({ ...tempMakerDetails });
  }, []);

  useEffect(() => {
    console.log("benny", makerDetails);
  }, [makerDetails]);
  return (
    <>
      {makerDetails === null ? (
        <>
          <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
            <div style={{ width: "100%", marginTop: 10 }}>
              <Skeleton height={36} style={{ transform: "initial" }} />
            </div>
            <div style={{ width: "100%", marginTop: 10 }}>
              <Skeleton height={36} style={{ transform: "initial" }} />
            </div>
          </Grid>
        </>
      ) : (
        <Grid container xs={12} sm={12} md={12} lg={12} xl={12} pt={1}>
          <RenderGridBox
            grid={3}
            label={"Alop Card Check"}
            value={makerDetails["alopCard"]}
            isLoading={loading}
          />
          <RenderGridBox
            grid={3}
            label={"Cancellation Code Check"}
            value={makerDetails["alopCard"]}
            isLoading={loading}
          />
          <RenderGridBox
            grid={3}
            label={"Card Agreement Check"}
            value={makerDetails["cardAgreement"]}
            isLoading={loading}
          />
          <RenderGridBox
            grid={3}
            label={"Card Status Block Check"}
            value={makerDetails["cardStatus"]}
            isLoading={loading}
          />
        </Grid>
      )}
    </>
  );
});
const MakerConclusion = React.memo(MakerConclusionFunc);
export default MakerConclusion;
