import {
  Button as BaseButton,
  IconButton,
  styled,
} from "components/material-ui";
import { ButtonProps, ButtonStyleProps } from "./types";
import {
  COMPONENTS_ACTION_DEFAULT,
  COMPONENTS_BUTTON,
} from "../../utils/constants";
import { getColor, getHoverColor } from "../../utils/colors";
import CircularProgress from "../CircularProgress";

export const MUIButton = styled(BaseButton)(
  ({ allcaps, colorcode, colorhovercode, small }: ButtonStyleProps) => ({
    backgroundColor: colorcode,
    color: "white",
    height: small ? "30px" : "40px",
    textTransform: allcaps ? "uppercase" : "none",
    fontFamily: "Google-Sans-SemiBold",
    padding: small ? 0 : "4px 10px",
    fontSize: small
      ? "clamp(0.7rem, 0.9rem, 1rem)"
      : "clamp(1rem, 1rem, 1.5rem)",
    "&:hover": {
      backgroundColor: colorhovercode,
    },
  })
);

export const MUIIconButton = styled(IconButton)(
  ({ colorcode, colorhovercode }: ButtonStyleProps) => ({
    backgroundColor: colorcode,
    color: "white",
    height: 30,
    minWidth: 35,
    width: 35,
    padding: "0px 10px",
    borderRadius: 4,
    "&:hover": {
      backgroundColor: colorhovercode,
    },
  })
);

const MButton = (props: ButtonProps) => {
  let {
    children,
    style,
    className,
    loading,
    allcaps,
    disabled,
    type,
    onClick,
    theme,
    id,
    small,
  } = props;

  return (
    <>
      {type === "icon" ? (
        <MUIIconButton
          color="primary"
          disabled={disabled}
          style={style}
          colorcode={getColor(
            COMPONENTS_BUTTON,
            theme === undefined ? COMPONENTS_ACTION_DEFAULT : theme
          )}
          colorhovercode={getHoverColor(
            COMPONENTS_BUTTON,
            theme === undefined ? COMPONENTS_ACTION_DEFAULT : theme
          )}
          onClick={() => {
            if (onClick !== undefined) {
              onClick();
            }
          }}
        >
          {children}
        </MUIIconButton>
      ) : (
        <MUIButton
          type={type}
          id={id}
          variant="contained"
          color="primary"
          disableElevation={true}
          style={style}
          className={className}
          allcaps={allcaps !== undefined ? allcaps : false}
          disabled={disabled || loading}
          size={"small"}
          colorcode={getColor(
            COMPONENTS_BUTTON,
            theme === undefined ? COMPONENTS_ACTION_DEFAULT : theme
          )}
          colorhovercode={getHoverColor(
            COMPONENTS_BUTTON,
            theme === undefined ? COMPONENTS_ACTION_DEFAULT : theme
          )}
          onClick={() => {
            if (onClick !== undefined) {
              onClick();
            }
          }}
          small={small}
        >
          {loading ? <CircularProgress colors={"white"} size={24} /> : children}
        </MUIButton>
      )}
    </>
  );
};

export default MButton;
