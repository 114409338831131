//@ts-nocheck
import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  styled,
  TextField,
  CircularProgress,
  Popper,
} from "components/material-ui";
import { Autocomplete as AutocompleteCustom } from "components/material-ui";
import { Controller } from "react-hook-form";
import { useDebouncedCallback } from "use-debounce";

import { AutocompleteProps } from "./type";
import "./index.css";

export const MUIAutocomplete = styled(AutocompleteCustom)(({ small }: any) => ({
  "&.MuiAutocomplete-option": {
    fontFamily: "Google-Sans !important",
  },
  "& .MuiFormHelperText-root.Mui-error": {
    color: "#b00020",
    fontWeight: "600",
    fontFamily: "Google-Sans-Bold !important",
    margin: 0,
  },
  "& .MuiFormControl-root .MuiAutocomplete-option ": {
    backgroundColor: "#000",
    color: "red",
  },

  "& .MuiFormControl-root label": {
    color: "#313131",
    fontWeight: "500",
    fontFamily: "Google-Sans !important",
    "&.Mui-focused": {
      color: "#313131",
      fontWeight: "600",
    },
    "&.Mui-error": {
      color: "#b00020",
      fontWeight: "600",
      marginTop: 1,
    },
  },
  "& .MuiFormControl-root .MuiInput-root  input": {
    width: "100%",
    fontFamily: "Google-Sans !important",
    fontSize: "clamp(0.7rem, 1rem, 1.2rem) !important",
    lineHeight: "unset",
  },
  "& .MuiFormControl-root .MuiInput-root": {
    "&:before": {
      border: "none",
    },
    "&:after": {
      border: "none",
    },
    "&:hover": {
      border: "1px solid rgba(0, 0, 0, 0.23)",
    },

    "&.Mui-focused": {
      border: "1px solid rgba(0, 0, 0, 0.23)",
    },
    height: small ? 30 : 35,
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: 5,
    padding: 5,
  },
}));

const MAutoComplete = (props: AutocompleteProps) => {
  let {
    name,
    disabled,
    grid,
    control,
    label,
    required,
    ruleList,
    options,
    isLoading,
    disableClearable,
    optionKey,
    disabledValue,
    absoluteWidth,
    onInputChange,
    remote,
    freeText,
    onTextChange,
    onInputBlur,
    onOpen,
    inputValue,
    errorTrigger,
    small,
  } = props;

  let rules: object = {};
  const [fieldError, setFieldError] = useState({
    status: false,
    message: "",
  });
  if (required) {
    rules = {
      validate: (value) =>
        Object.keys(value === undefined || value === null ? {} : value)
          .length || " Required",
      ...ruleList,
    };
  } else {
    rules = {
      validate: false,
      ...ruleList,
    };
  }
  const debounced = useDebouncedCallback((value) => {
    onTextChange(value);
  }, 1000);

  useEffect(() => {
    if (errorTrigger) {
      setFieldErrorData(true);
    } else {
      setFieldErrorData(false);
    }
  }, [errorTrigger]);
  const getOptionLabelLogic = (optionData) => {
    let optionKeyData = optionKey.split(",");
    let optionKeyDataLength = optionKeyData.length;
    let optionConcat = "";
    if (optionKeyDataLength === 1) {
      return optionData[optionKey] !== undefined ? optionData[optionKey] : "";
    }

    if (optionKeyDataLength > 1) {
      if (optionData[optionKeyData[0]] === undefined) return "";
      for (var i = 0; i < optionKeyDataLength; i++) {
        if (optionData[optionKeyData[i]] === null) continue;
        optionConcat += optionData[optionKeyData[i]];
        if (optionKeyDataLength !== optionKeyDataLength - 1)
          optionConcat += " ";
      }

      return optionConcat;
    }
  };

  const onFieldValueChange = (onChange: any) => (_e, data?: any) => {
    onChange(data);
    if (onInputChange !== null) {
      onInputChange(data);
    }
  };
  const onFieldValueChangeFree = (onChange: any, e) => {
    if (e !== null) {
      let data = {
        id: e.target.value,
        [optionKey]: e.target.value,
      };
      onChange(data);
      if (onInputChange !== null) {
        onInputChange(data);
      }
    }
  };
  const onTextValueBlur = (onBlur: any) => (e) => {
    onBlur(e);
    if (onInputBlur !== null) {
      onInputBlur(e);
    }
  };

  const PopperMy = function (props) {
    return (
      <Popper
        {...props}
        style={{ width: "fit-content" }}
        placement="bottom-start"
      />
    );
  };

  const setFieldErrorData = (status) => {
    if (status) {
      if (!fieldError.status) {
        setFieldError({
          status: true,
          message: "Required",
        });
      }
    }
    if (!status) {
      if (fieldError.status) {
        setFieldError({
          status: false,
          message: "",
        });
      }
    }
  };

  return (
    <Grid
      item
      xs={grid}
      sm={grid}
      md={grid}
      lg={grid}
      xl={grid}
      style={{ paddingLeft: 5, paddingRight: 5, marginTop: 5 }}
    >
      <>
        {control !== null ? (
          <Controller
            name={name}
            control={control}
            render={({
              field: { onChange, value, onBlur },
              fieldState: { error },
            }) => (
              <Box style={{ position: "relative" }}>
                <MUIAutocomplete
                  data-testid={label}
                  options={options}
                  value={value !== undefined ? value : {}}
                  disableClearable={disableClearable}
                  getOptionDisabled={(option: any) =>
                    disabledValue !== undefined && disabledValue.length > 0
                      ? disabledValue.includes(option[optionKey])
                      : false
                  }
                  classes={absoluteWidth ? { paper: "" } : {}}
                  getOptionLabel={(option) => getOptionLabelLogic(option)}
                  small={small}
                  renderInput={(params: any) => (
                    <TextField
                      error={error !== undefined ? Boolean(error) : false}
                      helperText={
                        error
                          ? error.message !== ""
                            ? label + error.message
                            : ""
                          : null
                      }
                      {...params}
                      InputLabelProps={{
                        shrink: true,
                        required: required,
                      }}
                      className={disabled ? "disabledInput" : ""}
                      label={label}
                      variant="standard"
                      size="small"
                      style={{ borderStyle: "solid" }}
                    />
                  )}
                  onChange={onFieldValueChange(onChange)}
                  onBlur={onTextValueBlur(onBlur)}
                  disabled={disabled}
                  freeSolo={remote || freeText}
                  onInputChange={(e) => {
                    if (freeText) {
                      onFieldValueChangeFree(onChange, e);
                    }
                    if (onTextChange !== null && e !== null) {
                      debounced(e.target["value"]);
                    }
                  }}
                  loading={isLoading}
                  clearOnBlur={true}
                />
                {isLoading && remote ? (
                  <CircularProgress
                    size={20}
                    style={{
                      position: "absolute",
                      right: 10,
                      top: 23,
                    }}
                  />
                ) : (
                  <></>
                )}
              </Box>
            )}
            rules={rules}
          />
        ) : (
          <MUIAutocomplete
            data-testid={label}
            PopperComponent={PopperMy}
            options={options}
            disabled={disabled}
            onOpen={onOpen}
            value={inputValue}
            disableClearable={disableClearable}
            getOptionDisabled={(option: any) =>
              disabledValue !== undefined && disabledValue.length > 0
                ? disabledValue.includes(option[optionKey])
                : false
            }
            getOptionLabel={(option) => getOptionLabelLogic(option)}
            // renderOption={(option) => getOptionLabelLogic(option)}
            // isOptionEqualToValue={(option: any) => {
            //   getOptionLabelLogic(option)
            // }}
            renderInput={(params) => (
              <TextField
                className={`${disabled ? "disabledInput" : ""} ${
                  errorTrigger !== null && fieldError.status
                    ? "errorMessageIn"
                    : ""
                }`}
                {...params}
                InputLabelProps={{
                  shrink: true,
                  required: required,
                }}
                label={label}
                variant="standard"
                size="small"
                error={fieldError.status}
                helperText={
                  errorTrigger !== null
                    ? ""
                    : fieldError.status
                    ? fieldError.message
                    : ""
                }
                placeholder={
                  errorTrigger !== null
                    ? fieldError.status
                      ? fieldError.message
                      : ""
                    : ""
                }
              />
            )}
            loading={isLoading}
            onChange={(e, data: any) => {
              if (required) {
                if (Object.keys(data).length === 0) {
                  setFieldErrorData(true);
                } else {
                  setFieldErrorData(false);
                }
              }
              onInputChange(data);
            }}
            onBlur={(e) => {
              if (required && inputValue !== null) {
                if (Object.keys(inputValue).length === 0) {
                  setFieldErrorData(true);
                } else {
                  setFieldErrorData(false);
                }
              }
            }}
          />
        )}
      </>
    </Grid>
  );
};
MAutoComplete.defaultProps = {
  onChange: null,
  disabled: false,
  control: null,
  sideLabel: false,
  required: false,
  ruleList: {},
  options: [],
  isLoading: false,
  disableClearable: true,
  optionKey: "",
  disabledValue: [],
  onInputChange: null,
  remote: false,
  freeText: false,
  onTextChange: null,
  onInputBlur: null,
  onOpen: null,
  errorTrigger: null,
};
export default MAutoComplete;
