import {
  CircularProgress as BaseCircularProgress,
  styled,
} from "components/material-ui";
import { CircularProgressProps, CircularProgressStyleProps } from "./types";

export const MUICircularProgress = styled(BaseCircularProgress)(
  ({ colors }: CircularProgressStyleProps) => ({
    color: colors,
  })
);

const CircularProgress = (props: CircularProgressProps) => {
  let { size, colors } = props;
  return <MUICircularProgress colors={colors} size={size} />;
};

export default CircularProgress;
