import { Grid } from "components/material-ui";
import RenderGridBox from "components/renderGridBox";
import React from "react";

export const PrimeDetailsFunc = React.forwardRef((props: any, ref) => {
  const { tabData } = props;
  let primeDetails = {
    customerName: tabData.customerName ? tabData.customerName : "",
    dunningHistory: tabData.dunningHistory ? tabData.dunningHistory : "",
    cif: tabData.cif ? tabData.cif : "",
    cardId: tabData.cardId ? tabData.cardId : "",
    collateralAccount: tabData.collateralAccount
      ? tabData.collateralAccount
      : "",
    monthOnBook: tabData.monthOnBook ? tabData.monthOnBook : "",
    cardActiveStatus: tabData.cardActiveStatus ? tabData.monthOnBook : "",
    cardIssuer: tabData.cardIssuer ? tabData.cardIssuer : "",
    cardAccountNumber: tabData.cardAccountNumber
      ? tabData.cardAccountNumber
      : "",
    totalDue: tabData.totalDue ? tabData.totalDue : "",
    cardAgreement: tabData.cardAgreement ? tabData.cardAgreement : "",
    outstandingDeals: tabData.outstandingDeals ? tabData.outstandingDeals : "",
    ProductType: tabData.productType ? tabData.productType : "",
    cardType: tabData.cardType ? tabData.cardType : "",
    accountNumber: tabData.accountNo ? tabData.accountNo : "",
    cardNumber: tabData.cardNo ? tabData.cardNo : "",
    creditLimit: tabData.cardLimit ? tabData.cardLimit : "",
  };
  return (
    <Grid container xs={12} sm={12} md={12} lg={12} xl={12} pt={1}>
      <RenderGridBox
        grid={3}
        label={"Customer Name"}
        value={primeDetails["customerName"]}
      />
      <RenderGridBox
        grid={3}
        label={"Dunning History"}
        value={primeDetails["dunningHistory"]}
      />
      <RenderGridBox grid={3} label={"CIF"} value={primeDetails["cif"]} />
      <RenderGridBox
        grid={3}
        label={"Card Id"}
        value={primeDetails["cardId"]}
      />
      <RenderGridBox
        grid={3}
        label={"Collateral Account"}
        value={primeDetails["collateralAccount"]}
      />
      <RenderGridBox
        grid={3}
        label={"Card Active Status"}
        value={primeDetails["cardActiveStatus"]}
      />
      <RenderGridBox
        grid={3}
        label={"Card Issuer"}
        value={primeDetails["cardIssuer"]}
      />
      <RenderGridBox
        grid={3}
        label={"Card Account Number"}
        value={primeDetails["cardAccountNumber"]}
      />
      <RenderGridBox
        grid={3}
        label={"Credit Limit"}
        value={primeDetails["creditLimit"]}
      />
      <RenderGridBox
        grid={3}
        label={"Total Due"}
        value={primeDetails["totalDue"]}
      />
      <RenderGridBox
        grid={3}
        label={"Card Agreement"}
        value={primeDetails["cardAgreement"]}
      />
      <RenderGridBox
        grid={3}
        label={"Outstanding Deals"}
        value={primeDetails["outstandingDeals"]}
      />
      <RenderGridBox
        grid={3}
        label={"Card Type"}
        value={primeDetails["cardType"]}
      />
      <RenderGridBox
        grid={3}
        label={"Account Number"}
        value={primeDetails["accountNumber"]}
      />
    </Grid>
  );
});
const PrimeDetails = React.memo(PrimeDetailsFunc);
export default PrimeDetails;
