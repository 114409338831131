export enum GLOBAL_ERROR_KEYS {
  COMMON_ERROR = "COMMON_ERROR",
  TIMEOUT = "TIMEOUT",
  API_FAILURE = "API_FAILURE",
  INTERNAL_SERVER_ERROR = "INTERNAL_SERVER_ERROR",
  COMMUNICATION_POPUP_ERROR = "COMMUNICATION_POPUP_ERROR",
  BAD_REQUEST = "BAD_REQUEST",
  GLOBAL_SERVER_ERROR = "GLOBAL_SERVER_ERROR",
  RCMS_PROXY_ERROR_1001 = "RCMS-PROXY-ERROR-1001",
  RCMS_PROXY_ERROR_1002 = "RCMS-PROXY-ERROR-1002",
  RCMS_PROXY_ERROR_1003 = "RCMS-PROXY-ERROR-1003",
  RCMS_PROXY_ERROR_1004 = "RCMS-PROXY-ERROR-1004",
  RCMS_PROXY_ERROR_1005 = "RCMS-PROXY-ERROR-1005",
  RCMS_PROXY_ERROR_1006 = "RCMS-PROXY-ERROR-1006",
  RCMS_PROXY_ERROR_1007 = "RCMS-PROXY-ERROR-1007",
  RCMS_PROXY_ERROR_1008 = "RCMS-PROXY-ERROR-1008",
  "RCMS-AUTH-ERROR-1006" = "RCMS-AUTH-ERROR-1006",
  "RCMS-AUTH-ERROR-1007" = "RCMS-AUTH-ERROR-1007",
  "RCMS-LDAP-AUTH-ERROR-1005" = "RCMS-LDAP-AUTH-ERROR-1005",
  "RCMS-5000" = "RCMS-5000",
  RCMS_LENDING_1000 = "RCMS-LENDING-APP-1000",
  SYSTEM_ERROR = "SYSTEM_ERROR",
  BS_NO_ACTIVE_CUSTOMER_TASK = "BS_NO_ACTIVE_CUSTOMER_TASK",
}

export const GLOBAL_ERROR_MESSAGE = {
  COMMON_ERROR:
    "Something went wrong. Contact system administrator if issue persists.",
  TIMEOUT: "Apologies, there was an error.",
  COMMUNICATION_POPUP_ERROR: "Apologies, there was an error.",
  INTERNAL_SERVER_ERROR: "Internal Server with Backend Service!",
  API_FAILURE: "Unable to get details! Please try again",
  BAD_REQUEST: "Apologies, there was an error.",
  GLOBAL_SERVER_ERROR: "Apologies, there was an error, Please try again!",
  "RCMS-LDAP-AUTH-ERROR-1005": "Invalid Authentication!",
  "RCMS-AUTH-ERROR-1007": "You are not registered in system!",
  RCMS_PROXY_ERROR_1001: "An error occured during getting username from token.",
  RCMS_PROXY_ERROR_1002: "Token is expired and not valid anymore.",
  RCMS_PROXY_ERROR_1003:
    "Authentication Failed. Username or Password not valid.",
  RCMS_PROXY_ERROR_1004:
    "Exception Occured while retrieving user DTO from rcms-core service",
  RCMS_PROXY_ERROR_1005: "Exception Occured while validating credentials in AD",
  RCMS_PROXY_ERROR_1006: "Exception Occur while validating credentials",
  RCMS_PROXY_ERROR_1007:
    "Index 0 out of bounds for length 0 -Exception Occur while validating credentials",
  RCMS_PROXY_ERROR_1008: "No Authorization provided for the request",
  "RCMS-5000": "Something went wrong with external service",
  RCMS_LENDING_1000:
    "Some Technical Error is occurred. Please check the logs for more details",
  "RCMS-AUTH-ERROR-1006":
    "User Name is not registered in AD and do raise ticket to System Administrator",
  SYSTEM_ERROR: "Something went wrong with external service",
  BS_NO_ACTIVE_CUSTOMER_TASK: "Given Application ID is not with Customer!",
};
