import { memo } from "react";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import * as RoutePath from "./config";
import Login from "pages/prelogin/login";
import Dashboard from "pages/postlogin/dashboard";
import NEW_REQUEST from "pages/postlogin/requestForm";
import ViewApplication from "pages/postlogin/viewApplication";
import UpdateApplication from "pages/postlogin/updateApplication";

import Signup from "pages/prelogin/signUp";
import ForgotPassword from "pages/prelogin/forgotPassword";
import Entry from "pages/postlogin";
import Teams from "pages/postlogin/user_management/teams";
import Users from "pages/postlogin/user_management/users";
import Cancellation from "pages/postlogin/cancellation";
import CreateUser from "pages/postlogin/user_management/users/createUser";

const Routers = () => {
  return (
    <Router basename="/service">
      <Routes>
        <Route index element={<Login />} />
        <Route path={RoutePath.SIGNUP} element={<Signup />} />
        <Route path={RoutePath.FORGOTPASSWORD} element={<ForgotPassword />} />
        <Route path={"/"} element={<Entry />}>
          <Route path={RoutePath.DASHBOARD} element={<Dashboard />} />
          <Route path={RoutePath.NEW_REQUEST} element={<NEW_REQUEST />} />
          <Route
            path={RoutePath.VIEW_APPLICATION}
            element={<ViewApplication />}
          />
          <Route
            path={RoutePath.UPDATE_APPLICATION}
            element={<UpdateApplication />}
          />

          <Route path={RoutePath.USERS} element={<Users />} />
          <Route path={RoutePath.CREATE_USERS} element={<CreateUser />} />
          <Route path={RoutePath.TEAMS} element={<Teams />} />
          <Route
            path={RoutePath.CANCELLATION_LINK}
            element={<Cancellation />}
          />
        </Route>
      </Routes>
    </Router>
  );
};
export default memo(Routers);
