import { AxiosResponse } from "axios";

export default function defaultResponse(response: AxiosResponse) {
  const {
    data: { hasError },
  } = response;
  switch (response.status) {
    case 200:
      if (hasError) {
        Promise.reject(response);
      }
      return response;
    default:
      return Promise.reject(response);
  }
}
