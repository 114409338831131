import { Grid, Paper } from "components/material-ui";
import { UnderlineText } from "components/ui";
import React, { useEffect, useState } from "react";
// import { useForm } from "react-hook-form";
import { SYSTEM_PAGE_TITLE } from "util/constants";
import * as RoutePath from "routes/config";
import { useNavigate } from "react-router-dom";
// import { APPLICATION_STATUS_TYPES } from "util/constants";
// import ModuleCards from "../general/moduleCards";
import DashBoardItem from "./dashBoardItem";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { API_STATUS_SUCCESS } from "util/constants";
import * as Endpoints from "network/Endpoints";
import { replaceStr, showToast } from "util/helper";
import { useFetchAPI } from "network/useFetchAPI";
import { StateContext } from "context/context";
import NoRecords from "assets/images/empty_apps.png";

import { getAuthUserData } from "util/helper";
import Loader from "components/loader";

export const DashboardFunc = React.forwardRef((props: any, ref) => {
  const contextData = React.useContext(StateContext);
  let authUser = getAuthUserData(contextData);

  useEffect(() => {
    document.title = SYSTEM_PAGE_TITLE.DASHBOARD;
    getApplicationData();
  }, []);

  const navigate = useNavigate();
  let toggleOptions = ["All", "Waiting", "In Progress", "Completed"];
  const [formats, setFormats] = React.useState<any>([]);
  const handleFormat = (
    event: React.MouseEvent<HTMLElement>,
    newFormats: string[]
  ) => {
    if (newFormats.includes("All")) {
      SetFilterData([...taskListData]);
    }
    if (newFormats.includes("Waiting")) {
      let tempListData = [...taskListData];
      tempListData = tempListData.filter((item) => item.status === "Waiting");
      SetFilterData([...tempListData]);
    }
    if (newFormats.includes("In Progress")) {
      let tempListData = [...taskListData];
      tempListData = tempListData.filter(
        (item) => item.status === "In Progress"
      );
      SetFilterData([...tempListData]);
    }
    if (newFormats.includes("Completed")) {
      let tempListData = [...taskListData];
      tempListData = tempListData.filter((item) => item.status === "Completed");
      SetFilterData([...tempListData]);
    }
    setFormats(newFormats);
  };
  const openApplication = (rowData, action) => {
    if (action === "view")
      navigate(RoutePath.VIEW_APPLICATION, {
        state: {
          applicationId: rowData.applicationId,
        },
      });
    if (action === "update")
      navigate(RoutePath.UPDATE_APPLICATION, {
        state: {
          applicationId: rowData.applicationId,
        },
      });
  };

  const [taskListData, setTaskListData] = useState<any>([]);

  const [filterData, SetFilterData] = useState<any>([]);

  useEffect(() => {
    if (taskListData.length > 0) {
      SetFilterData([...taskListData]);
    }
  }, [taskListData]);

  const {
    execute: getApplicationData,
    response: getApplicationDataResponse,
    loading: getApplicationDataLoading,
  } = useFetchAPI(
    replaceStr(
      replaceStr(Endpoints.APPLICATION_GET, "usertype", authUser["userType"]),
      "uid",
      authUser["userId"]
    ),
    {
      method: "GET",
    }
  );
  useEffect(() => {
    if (!getApplicationDataLoading && getApplicationDataResponse) {
      let { status, data, message } = getApplicationDataResponse;
      if (status === API_STATUS_SUCCESS) {
        if (data && data.length > 0) {
          let tempArray: any[] = [];

          data.map((item) => {
            tempArray.push({
              applicationId: item.id,
              assignedTo: item.technicianAssigned
                ? item.technicianAssigned
                : "",
              submittedDate: item.createdAt,
              resolvedDate: item.resolvedOn ? item.resolvedOn : null,
              updatedDate: item.updatedAt,
              issue: item.requestType,
              status: item.appStat,
            });
          });

          setTaskListData([...tempArray]);
        }
      } else {
        showToast("error", message);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getApplicationDataLoading, getApplicationDataResponse]);

  return (
    <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
      <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
        <span style={{ marginBottom: 10, display: "block" }}>
          <UnderlineText>
            <span className="heading">Dashboard</span>
          </UnderlineText>
        </span>
      </Grid>
      <Grid
        item
        xs={4}
        sm={4}
        md={4}
        lg={4}
        xl={4}
        style={{ textAlign: "right" }}
      ></Grid>
      {/* <Grid container columns={15} spacing={1}>
        <Grid item xs={3}>
          <Paper className="cardBox">
            <Typography variant="label">Cancellation</Typography>
            <Typography variant="h4">0</Typography>
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper className="cardBox">
            <Typography variant="label">Replacement</Typography>
            <Typography variant="h4">0</Typography>
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper className="cardBox">
            <Typography variant="label">Pick and Capture</Typography>
            <Typography variant="h4">0</Typography>
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper className="cardBox">
            <Typography variant="label">DE Post</Typography>
            <Typography variant="h4">0</Typography>
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper className="cardBox">
            <Typography variant="label">Balance Transfer - EC</Typography>
            <Typography variant="h4">0</Typography>
          </Paper>
        </Grid>
      </Grid> */}
      <Grid container columns={15}>
        <Grid item xs={3}>
          <div className="cssSelectedPaper"></div>
        </Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={3}></Grid>
      </Grid>
      {/* <ModuleCards /> */}

      <ToggleButtonGroup
        value={formats}
        onChange={handleFormat}
        aria-label="text formatting"
        exclusive
        style={{ display: "flow-root", marginBottom: 5 }}
      >
        {toggleOptions.map((items) => (
          <ToggleButton
            value={items}
            aria-label={items}
            className={
              formats.includes(items)
                ? "selectedToggleButtonStyle"
                : "toggleButtonStyle"
            }
            style={{ marginLeft: 10 }}
          >
            {items}
          </ToggleButton>
        ))}
        {/* <ToggleButton value="In Progress" aria-label="In Progress">
          <Typography variant="h6">In Progress</Typography>
        </ToggleButton>
        <ToggleButton value="Completed" aria-label="Completed">
          <Typography variant="h6">Completed</Typography>
        </ToggleButton> */}
      </ToggleButtonGroup>
      <Grid container xs={12} sm={12} md={12} lg={12} xl={12} mt={1}>
        <Paper className="paperFullWidth">
          {/* <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
            <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
              <MTextField
                name={"CRM Reference Id"}
                label={"CRM Reference ID"}
                grid={2}
                control={control}
                small={true}
              />
              <MTextField
                name={"Card Number"}
                label={"Card Number"}
                grid={3}
                control={control}
                small={true}
                number={true}
              />
              <MTextField
                name={"cif"}
                label={"CIF"}
                grid={3}
                control={control}
                small={true}
                number={true}
              />
              <Grid
                item
                style={{
                  display: "inline-block",
                  alignSelf: "center",
                  marginTop: "2.4vh",
                }}
              >
                <MButton small={true} type={"submit"}>
                  Search
                </MButton>
                <MButton
                  small={true}
                  theme={"dismiss"}
                  style={{ marginLeft: 10 }}
                >
                  Filter
                </MButton>
              </Grid>
            </Grid>
          </form> */}
          <Grid container xs={12} sm={12} md={12} lg={12} xl={12} mt={1}>
            {/* <MashTablePro
              title={"My Applications"}
              id="myClaimTask"
              rows={taskListData}
              columns={taskListColumn}
              search={true}
              height={400}
            /> */}
            {filterData && filterData.length === 0 ? (
              <Grid
                container
                item={true}
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                style={{ height: 240 }}
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  style={{ textAlign: "center" }}
                >
                  <img
                    src={NoRecords}
                    alt="noRecord"
                    style={{ width: "30%" }}
                  />
                  <span style={{ display: "block" }}>
                    No Applications found!
                  </span>
                </Grid>
              </Grid>
            ) : (
              <>
                {filterData.map((item) => (
                  <DashBoardItem
                    appInfo={item}
                    openApplication={openApplication}
                    authUser={authUser}
                  />
                ))}
              </>
            )}
          </Grid>
        </Paper>
      </Grid>
      <Loader enable={getApplicationDataLoading} />
    </Grid>
  );
});
const Dashboard = React.memo(DashboardFunc);
export default Dashboard;
