import React from "react";
import { StateContext } from "../context/context";
import { DispatchContext } from "../context/context";
import * as appContextAction from "../context/actions/appContextActions";
import { GLOBAL_ERROR_MESSAGE, GLOBAL_ERROR_KEYS } from "../util/errors";
import { Snackbar } from "components/material-ui";

const AppErrorBoundry = ({ children }: any) => {
  const { errorState } = React.useContext(StateContext);
  const appContextDispatch = React.useContext(DispatchContext);
  const { errorCode = null } = errorState !== undefined ? errorState : {};

  return (
    <>
      {GLOBAL_ERROR_KEYS !== undefined && errorCode in GLOBAL_ERROR_KEYS ? (
        <Snackbar
          key="timeout_snackbar"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={true}
          onClose={() => {
            appContextDispatch(appContextAction.clearError({}));
          }}
          autoHideDuration={10000}
          message={`${GLOBAL_ERROR_MESSAGE[errorCode]}`}
        />
      ) : null}
      {children}
    </>
  );
};

export default AppErrorBoundry;
