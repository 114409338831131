import { Box, Grid } from "components/material-ui";
import Lottie from "react-lottie";
import { lottieDefaultOptions } from "../../utils/helper";

const SuspenseLoading = () => {
  return (
    <Grid
      container
      item={true}
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      style={{ height: "60vh" }}
    >
      <Box className="centerBoxInAppSearch" style={{ marginLeft: 0 }}>
        <Lottie options={lottieDefaultOptions} />
      </Box>
    </Grid>
  );
};

export default SuspenseLoading;
