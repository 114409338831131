/**
 * Actions Types
 */
export const AUTHENTICATE_USER = "DEBOUNCE_EXECUTING";
export const SIDEMENU = "SIDEMENU_EXECUTING";
export const ERROR = "ERROR";

/**
 * @func addAuthenticateUser
 * @param ``
 */
export const addAuthenticateUser = (payload: any) => ({
  type: AUTHENTICATE_USER,
  payload,
});
export const addSideMenu = (payload: any) => ({
  type: SIDEMENU,
  payload,
});
export const updateErrorId = (payload: any) => ({
  type: ERROR,
  payload,
});
export const clearError = (payload: any) => ({
  type: ERROR,
  payload,
});
