import React from "react";
import Base64 from "../utils/base64";
import { createInstance, isCancel, CancelToken } from "..";
import { ERROR_TYPES } from "../utils/constant";

const API = createInstance();
const pending: any = {};

export const useFetch = (url: string | undefined, options: any = {}) => {
  const [response, setResponse] = React.useState<any>(null);
  const [error, setError] = React.useState<any>(null);
  const [loading, setLoading] = React.useState(false);
  const lastReqRef: any = React.useRef();
  const [headers, setResponseHeaders] = React.useState<any>(null);
  const [reqHeaders, setReqHeader] = React.useState<any>(null);

  const execute = async (val: any = {}) => {
    if (!url) return;
    // generate HashedUrl
    setError(null);
    setResponseHeaders(null);
    setResponse(null);
    let hashedURI: string;
    if (
      (options.method && options.method.toLowerCase() === "post") ||
      options.data
    ) {
      hashedURI = Base64.btoa(
        `${url}~POST~${options.data ? JSON.stringify(options.data) : undefined}`
      );
    } else {
      hashedURI = Base64.btoa(`${url}~GET`);
    }

    try {
      setLoading(true);
      if (pending[hashedURI] && lastReqRef.current) {
        lastReqRef.current();
        delete pending[hashedURI];
      }

      const { headers: optionHeader = {}, ...restOptions } = options;
      const { headers: valHeader = {}, ...restVal } = val;
      const optionParams = {
        ...restOptions,
        ...restVal,
        headers: { ...optionHeader, ...valHeader },
        cancelToken: new CancelToken(async (c: any) => {
          lastReqRef.current = c;
          pending[hashedURI] = true;
        }),
      };
      const res = await API(url, optionParams);

      setLoading(false);
      // TODO: check if res.data is the correct object thtz send and if not handle accordingly
      const data = await res.data;
      const responseHeaders = await res.headers;
      const requestHeaders = await res?.config?.headers;

      setError(null);
      setResponseHeaders(responseHeaders);
      setReqHeader(requestHeaders);
      setResponse(data);
    } catch (err) {
      const error = err as ApiError;
      if (isApiError(error)) {
        if (error.code == "ERR_CANCELED") {
          setError(null);
        } else {
          if (error.code == "ECONNABORTED") {
            setError({ errorType: ERROR_TYPES.TIMEOUT });
            setLoading(false);
          } else {
            setError(error);
            setLoading(false);
          }
        }
      } else if (isCancel(error)) {
        // TODO: If needed we can handle the cancelled request for any use
      } else {
        setError(error);
        setLoading(false);
      }
    }
  };
  return { execute, response, headers, error, loading, reqHeaders };
};

export default useFetch;

interface ApiError {
  code: any;
  error: string;
}

function isApiError(x: unknown): x is ApiError {
  if (x && typeof x === "object" && "code" in x) {
    return true;
  }
  return false;
}
