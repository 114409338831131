import React, { useState } from "react";
import { Grid } from "components/material-ui";
import { ExpandablePanel, MButton, MTextField } from "components/ui";
import { getDateForDisp } from "util/helper";
// , IconButton, Icons
interface DashBoardItemProps {
  appInfo: any;
  openApplication: any;
  authUser: any;
}

export const DashboardItemFunc = React.forwardRef(
  (props: DashBoardItemProps, ref) => {
    const [expanded, setExpanded] = useState(false);
    const { appInfo, openApplication, authUser } = props;

    return (
      <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
        <ExpandablePanel
          marginTop={5}
          style={{ marginTop: 5 }}
          content={
            <>
              <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
                <MTextField
                  name={"applicationId"}
                  grid={3}
                  label={"Application Id"}
                  inputValue={appInfo.applicationId}
                  disabled={true}
                ></MTextField>
                <MTextField
                  name={"issue"}
                  grid={3}
                  label={"Issue"}
                  inputValue={appInfo.issue}
                  disabled={true}
                />
                <MTextField
                  name={"assignedTo"}
                  grid={3}
                  label={"Assigned To"}
                  inputValue={appInfo.assignedTo}
                  disabled={true}
                />
                <MTextField
                  name={"submittedDate"}
                  grid={3}
                  label={"Submitted On"}
                  inputValue={getDateForDisp(new Date(appInfo.submittedDate))}
                  disabled={true}
                />
                <MTextField
                  name={"updatedDate"}
                  grid={3}
                  label={"Updated On"}
                  inputValue={getDateForDisp(new Date(appInfo.updatedDate))}
                  disabled={true}
                />
                <MTextField
                  name={"resolvedDate"}
                  grid={3}
                  label={"Resolved On"}
                  inputValue={
                    appInfo.resolvedDate
                      ? getDateForDisp(new Date(appInfo.resolvedDate))
                      : ""
                  }
                  disabled={true}
                />
                <Grid
                  container
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  style={{ marginTop: 10 }}
                >
                  <MButton
                    onClick={() => openApplication(appInfo, "view")}
                    small={true}
                  >
                    Open
                  </MButton>
                  {authUser["userType"] === "Customer" && (
                    <MButton
                      onClick={() => openApplication(appInfo, "update")}
                      small={true}
                      style={{ marginLeft: 5 }}
                    >
                      Update
                    </MButton>
                  )}
                </Grid>
              </Grid>
            </>
          }
          title={appInfo.applicationId}
          // status={
          //   <span
          //     style={{
          //       width: 250,
          //       float: "right",
          //       marginRight: 10,
          //       background:
          //         emptyInstance !== undefined && emptyInstance
          //           ? "green"
          //           : "#e92220",
          //       color: "white",
          //       borderRadius: 50,
          //       textAlign: "center",
          //       paddingTop: 2,
          //     }}
          //   >
          //     {emptyInstance !== undefined && emptyInstance
          //       ? "No Suspect"
          //       : `Discounted Suspect : ${instanceData.discountedStatus}/${instanceData.noOfSuspect}`}
          //   </span>
          // }
          status={appInfo.status}
          expanded={expanded}
          onChange={() => setExpanded(!expanded)}
        />
      </Grid>
    );
  }
);
const DashBoardItem = React.memo(DashboardItemFunc);
export default DashBoardItem;
