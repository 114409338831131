import React, { useState } from "react";
import { useContext, useEffect } from "react";
import { API_STATUS_ERROR, SYSTEM_PAGE_TITLE } from "util/constants";

import { useNavigate } from "react-router-dom";
import LoginForm from "./LoginForm";
import { LOGIN } from "network/Endpoints";
import * as RouteConfig from "routes/config";
import * as appContextAction from "context/actions/appContextActions";
import { getErrorMessage, showToast } from "util/helper";
import { DispatchContext, StateContext } from "context/context";
import { Grid, OpeningLoading } from "components/ui";
// import { encryptString } from "util/security";
import { motion } from "framer-motion/dist/framer-motion";
import { useFetchAPI } from "network/useFetchAPI";

const Login = () => {
  const navigate = useNavigate();

  const appContextDispatch = useContext(DispatchContext);
  let contextData = React.useContext(StateContext);

  useEffect(() => {
    document.title = SYSTEM_PAGE_TITLE.LOGIN_SCREEN;
    setTimeout(() => {
      setIsLoading(false);
    }, 2500);
  }, []);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (contextData !== null && contextData.authuser !== undefined) {
      if (
        Object.keys(contextData.authuser).length > 0 &&
        contextData.authuser.accessToken
      ) {
        contextData.authuser = {};
      }
    }
    appContextDispatch(appContextAction.addAuthenticateUser({}));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appContextDispatch]);

  const {
    execute: checkLogin,
    response: checkLoginResponse,
    loading: checkLoginLoading,
  } = useFetchAPI(LOGIN, {
    method: "POST",
  });

  useEffect(() => {
    if (checkLoginLoading === false && checkLoginResponse) {
      let { status, data, message } = checkLoginResponse;
      if (status === API_STATUS_ERROR) {
        showToast("error", message);
      } else {
        if (data.authenticated && data.status) {
          appContextDispatch(
            appContextAction.addAuthenticateUser({
              accessToken: data.token,
              userId: data.id,
              loginID: data.username,
              firstName: data.firstName,
              lastName: data.lastName,
              mobileno: data.mobileNo,
              username: data.username,
              userType: data.usertype,
              expoToken: data.expoToken,
              status: data.status,
            })
          );

          navigate(RouteConfig.DASHBOARD);
        } else {
          if (!data.status) {
            showToast("error", "User Status not Active!");
          } else
            showToast("error", getErrorMessage("RCMS-LDAP-AUTH-ERROR-1005"));
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkLoginResponse, checkLoginLoading]);

  const onSubmit = (data) => {
    // navigate(RouteConfig.DASHBOARD);
    // let encryptedPassword = encryptString(data.password);
    checkLogin({
      data: {
        username: data.username,
        password: data.password,
      },
    });
  };

  const OnError = (data) => {};

  return (
    <Grid
      container
      item={true}
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      style={{ background: "#FFF" }}
    >
      {isLoading ? (
        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.4 }}
          style={{ width: "100%" }}
        >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
              <OpeningLoading />
            </Grid>
          </Grid>
        </motion.div>
      ) : (
        <>
          <Grid
            container
            spacing={0}
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: "100vh" }}
          >
            <Grid item xs={10} sm={4} md={4}>
              <LoginForm
                onSubmit={onSubmit}
                OnError={OnError}
                checkLoginLoading={checkLoginLoading}
              />
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default Login;
