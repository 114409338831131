//@ts-nocheck
import {
  Box,
  CircularProgress,
  Grid,
  Icons,
  Paper,
  Tab,
  Tabs,
} from "components/material-ui";
import { MButton, SuspenseLoading } from "components/ui";
import React, { useEffect, useRef, useState, Suspense } from "react";
import { SYSTEM_PAGE_TITLE } from "util/constants";
import { setApplicationTitle } from "util/helper";
import { useLocation } from "react-router-dom";
import InternalError from "assets/images/internal_error.png";
import { API_STATUS_SUCCESS } from "util/constants";
import { getErrorMessage, replaceAll, showToast } from "util/helper";
import * as Endpoints from "network/Endpoints";
import { useFetchAPI } from "network/useFetchAPI";
import AuditHistory from "../general/audit_history";
import CommentList from "../general/comments";
import CRMDetails from "../general/crmDetails";
import CCardDetails from "./CCardDetails";

export const CancellationFunc = React.forwardRef((props: any, ref) => {
  const location: any = useLocation();
  const appInfo =
    location.state && location.state !== undefined ? location.state : {};
  const ref_CrmDetails = useRef<any>(null);
  const [isError, setIsError] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [tabOrderList, setTabOrderList] = useState<any>([]);

  useEffect(() => {
    document.title = SYSTEM_PAGE_TITLE.CANCELLATION;
    if (appInfo !== undefined && Object.keys(appInfo).length > 0) {
      setApplicationTitle("Card Cancellation | #" + appInfo.applicationId);
      getAppDetailsData();
    }
  }, []);

  // let tabOrderList = [
  //   { tabName: "23423423423432", status: null },
  //   { tabName: "23323423403000", status: "APPROVED" },
  //   { tabName: "23323423403000", status: "REJECTED" },
  //   { tabName: "Comment Details" },
  //   { tabName: "Audit History" },
  // ];

  const {
    execute: getAppDetailsData,
    response: getAppDetailsDataResponse,
    loading: getAppDetailsDataLoading,
  } = useFetchAPI(
    replaceAll(
      Endpoints.GET_CARD_CANCELLATION,
      "crmRefId",
      appInfo.applicationId
    ),
    {
      method: "GET",
    }
  );

  useEffect(() => {
    if (!getAppDetailsDataLoading && getAppDetailsDataResponse) {
      let { status, data, errorCode } = getAppDetailsDataResponse;
      if (status === API_STATUS_SUCCESS) {
        if (data !== undefined) {
          let tempTabOrderList: any[] = [
            { tabName: "Comment Details" },
            { tabName: "Audit History" },
          ];
          if (data.crmDetails) {
            ref_CrmDetails?.current.setDetails({
              cif: data.crmDetails.cif ? data.crmDetails.cif : "",
              bulkUpload:
                data.crmDetails.bulkUpload &&
                data.crmDetails.bulkUpload.toLowerCase() === "y"
                  ? "Yes"
                  : "No",
              initatorTeam: data.crmDetails.initiator
                ? data.crmDetails.initiator
                : "",
              cardNumber: data.crmDetails.cardNo ? data.crmDetails.cardNo : "",
              isCreditCard: data.crmDetails.isCreditCard
                ? data.crmDetails.isCreditCard
                : "No",
              cancellationCode: data.crmDetails.cancellationCode
                ? data.crmDetails.cancellationCode
                : "",
              MMCFlag:
                data.crmDetails.mmcFlag &&
                data.crmDetails.mmcFlag.toLowerCase() === "y"
                  ? "Yes"
                  : "No",
              customerName: data.crmDetails.customerName
                ? data.crmDetails.customerName
                : "",
              cancellationCodeReason: data.crmDetails.cancellationCodeReason,
              crmSettlement:
                data.crmDetails.crmSettingLiabilityFlag &&
                data.crmDetails.crmSettingLiabilityFlag.toLowerCase() === "y"
                  ? "Yes"
                  : "No",
              initiatedBy: data.crmDetails.initiatedBy
                ? data.crmDetails.initiatedBy
                : "",
            });
          }
          if (data.primeDetail) {
            data.primeDetail.map((card) => {
              if (card.cardAccountNumber) {
                tempTabOrderList.unshift({
                  tabName: card.cardNo,
                  status: "APPROVED",
                  tabData: card,
                });
              }
            });
            setTabOrderList([...tempTabOrderList]);
          }
        } else {
          setIsError(true);
        }
      } else {
        setIsError(true);
        showToast("error", getErrorMessage(errorCode));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAppDetailsDataLoading, getAppDetailsDataResponse]);

  const WatchIcon = (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 24 24"
      width={props.width}
      height={props.height}
      className={props.className}
    >
      <path d="M12,0C5.383,0,0,5.383,0,12s5.383,12,12,12,12-5.383,12-12S18.617,0,12,0Zm1,12c0,.552-.448,1-1,1h-4c-.552,0-1-.448-1-1s.448-1,1-1h3V6c0-.552,.448-1,1-1s1,.448,1,1v6Z" />
    </svg>
  );
  const SuccessIcon = () => (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      style={{
        backgroundColor: "green",
        width: 20,
        height: 20,
        fill: "white",
        borderRadius: 50,
      }}
      className={"tabIcon"}
    >
      <Icons.TickLine width={12} height={12} />
    </Grid>
  );

  const RejectIcon = (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      id="Capa_1"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      width={props.width}
      height={props.height}
      className={props.className}
    >
      <g>
        <path d="M256,0C114.615,0,0,114.615,0,256s114.615,256,256,256s256-114.615,256-256C511.847,114.678,397.322,0.153,256,0z    M341.333,311.189c8.669,7.979,9.229,21.475,1.25,30.144c-7.979,8.669-21.475,9.229-30.144,1.25c-0.434-0.399-0.85-0.816-1.25-1.25   L256,286.165l-55.168,55.168c-8.475,8.185-21.98,7.95-30.165-0.525c-7.984-8.267-7.984-21.373,0-29.64L225.835,256l-55.168-55.168   c-8.185-8.475-7.95-21.98,0.525-30.165c8.267-7.984,21.373-7.984,29.64,0L256,225.835l55.189-55.168   c7.979-8.669,21.475-9.229,30.144-1.25c8.669,7.979,9.229,21.475,1.25,30.144c-0.399,0.434-0.816,0.85-1.25,1.25L286.165,256   L341.333,311.189z" />
      </g>
    </svg>
  );

  const renderTabIcon = (item) => {
    if (item.status !== undefined && item.status === "APPROVED") {
      return <SuccessIcon />;
    } else if (item.status !== undefined && item.status === "REJECTED") {
      return (
        <span style={{ margin: 0, fill: "red" }}>
          <RejectIcon height={20} width={20} className={"tabIcon"} />
        </span>
      );
    } else if (item.status !== undefined && item.status === null) {
      return (
        <span style={{ margin: 0, fill: "orange" }}>
          {" "}
          <WatchIcon height={20} width={20} className={"tabIcon"} />
        </span>
      );
    } else {
      return <></>;
    }
  };

  return (
    <>
      {isError ? (
        <Grid
          container
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: "84vh" }}
        >
          <Box
            style={{
              background: "white",
              padding: "3%",
              borderRadius: 20,
              boxShadow: "0px 0px 10px #e8e8e8",
            }}
          >
            <img
              src={InternalError}
              alt="InternalError"
              style={{ margin: "auto", width: "45vh", display: "block" }}
            />
            <Box
              style={{
                width: "45vh",
                marginTop: "4%",
                fontSize: "2vh",
                textAlign: "center",
              }}
            >
              Sorry, Look's like There is a some technical issue while
              processing your request. Kindly contact with System
              Administrative.
            </Box>
          </Box>
        </Grid>
      ) : (
        <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
          <CRMDetails ref={ref_CrmDetails} loading={getAppDetailsDataLoading} />

          <Paper className="paperTop">
            <Tabs
              value={selectedTab}
              onChange={(e, newValue) => {
                setSelectedTab(newValue);
              }}
            >
              {tabOrderList.map((item) => (
                <Tab
                  label={item.tabName}
                  icon={renderTabIcon(item)}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    justifyContent: "center",
                    minHeight: "fit-content",
                    height:
                      item.status !== undefined ? "fit-content" : "initial",
                    paddingTop: 15,
                  }}
                />
              ))}
            </Tabs>
          </Paper>
          {getAppDetailsDataLoading ? (
            <Paper className="paperBottom">
              <div style={{ height: 235, width: "100%" }}>
                <div className="centerDiv">
                  <CircularProgress />
                </div>
              </div>
            </Paper>
          ) : (
            <Paper className="paperBottom">
              {tabOrderList.map((item, index) => (
                <>
                  {selectedTab === index &&
                  item.status !== undefined &&
                  item.tabData !== undefined ? (
                    <Suspense fallback={SuspenseLoading}>
                      <CCardDetails tabData={item.tabData} />
                    </Suspense>
                  ) : (
                    ""
                  )}
                  {selectedTab === index &&
                  item.tabName === "Comment Details" ? (
                    <CommentList
                      applicationId={appInfo.applicationId}
                      applicationStageId={{}}
                    />
                  ) : (
                    ""
                  )}
                  {selectedTab === index && item.tabName === "Audit History" ? (
                    <AuditHistory applicationId={appInfo.applicationId} />
                  ) : (
                    ""
                  )}
                </>
              ))}
            </Paper>
          )}

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="actionButtonStyle"
          >
            <MButton>Submit</MButton>
          </Grid>
        </Grid>
      )}
    </>
  );
});
const Cancellation = React.memo(CancellationFunc);
export default Cancellation;
