import { FunctionComponent, useReducer, useEffect } from "react";
import { StateContext, DispatchContext } from "context/context";
import { AppContextReducer } from "context/reducers/appContextReducer";
import Setup from "./Setup";

const SetupDispatch: FunctionComponent = (): JSX.Element => {
  const [appContextState, appContectDispatch] = useReducer(
    AppContextReducer,
    JSON.parse(localStorage.getItem("appContextState") || "{}")
  );
  useEffect(() => {
    localStorage.setItem("appContextState", JSON.stringify(appContextState));
  }, [appContextState]);

  return (
    <DispatchContext.Provider value={appContectDispatch}>
      <StateContext.Provider value={appContextState}>
        <Setup />
      </StateContext.Provider>
    </DispatchContext.Provider>
  );
};

export default SetupDispatch;
