import {
  COMPONENTS_ACTION_DEFAULT,
  COMPONENTS_ACTION_DISMISS,
  COMPONENTS_BUTTON,
} from "./constants";

export const THEME_COLOR = "#e92220";
export const THEME_COLOR_HOVER = "#a9212d";
export const THEME_COLOR_DISMISS = "#353535";
export const THEME_COLOR_DISMISS_HOVER = "#040404";

export const getColor = (component, action) => {
  if (component === COMPONENTS_BUTTON) {
    if (action === COMPONENTS_ACTION_DISMISS) {
      return THEME_COLOR_DISMISS;
    }
    if (action === COMPONENTS_ACTION_DEFAULT) {
      return THEME_COLOR;
    }
  }
};
export const getHoverColor = (component, action) => {
  if (component === COMPONENTS_BUTTON) {
    if (action === COMPONENTS_ACTION_DISMISS) {
      return THEME_COLOR_DISMISS_HOVER;
    }
    if (action === COMPONENTS_ACTION_DEFAULT) {
      return THEME_COLOR_HOVER;
    }
  }
};
