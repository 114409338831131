//@ts-nocheck
import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import {
  InputLabel,
  MenuItem as BaseMenuItem,
  ListItemText as BaseListItemText,
  ListItem,
  FormControl as BaseFormControl,
  Select as BaseSelect,
  FormHelperText,
  styled,
  Grid,
} from "components/material-ui";
import { MButton, MCheckBox, MTextField } from "components/ui";
import "./index.css";
import {
  InputControllerProps,
  FormControlStyleProps,
  SelectControlStyleProps,
} from "./type";
export const CustomFormControl = styled(BaseFormControl)(
  ({ disabled }: FormControlStyleProps) => ({
    width: "100% !important",
    display: "flex !important",
    fullWidth: true,
    background: "white",
    fontSize: "11px",
    color: "#313131",
    padding: "5px",
    "&:focus": {
      border: "none",
      outline: 0,
    },
    "&.Mui-error": {
      borderColor: "rgba(0, 0, 0, 0.23)",
    },
    "& p.MuiFormHelperText-sizeSmall.Mui-error": {
      margin: "0px",
      marginLeft: "0px",
      fontWeight: "600",
      color: "#b00020",
      fontFamily: "Google-Sans-Bold !important",
    },
    label: {
      height: "35px",
      margin: "0px",
      marginTop: "13px",
      marginLeft: "-13px",
      borderRadius: "5px",
      padding: "0px 5px",
      marginBottom: "20px",
      color: disabled ? "red" : "#313131",
      fontWeight: "500",
      fontFamily: "Google-Sans !important",
      "&.Mui-focused": {
        color: "#313131",
        fontWeight: "600",
      },
      "&.Mui-error": {
        marginTop: "13px",
        marginLeft: "-13px",
        borderRadius: "5px",
        padding: "0px 5px",
        marginBottom: "20px",
        borderColor: "rgba(0, 0, 0, 0.23)",
        color: "#b00020",
        fontWeight: "600",
      },
    },
    "& .MuiInputBase-root": {
      marginTop: "15px",
      height: "35px",
    },
  })
);
export const CustomMenuItem = styled(BaseMenuItem)(({}) => ({
  height: 28,
  "&.MuiMenuItem-root": {
    height: "fit-content",
  },

  "&.MuiMenuItem-root .MuiCheckbox-root": {
    padding: "0px 9px !important",
  },
  "&:hover": { backgroundColor: "none" },
  "&.Mui-selected": {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
  },
  "&.Mui-selected:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
  },
}));
export const CustomListItemText = styled(BaseListItemText)(({}) => ({
  ".MuiTypography-root": {
    fontSize: "10px ",
    whiteSpace: "break-spaces",
  },
}));
export const CustomSelect = styled(BaseSelect)(
  ({ disabled }: SelectControlStyleProps) => ({
    "&.MuiOutlinedInput-root": {
      "& fieldset": {
        border: "1px solid rgba(0, 0, 0, 0.23)",
      },
      "&:hover fieldset": {
        border: "1px solid rgba(0, 0, 0, 0.23)",
      },
      "&.Mui-focused fieldset": {
        border: "1px solid rgba(0, 0, 0, 0.23)",
      },
      "&.Mui-error fieldset": {
        borderColor: "rgba(0, 0, 0, 0.23)",
      },
    },
    background: disabled ? "#e6e6e6" : "#fff",
  })
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 6 + ITEM_PADDING_TOP,
      width: 250,
      marginTop: 5,
      zIndex: 10000,
    },
  },
  getContentAnchorEl: null,

  anchorOrgin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrgin: {
    vertical: "top",
    horizontal: "left",
  },

  disablePortal: false,
  disableAutoFocusItem: true,
};

export default function MultiSelect(props: InputControllerProps) {
  const {
    name,
    label,
    grid,
    options,
    optionKey,
    control,
    required,
    setSelectedValue,
    disabled,
    ruleList,
  } = props;

  const [selected, setSelected] = useState<any[]>([]);

  const [optionList, setOptionList] = useState(options);
  useEffect(() => {
    setOptionList(options);
    setSelected([]);
  }, [options]);

  const handleChange = (event, buttonAction = "none") => {
    let value;
    if (event) {
      value = event.target.value;
    }

    if (buttonAction === "all") {
      let selectAllArray = optionList.map((x) => x[optionKey]);
      setSelected(selectAllArray);
      setSelectedValue(name, optionList);
      return;
    }
    if (buttonAction === "uncheckAll") {
      setSelected([]);
      setSelectedValue(name, []);
      return;
    }
    let selectedValue = options.filter((list) =>
      value.includes(list[optionKey])
    );

    setSelectedValue(name, selectedValue);
    setSelected(value);
  };
  const onFieldValueChange = (onChange: any) => (_e, data?: any) => {
    onChange(data);
  };
  const filterList = (key) => {
    let filOption = options.filter((list) =>
      list[optionKey].toLowerCase().includes(key.toLowerCase())
    );
    setOptionList(filOption);
  };
  let rules: object = {};

  if (required) {
    rules = {
      required: " Required",
      ...ruleList,
    };
  } else {
    rules = {
      required: false,
      ...ruleList,
    };
  }
  const listObj = optionList.map((option, index) => {
    return (
      <CustomMenuItem
        key={option.id}
        value={option[optionKey]}
        // onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
        //   e.stopPropagation();
        // }}
      >
        <MCheckBox checked={selected.indexOf(option[optionKey]) > -1} />
        <CustomListItemText
          style={{ height: "fit-content" }}
          primary={option[optionKey]}
        />
      </CustomMenuItem>
    );
  });

  return (
    <Grid item xs={grid} sm={grid} md={grid} lg={grid} xl={grid}>
      {control !== null && (
        <Controller
          name={name}
          control={control}
          render={({
            field: { onChange, value, onBlur },
            fieldState: { error },
          }) => (
            <>
              <CustomFormControl
                variant="outlined"
                size="small"
                error={
                  error !== undefined && selected.length === 0
                    ? Boolean(error)
                    : false
                }
                disabled={disabled}
                fullWidth
              >
                <InputLabel shrink id="mutiple-select-label">
                  {label}
                  {required ? <span style={{ color: "red" }}> *</span> : <></>}
                </InputLabel>

                <CustomSelect
                  className="multiSelectCustom"
                  labelId="mutiple-select-label"
                  multiple
                  value={selected}
                  onChange={(e) => {
                    handleChange(e, "none");
                    onFieldValueChange(onChange);
                  }}
                  onClose={() => setOptionList(options)}
                  renderValue={(selected) =>
                    selected.join("$").split("$").length + " selected"
                  }
                  MenuProps={MenuProps}
                  autoFocus={false}
                  disabled={disabled}
                >
                  {options && options.length > 0 ? (
                    <>
                      <MTextField
                        name={"filter"}
                        onInputChange={(e) => {
                          filterList(e.target.value);
                        }}
                        label={"Enter Keyword"}
                        grid={12}
                        disabled={disabled}
                      />

                      <Grid
                        container
                        item={true}
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                      >
                        <Grid xs={6} sm={6} md={6} lg={6} xl={6}>
                          <ListItem>
                            <MButton
                              onClick={(e) => {
                                handleChange(e, "all");
                              }}
                              style={{
                                fontSize: "10px",
                                width: "100%",
                                height: "30px",
                                padding: 0,
                              }}
                            >
                              Check All
                            </MButton>
                          </ListItem>
                        </Grid>
                        <Grid xs={6} sm={6} md={6} lg={6} xl={6}>
                          <ListItem>
                            <MButton
                              onClick={(e) => {
                                handleChange(e, "uncheckAll");
                              }}
                              style={{
                                fontSize: "10px",
                                width: "100%",
                                height: "30px",
                                padding: 0,
                              }}
                            >
                              Uncheck All
                            </MButton>
                          </ListItem>
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    <div className={"noOptions"}>
                      <p>No Reasons</p>
                    </div>
                  )}
                  {listObj}
                </CustomSelect>
                {error && selected.length === 0 && (
                  <FormHelperText className={"ErrorText"}>
                    {label} Required
                  </FormHelperText>
                )}
              </CustomFormControl>
            </>
          )}
          rules={rules}
        />
      )}
    </Grid>
  );
}

MultiSelect.defaultProps = {
  name: "",
  options: [],
  optionKey: "",
  control: null,
  ruleList: {},
  inputValue: null,
  disabled: false,
  required: false,
};
