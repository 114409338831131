import React, { useEffect, useRef, useState } from "react";
import { Grid, MashTablePro, MButton } from "components/ui";
import { useFetchAPI } from "network/useFetchAPI";
import { API_STATUS_SUCCESS } from "util/constants";
import {
  replaceStr,
  showToast,
  getErrorMessage,
  getDateTimeForDisp,
} from "util/helper";
import { Icons } from "components/material-ui";
import * as Endpoints from "network/Endpoints";
import CommentAdd from "./CommentAdd";

interface CommentListProps {
  applicationId: any;
  applicationStageId: any;
  readOnly?: any;
}

export const CommentListFunc = React.forwardRef(
  (props: CommentListProps, ref) => {
    const { applicationId, applicationStageId, readOnly } = props;

    const [commentList, setCommentList] = useState<any>([]);
    const commentAddRef = useRef<any>(null);

    useEffect(() => {
      getCommentsDetails();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const {
      execute: getCommentsDetails,
      response: getCommentsDetailsResponse,
      loading: getCommentsDetailsLoading,
      reqHeaders: getCommentsDetailsHeaders,
    } = useFetchAPI(
      replaceStr(Endpoints.COMMENTS_DETAILS_FETCH, "appId", applicationId),
      {
        method: "GET",
        applicationId: applicationId,
      }
    );

    useEffect(() => {
      if (!getCommentsDetailsLoading && getCommentsDetailsResponse) {
        let { status, data, errorCode } = getCommentsDetailsResponse;
        if (status === API_STATUS_SUCCESS) {
          setCommentList(data);
        } else {
          showToast(
            "error",
            getErrorMessage(errorCode),
            getCommentsDetailsHeaders["X-CORRELATION-ID"]
          );
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getCommentsDetailsLoading, getCommentsDetailsResponse]);

    let commentColumn = [
      {
        field: "stageCode",
        title: "Stage",
        width: "10%",
      },
      {
        field: "comment",
        title: "Comments",
        width: "60%",
        render: (rowData) => (
          <span>
            {rowData.comment.substring(0, 700)}
            {rowData.comment.length > 700 && (
              <span
                onClick={(e) =>
                  commentAddRef?.current!.setIsOpen(
                    rowData.comment,
                    false,
                    null
                  )
                }
                className={"readMoreText"}
              >
                Read More
              </span>
            )}
          </span>
        ),
      },

      {
        field: "commentedBy",
        title: "Commented By",
        width: "15%",
      },
      {
        field: "creationDate",
        title: "Commented On",
        width: "15%",
        render: (rowData) => (
          <span>{getDateTimeForDisp(new Date(rowData.creationDate))}</span>
        ),
      },
    ];

    return (
      <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
        <MashTablePro
          id={"comment"}
          title={"Comment Details"}
          rows={commentList}
          columns={commentColumn}
          height={400}
          search={true}
          isLoading={getCommentsDetailsLoading}
          footerAction={[
            {
              render: (
                <>
                  {!readOnly && (
                    <Grid
                      container
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      style={{ marginTop: 3 }}
                    >
                      <MButton
                        type={"icon"}
                        onClick={() => {
                          commentAddRef?.current!.setIsOpen(
                            "",
                            true,
                            applicationStageId
                          );
                        }}
                      >
                        <Icons.AddPlusIcon />
                      </MButton>
                    </Grid>
                  )}
                </>
              ),
            },
          ]}
        />
        <CommentAdd
          ref={commentAddRef}
          onClose={(e) => getCommentsDetails()}
          applicationId={applicationId}
        />
      </Grid>
    );
  }
);
const CommentList = React.memo(CommentListFunc);
export default CommentList;
