import EyeIcon from "@mui/icons-material/Visibility";
import EyeIconOff from "@mui/icons-material/VisibilityOff";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import AddPlusIcon from "@mui/icons-material/Add";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import ComputerOutlinedIcon from "@mui/icons-material/ComputerOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import DoneIcon from "@mui/icons-material/Done";
import CalendarIcon from "@mui/icons-material/CalendarMonth";
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
const GridView = (props) => (
  <svg viewBox="0 0 24 24" width="1em" height="1em">
    <path d="M1.725 14.256a1.719 1.719 0 01-1.222-.508A1.718 1.718 0 010 12.525V1.736C-.001 1.276.178.841.504.515A1.718 1.718 0 011.725.006l7.803.013A1.732 1.732 0 0111.25 1.75v10.775a1.73 1.73 0 01-1.723 1.731H1.725zm.001-12.75a.218.218 0 00-.159.067.224.224 0 00-.067.161v10.792a.227.227 0 00.226.23h7.799a.227.227 0 00.225-.229V1.749a.23.23 0 00-.227-.23l-7.797-.013zM14.471 8.256a1.715 1.715 0 01-1.725-1.705v-4.82A1.723 1.723 0 0114.463.019l7.804-.013c.95 0 1.721.765 1.729 1.706v4.832a1.725 1.725 0 01-1.719 1.712h-7.806zm-.005-6.737a.22.22 0 00-.22.216v4.809a.21.21 0 00.065.149.23.23 0 00.159.063h7.801a.221.221 0 00.225-.217V1.718a.22.22 0 00-.22-.212l-7.81.013zM1.722 23.993A1.723 1.723 0 010 22.283V17.47a1.724 1.724 0 011.719-1.714h7.806c.948 0 1.719.767 1.725 1.71v4.825a1.716 1.716 0 01-1.72 1.715l-7.808-.013zm-.016-6.737c-.111 0-.205.1-.206.218v4.805a.219.219 0 00.219.214l7.807.013a.193.193 0 00.158-.064.213.213 0 00.066-.155V17.47a.219.219 0 00-.22-.214H1.706zM14.471 24.006a1.72 1.72 0 01-1.222-.509 1.718 1.718 0 01-.503-1.223V11.487a1.716 1.716 0 01.503-1.221 1.714 1.714 0 011.22-.51h7.802c.463.001.897.182 1.223.51.326.327.504.762.502 1.224v10.773a1.732 1.732 0 01-1.72 1.731l-7.802.013-.003-.001zm.001-12.75a.228.228 0 00-.226.229v10.79a.229.229 0 00.227.231l7.797-.013a.23.23 0 00.226-.23V11.487a.229.229 0 00-.227-.231h-7.797z"></path>
  </svg>
);
const ArrowDown = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Outline"
    viewBox="0 0 24 24"
    width="20"
    height="20"
  >
    <path d="M12,17.17a5,5,0,0,1-3.54-1.46L.29,7.54A1,1,0,0,1,1.71,6.12l8.17,8.17a3,3,0,0,0,4.24,0l8.17-8.17a1,1,0,1,1,1.42,1.42l-8.17,8.17A5,5,0,0,1,12,17.17Z" />
  </svg>
);
const ArrowUp = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Outline"
    viewBox="0 0 24 24"
    width="20"
    height="20"
  >
    <path d="M23.71,16.29,15.54,8.12a5,5,0,0,0-7.08,0L.29,16.29a1,1,0,0,0,1.42,1.42L9.88,9.54a3,3,0,0,1,4.24,0l8.17,8.17a1,1,0,0,0,1.42,0A1,1,0,0,0,23.71,16.29Z" />
  </svg>
);

const DoubleUser = (props) => (
  <svg viewBox="0 0 24 24" width="1em" height="1em">
    <path d="M7.5 12.75c-2.688 0-4.875-2.187-4.875-4.875S4.812 3 7.5 3s4.875 2.187 4.875 4.875S10.188 12.75 7.5 12.75zm0-8.25c-1.861 0-3.375 1.514-3.375 3.375S5.639 11.25 7.5 11.25s3.375-1.514 3.375-3.375S9.361 4.5 7.5 4.5zM14.25 21a.75.75 0 01-.75-.75c0-3.308-2.692-6-6-6s-6 2.692-6 6a.75.75 0 01-1.5 0c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5a.75.75 0 01-.75.75zM17.727 14.25a4.13 4.13 0 01-4.125-4.125C13.602 7.85 15.453 6 17.727 6s4.125 1.85 4.125 4.125a4.13 4.13 0 01-4.125 4.125zm0-6.75c-1.448 0-2.625 1.178-2.625 2.625s1.177 2.625 2.625 2.625 2.625-1.178 2.625-2.625S19.175 7.5 17.727 7.5z"></path>
    <path d="M23.25 21a.75.75 0 01-.75-.75 4.73 4.73 0 00-.295-1.648 4.742 4.742 0 00-2.481-2.685 4.736 4.736 0 00-3.653-.145.754.754 0 01-.963-.444.745.745 0 01.444-.963 6.246 6.246 0 012.165-.388 6.302 6.302 0 015.894 4.106c.258.696.389 1.425.389 2.167a.75.75 0 01-.75.75z"></path>
  </svg>
);
const WarningAmberRoundedIconThin = (props) => (
  <svg
    viewBox="0 0 24 24"
    width="1em"
    height="1em"
    focusable="false"
    color="#FFFFFF"
    aria-hidden="true"
  >
    <path d="M2.251 23.249a2.252 2.252 0 01-1.969-3.337l9.747-17.995a2.251 2.251 0 013.942-.005l9.753 18.005a2.252 2.252 0 01-1.973 3.332h-19.5zm9.749-21a.749.749 0 00-.655.388L1.598 20.632a.747.747 0 00-.066.575.746.746 0 00.719.543h19.5a.75.75 0 00.656-1.112L12.655 2.633A.74.74 0 0012 2.249z"></path>
    <path d="M12.001 19.499a.796.796 0 01-.13-.011 1.126 1.126 0 01-.983-.983.717.717 0 010-.262c.06-.513.47-.922.983-.982a.702.702 0 01.261 0c.513.06.923.47.983.983a.808.808 0 010 .262 1.126 1.126 0 01-1.114.993zM12.001 15.749a.75.75 0 01-.75-.75v-6.75a.75.75 0 011.5 0v6.75a.75.75 0 01-.75.75z"></path>
  </svg>
);
const PencilEdit = (props) => (
  <svg viewBox="0 0 24 24" width="1em" height="1em" {...props}>
    <path d="M.741 24.003a.755.755 0 01-.531-.22.754.754 0 01-.196-.716l1.771-6.905a.56.56 0 01.03-.081.756.756 0 01.158-.253c.002-.004 14.533-14.535 14.533-14.535A4.358 4.358 0 0119.614.004c1.17 0 2.27.453 3.096 1.277l.017.017c1.699 1.738 1.673 4.528-.057 6.219L8.177 22.01a.762.762 0 01-.231.155l-.025.01a.438.438 0 01-.089.032L.927 23.978a.695.695 0 01-.186.025zm1.041-1.792l4.41-1.131-3.28-3.273-1.13 4.404zm5.868-1.794L18.577 9.491l-4.073-4.073-10.93 10.93 4.076 4.069zM19.637 8.43l1.033-1.033-4.073-4.073-1.033 1.033 4.073 4.073zm2.092-2.096a2.895 2.895 0 00-.061-3.973l-.017-.017a2.868 2.868 0 00-2.038-.84 2.87 2.87 0 00-1.955.76l4.071 4.07z"></path>
  </svg>
);

const GroupOfPeopleIcon = (props) => (
  <svg
    height="23"
    viewBox="0 0 24 24"
    width="23"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="m7.5 13a4.5 4.5 0 1 1 4.5-4.5 4.505 4.505 0 0 1 -4.5 4.5zm0-7a2.5 2.5 0 1 0 2.5 2.5 2.5 2.5 0 0 0 -2.5-2.5zm7.5 17v-.5a7.5 7.5 0 0 0 -15 0v.5a1 1 0 0 0 2 0v-.5a5.5 5.5 0 0 1 11 0v.5a1 1 0 0 0 2 0zm9-5a7 7 0 0 0 -11.667-5.217 1 1 0 1 0 1.334 1.49 5 5 0 0 1 8.333 3.727 1 1 0 0 0 2 0zm-6.5-9a4.5 4.5 0 1 1 4.5-4.5 4.505 4.505 0 0 1 -4.5 4.5zm0-7a2.5 2.5 0 1 0 2.5 2.5 2.5 2.5 0 0 0 -2.5-2.5z" />
  </svg>
);
const DeleteIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="23"
  >
    <g id="_01_align_center" data-name="01 align center">
      <path d="M22,4H17V2a2,2,0,0,0-2-2H9A2,2,0,0,0,7,2V4H2V6H4V21a3,3,0,0,0,3,3H17a3,3,0,0,0,3-3V6h2ZM9,2h6V4H9Zm9,19a1,1,0,0,1-1,1H7a1,1,0,0,1-1-1V6H18Z"></path>
      <rect x="9" y="10" width="2" height="8"></rect>
      <rect x="13" y="10" width="2" height="8"></rect>
    </g>
  </svg>
);
const ToDoIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    height="20"
    viewBox="0 0 24 24"
    width="20"
    data-name="Layer 1"
  >
    <path d="m4 6a2.982 2.982 0 0 1 -2.122-.879l-1.544-1.374a1 1 0 0 1 1.332-1.494l1.585 1.414a1 1 0 0 0 1.456.04l3.604-3.431a1 1 0 0 1 1.378 1.448l-3.589 3.414a2.964 2.964 0 0 1 -2.1.862zm20-2a1 1 0 0 0 -1-1h-10a1 1 0 0 0 0 2h10a1 1 0 0 0 1-1zm-17.9 9.138 3.589-3.414a1 1 0 1 0 -1.378-1.448l-3.6 3.431a1.023 1.023 0 0 1 -1.414 0l-1.59-1.585a1 1 0 0 0 -1.414 1.414l1.585 1.585a3 3 0 0 0 4.226.017zm17.9-1.138a1 1 0 0 0 -1-1h-10a1 1 0 0 0 0 2h10a1 1 0 0 0 1-1zm-17.9 9.138 3.585-3.414a1 1 0 1 0 -1.378-1.448l-3.6 3.431a1 1 0 0 1 -1.456-.04l-1.585-1.414a1 1 0 0 0 -1.332 1.494l1.544 1.374a3 3 0 0 0 4.226.017zm17.9-1.138a1 1 0 0 0 -1-1h-10a1 1 0 0 0 0 2h10a1 1 0 0 0 1-1z"></path>
  </svg>
);
const MultipleUsersIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    viewBox="0 0 24 24"
    width="20"
    height="20"
  >
    <path d="M12,16a4,4,0,1,1,4-4A4,4,0,0,1,12,16Zm0-6a2,2,0,1,0,2,2A2,2,0,0,0,12,10Zm6,13A6,6,0,0,0,6,23a1,1,0,0,0,2,0,4,4,0,0,1,8,0,1,1,0,0,0,2,0ZM18,8a4,4,0,1,1,4-4A4,4,0,0,1,18,8Zm0-6a2,2,0,1,0,2,2A2,2,0,0,0,18,2Zm6,13a6.006,6.006,0,0,0-6-6,1,1,0,0,0,0,2,4,4,0,0,1,4,4,1,1,0,0,0,2,0ZM6,8a4,4,0,1,1,4-4A4,4,0,0,1,6,8ZM6,2A2,2,0,1,0,8,4,2,2,0,0,0,6,2ZM2,15a4,4,0,0,1,4-4A1,1,0,0,0,6,9a6.006,6.006,0,0,0-6,6,1,1,0,0,0,2,0Z"></path>
  </svg>
);
const DashboardGridIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Outline"
    viewBox="0 0 24 24"
    width="20"
    height="20"
  >
    <path d="M2,11H13a2,2,0,0,0,2-2V2a2,2,0,0,0-2-2H2A2,2,0,0,0,0,2V9A2,2,0,0,0,2,11ZM2,2H13V9H2Z"></path>
    <path d="M22,0H19a2,2,0,0,0-2,2V9a2,2,0,0,0,2,2h3a2,2,0,0,0,2-2V2A2,2,0,0,0,22,0Zm0,9H19V2h3Z"></path>
    <path d="M5,13H2a2,2,0,0,0-2,2v7a2,2,0,0,0,2,2H5a2,2,0,0,0,2-2V15A2,2,0,0,0,5,13Zm0,9H2V15H5Z"></path>
    <path d="M22,13H11a2,2,0,0,0-2,2v7a2,2,0,0,0,2,2H22a2,2,0,0,0,2-2V15A2,2,0,0,0,22,13Zm0,9H11V15H22Z"></path>
  </svg>
);
const DoubleArrowLeft = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Outline"
    viewBox="0 0 24 24"
    width="20"
    height="20"
  >
    <path d="M12,24a1,1,0,0,1-.71-.29L3.12,15.54a5,5,0,0,1,0-7.08L11.29.29a1,1,0,1,1,1.42,1.42L4.54,9.88a3,3,0,0,0,0,4.24l8.17,8.17a1,1,0,0,1,0,1.42A1,1,0,0,1,12,24Z" />
    <path d="M22,24a1,1,0,0,1-.71-.29l-9.58-9.59a3,3,0,0,1,0-4.24L21.29.29a1,1,0,1,1,1.42,1.42l-9.59,9.58a1,1,0,0,0,0,1.42l9.59,9.58a1,1,0,0,1,0,1.42A1,1,0,0,1,22,24Z" />
  </svg>
);
const DoubleArrowRight = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Outline"
    viewBox="0 0 24 24"
    width="20"
    height="20"
  >
    <path d="M11.83,24a1,1,0,0,1-.71-.29,1,1,0,0,1,0-1.42l8.17-8.17a3,3,0,0,0,0-4.24L11.12,1.71A1,1,0,1,1,12.54.29l8.17,8.17a5,5,0,0,1,0,7.08l-8.17,8.17A1,1,0,0,1,11.83,24Z" />
    <path d="M1.83,24a1,1,0,0,1-.71-.29,1,1,0,0,1,0-1.42l9.59-9.58a1,1,0,0,0,0-1.42L1.12,1.71A1,1,0,0,1,2.54.29l9.58,9.59a3,3,0,0,1,0,4.24L2.54,23.71A1,1,0,0,1,1.83,24Z" />
  </svg>
);
const MoneyLotIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    viewBox="0 0 24 24"
    width="20"
    height="20"
  >
    <path d="M16.5,0c-4.206,0-7.5,1.977-7.5,4.5v2.587c-.483-.057-.985-.087-1.5-.087C3.294,7,0,8.977,0,11.5v8c0,2.523,3.294,4.5,7.5,4.5,3.407,0,6.216-1.297,7.16-3.131,.598,.087,1.214,.131,1.84,.131,4.206,0,7.5-1.977,7.5-4.5V4.5c0-2.523-3.294-4.5-7.5-4.5Zm5.5,12.5c0,1.18-2.352,2.5-5.5,2.5-.512,0-1.014-.035-1.5-.103v-1.984c.49,.057,.992,.087,1.5,.087,2.194,0,4.14-.538,5.5-1.411v.911ZM2,14.589c1.36,.873,3.306,1.411,5.5,1.411s4.14-.538,5.5-1.411v.911c0,1.18-2.352,2.5-5.5,2.5s-5.5-1.32-5.5-2.5v-.911Zm20-6.089c0,1.18-2.352,2.5-5.5,2.5-.535,0-1.06-.038-1.566-.112-.193-.887-.8-1.684-1.706-2.323,.984,.28,2.092,.435,3.272,.435,2.194,0,4.14-.538,5.5-1.411v.911Zm-5.5-6.5c3.148,0,5.5,1.32,5.5,2.5s-2.352,2.5-5.5,2.5-5.5-1.32-5.5-2.5,2.352-2.5,5.5-2.5ZM7.5,9c3.148,0,5.5,1.32,5.5,2.5s-2.352,2.5-5.5,2.5-5.5-1.32-5.5-2.5,2.352-2.5,5.5-2.5Zm0,13c-3.148,0-5.5-1.32-5.5-2.5v-.911c1.36,.873,3.306,1.411,5.5,1.411s4.14-.538,5.5-1.411v.911c0,1.18-2.352,2.5-5.5,2.5Zm9-3c-.512,0-1.014-.035-1.5-.103v-1.984c.49,.057,.992,.087,1.5,.087,2.194,0,4.14-.538,5.5-1.411v.911c0,1.18-2.352,2.5-5.5,2.5Z" />
  </svg>
);
const MoneyCoinIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    viewBox="0 0 24 24"
    width="20"
    height="20"
  >
    <path d="M12,2C5.271,2,0,4.855,0,8.5v7c0,3.645,5.271,6.5,12,6.5s12-2.855,12-6.5v-7c0-3.645-5.271-6.5-12-6.5Zm0,2c5.893,0,10,2.372,10,4.5s-4.107,4.5-10,4.5S2,10.628,2,8.5,6.107,4,12,4ZM7,14.423v4.941c-.739-.203-1.406-.449-2-.723v-4.836c.624,.239,1.292,.446,2,.618Zm2,.372c.647,.087,1.313,.149,2,.179v5.001c-.697-.033-1.365-.099-2-.194v-4.986Zm4,.179c.687-.029,1.353-.091,2-.179v4.986c-.635,.095-1.303,.161-2,.194v-5.001Zm4-.55c.708-.172,1.376-.379,2-.618v4.836c-.594,.274-1.261,.52-2,.723v-4.941Zm-15,1.077v-3.362c.301,.246,.641,.475,1,.694v4.526c-.641-.597-1-1.238-1-1.859Zm19,1.859v-4.526c.359-.22,.699-.449,1-.694v3.362c0,.621-.359,1.261-1,1.859Z" />
  </svg>
);
const UserAddIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Outline"
    viewBox="0 0 24 24"
    width="20"
    height="20"
  >
    <path d="M23,11H21V9a1,1,0,0,0-2,0v2H17a1,1,0,0,0,0,2h2v2a1,1,0,0,0,2,0V13h2a1,1,0,0,0,0-2Z" />
    <path d="M9,12A6,6,0,1,0,3,6,6.006,6.006,0,0,0,9,12ZM9,2A4,4,0,1,1,5,6,4,4,0,0,1,9,2Z" />
    <path d="M9,14a9.01,9.01,0,0,0-9,9,1,1,0,0,0,2,0,7,7,0,0,1,14,0,1,1,0,0,0,2,0A9.01,9.01,0,0,0,9,14Z" />
  </svg>
);
const AppSortIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Outline"
    viewBox="0 0 24 24"
    width="20"
    height="20"
  >
    <path d="M7,0H4A4,4,0,0,0,0,4V7a4,4,0,0,0,4,4H7a4,4,0,0,0,4-4V4A4,4,0,0,0,7,0ZM9,7A2,2,0,0,1,7,9H4A2,2,0,0,1,2,7V4A2,2,0,0,1,4,2H7A2,2,0,0,1,9,4Z" />
    <path d="M7,13H4a4,4,0,0,0-4,4v3a4,4,0,0,0,4,4H7a4,4,0,0,0,4-4V17A4,4,0,0,0,7,13Zm2,7a2,2,0,0,1-2,2H4a2,2,0,0,1-2-2V17a2,2,0,0,1,2-2H7a2,2,0,0,1,2,2Z" />
    <path d="M22.293,19.049,20,21.339V2.633l2.293,2.29a1,1,0,1,0,1.414-1.415L21.12.925a3,3,0,0,0-4.24,0L14.293,3.508a1,1,0,1,0,1.414,1.415L18,2.633V21.339l-2.293-2.29a1,1,0,1,0-1.414,1.415l2.587,2.583a3,3,0,0,0,4.24,0l2.587-2.583a1,1,0,1,0-1.414-1.415Z" />
  </svg>
);
const PlayFilledIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Filled"
    viewBox="0 0 24 24"
    width={props.width}
    height={props.height}
    className={props.className}
  >
    <path d="M20.492,7.969,10.954.975A5,5,0,0,0,3,5.005V19a4.994,4.994,0,0,0,7.954,4.03l9.538-6.994a5,5,0,0,0,0-8.062Z" />
  </svg>
);
const ChangeIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Outline"
    viewBox="0 0 24 24"
    width={props.width}
    height={props.height}
    className={props.className}
  >
    <path d="M23.421,16.583,20.13,13.292a1,1,0,1,0-1.413,1.414L21.007,17A9.332,9.332,0,0,1,14.321,14.2a.982.982,0,0,0-1.408.08L12.9,14.3a1,1,0,0,0,.075,1.382A11.177,11.177,0,0,0,21.01,19l-2.293,2.293A1,1,0,1,0,20.13,22.7l3.291-3.291A2,2,0,0,0,23.421,16.583Z" />
    <path d="M21.007,7l-2.29,2.29a.892.892,0,0,0-.054.082.992.992,0,0,0,1.467,1.332L21.836,9l1.586-1.585a2,2,0,0,0,.457-2.1,1.969,1.969,0,0,0-.458-.728L20.13,1.3a1,1,0,1,0-1.413,1.413L21.01,5.005c-4.933.012-7.637,2.674-10.089,5.474C8.669,7.937,6,5.4,1.487,5.046L1.006,5a1,1,0,0,0-1,1,1.02,1.02,0,0,0,1,1c.072,0,.287.033.287.033C5.189,7.328,7.425,9.522,9.6,12c-2.162,2.466-4.383,4.7-8.247,4.96l-.4.021a.994.994,0,1,0,.124,1.985c.156-.007.41-.013.535-.023,5.02-.387,7.743-3.6,10.171-6.409C14.235,9.7,16.551,7.018,21.007,7Z" />
  </svg>
);
const PlayIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={props.width}
    height={props.height}
    className={props.className}
  >
    <g id="_01_align_center" data-name="01 align center">
      <path d="M19.765,9.458,4.98.019v24l14.779-9.473a3.007,3.007,0,0,0,.006-5.088Zm-1.08,3.395-11.7,7.5V3.677l11.707,7.474a1,1,0,0,1-.007,1.7Z" />
    </g>
  </svg>
);
const NewTabIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    viewBox="0 0 24 24"
    width={props.width}
    height={props.height}
    className={props.className}
  >
    <path d="M20,11v8c0,2.757-2.243,5-5,5H5c-2.757,0-5-2.243-5-5V9c0-2.757,2.243-5,5-5H13c.552,0,1,.448,1,1s-.448,1-1,1H5c-1.654,0-3,1.346-3,3v10c0,1.654,1.346,3,3,3H15c1.654,0,3-1.346,3-3V11c0-.552,.448-1,1-1s1,.448,1,1ZM21,0h-7c-.552,0-1,.448-1,1s.448,1,1,1h6.586L8.293,14.293c-.391,.391-.391,1.023,0,1.414,.195,.195,.451,.293,.707,.293s.512-.098,.707-.293L22,3.414v6.586c0,.552,.448,1,1,1s1-.448,1-1V3c0-1.654-1.346-3-3-3Z" />
  </svg>
);
const UploadIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    viewBox="0 0 24 24"
    width={props.width}
    height={props.height}
    className={props.className}
  >
    <path d="M19.5,3h-7.028c-.231,0-.464-.055-.671-.158l-3.156-1.578c-.345-.173-.732-.264-1.118-.264h-3.028C2.019,1,0,3.019,0,5.5v13c0,2.481,2.019,4.5,4.5,4.5h4c.276,0,.5-.224,.5-.5s-.224-.5-.5-.5H4.5c-1.93,0-3.5-1.57-3.5-3.5V8H23v10.5c0,1.93-1.57,3.5-3.5,3.5h-4c-.276,0-.5,.224-.5,.5s.224,.5,.5,.5h4c2.481,0,4.5-2.019,4.5-4.5V7.5c0-2.481-2.019-4.5-4.5-4.5ZM1,5.5c0-1.93,1.57-3.5,3.5-3.5h3.028c.231,0,.464,.055,.671,.158l3.156,1.578c.345,.173,.732,.264,1.118,.264h7.028c1.76,0,3.221,1.306,3.464,3H1v-1.5Zm11.683,7.78c-.056-.056-.117-.103-.183-.142v9.363c0,.276-.224,.5-.5,.5s-.5-.224-.5-.5V13.139c-.066,.04-.129,.088-.187,.146l-2.511,2.566c-.193,.198-.509,.201-.707,.008-.197-.193-.201-.51-.008-.707l2.515-2.57c.384-.384,.886-.577,1.388-.58h.018c.502,.002,1.003,.194,1.384,.576l2.519,2.574c.193,.197,.189,.514-.008,.707-.097,.095-.224,.143-.35,.143-.13,0-.259-.05-.357-.15l-2.515-2.57Z" />
  </svg>
);
const DownloadIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    viewBox="0 0 24 24"
    width={props.width}
    height={props.height}
    className={props.className}
  >
    <path d="M19.5,3h-7.028c-.231,0-.464-.055-.671-.158l-3.156-1.578c-.345-.173-.732-.264-1.118-.264h-3.028C2.019,1,0,3.019,0,5.5v13c0,2.481,2.019,4.5,4.5,4.5h3c.276,0,.5-.224,.5-.5s-.224-.5-.5-.5h-3c-1.93,0-3.5-1.57-3.5-3.5V8H23v10.5c0,1.93-1.57,3.5-3.5,3.5h-3c-.276,0-.5,.224-.5,.5s.224,.5,.5,.5h3c2.481,0,4.5-2.019,4.5-4.5V7.5c0-2.481-2.019-4.5-4.5-4.5ZM1,5.5c0-1.93,1.57-3.5,3.5-3.5h3.028c.231,0,.464,.055,.671,.158l3.156,1.578c.345,.173,.732,.264,1.118,.264h7.028c1.76,0,3.221,1.306,3.464,3H1v-1.5Zm14.912,14.35l-2.515,2.57c-.386,.386-.891,.579-1.396,.58h-.003c-.504,0-1.008-.192-1.392-.576l-2.519-2.574c-.193-.197-.189-.514,.008-.707,.198-.191,.515-.189,.707,.008l2.515,2.57c.056,.056,.117,.103,.183,.142V12.5c0-.276,.224-.5,.5-.5s.5,.224,.5,.5v9.362c.066-.04,.129-.088,.187-.145l2.511-2.566c.193-.197,.51-.199,.707-.008,.197,.193,.201,.51,.008,.707Z" />
  </svg>
);
const DownloadFilledIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    viewBox="0 0 24 24"
    width={props.width}
    height={props.height}
    className={props.className}
  >
    <path d="M23.899,7H0v-1C0,3.243,2.243,1,5,1h2.528c.463,0,.927,.109,1.341,.316l3.156,1.578c.138,.069,.293,.105,.447,.105h6.528c2.414,0,4.434,1.721,4.899,4Zm.101,2v9c0,2.757-2.243,5-5,5H5c-2.757,0-5-2.243-5-5V9H24Zm-8.293,8.107c-.391-.391-1.023-.391-1.414,0l-1.293,1.293v-6.4c-.006-1.308-1.995-1.307-2,0v6.4l-1.293-1.293c-.929-.921-2.335,.486-1.414,1.414l1.614,1.613c1.154,1.154,3.033,1.154,4.187,0l1.613-1.613c.391-.391,.391-1.023,0-1.414Z" />
  </svg>
);
const BoxMinusOutlineIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Outline"
    viewBox="0 0 24 24"
    width={props.width}
    height={props.height}
    className={props.className}
  >
    <path d="M7,0H4A4,4,0,0,0,0,4V7a4,4,0,0,0,4,4H7a4,4,0,0,0,4-4V4A4,4,0,0,0,7,0ZM9,7A2,2,0,0,1,7,9H4A2,2,0,0,1,2,7V4A2,2,0,0,1,4,2H7A2,2,0,0,1,9,4Z" />
    <path d="M7,13H4a4,4,0,0,0-4,4v3a4,4,0,0,0,4,4H7a4,4,0,0,0,4-4V17A4,4,0,0,0,7,13Zm2,7a2,2,0,0,1-2,2H4a2,2,0,0,1-2-2V17a2,2,0,0,1,2-2H7a2,2,0,0,1,2,2Z" />
    <path d="M20,13H17a4,4,0,0,0-4,4v3a4,4,0,0,0,4,4h3a4,4,0,0,0,4-4V17A4,4,0,0,0,20,13Zm2,7a2,2,0,0,1-2,2H17a2,2,0,0,1-2-2V17a2,2,0,0,1,2-2h3a2,2,0,0,1,2,2Z" />
    <path d="M14,7h8a1,1,0,0,0,0-2H14a1,1,0,0,0,0,2Z" />
  </svg>
);
const SearchAppOutlineIcon = (props) => (
  <svg
    id="Layer_1"
    viewBox="0 0 24 24"
    width={props.width}
    height={props.height}
    className={props.className}
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
  >
    <path d="m16 6a1 1 0 0 1 0 2h-8a1 1 0 0 1 0-2zm7.707 17.707a1 1 0 0 1 -1.414 0l-2.407-2.407a4.457 4.457 0 0 1 -2.386.7 4.5 4.5 0 1 1 4.5-4.5 4.457 4.457 0 0 1 -.7 2.386l2.407 2.407a1 1 0 0 1 0 1.414zm-6.207-3.707a2.5 2.5 0 1 0 -2.5-2.5 2.5 2.5 0 0 0 2.5 2.5zm-4.5 2h-6a3 3 0 0 1 -3-3v-14a3 3 0 0 1 3-3h12a1 1 0 0 1 1 1v8a1 1 0 0 0 2 0v-8a3 3 0 0 0 -3-3h-12a5.006 5.006 0 0 0 -5 5v14a5.006 5.006 0 0 0 5 5h6a1 1 0 0 0 0-2z" />
  </svg>
);
const TickLine = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    id="Capa_1"
    x="0px"
    y="0px"
    viewBox="0 0 465.822 465.822"
    width={props.width}
    height={props.height}
    className={props.className}
  >
    <g>
      <path d="M5.988,289.981l88.875,88.875c24.992,24.984,65.504,24.984,90.496,0l274.475-274.475c8.185-8.475,7.95-21.98-0.525-30.165   c-8.267-7.985-21.374-7.985-29.641,0L155.194,348.691c-8.331,8.328-21.835,8.328-30.165,0l-88.875-88.875   c-8.475-8.185-21.98-7.95-30.165,0.525C-1.996,268.608-1.996,281.714,5.988,289.981L5.988,289.981z" />
    </g>
  </svg>
);
const BoxBallotIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    viewBox="0 0 24 24"
    width={props.width}
    height={props.height}
    className={props.className}
  >
    <path d="M19,8V4c0-2.206-1.794-4-4-4h-6c-2.206,0-4,1.794-4,4v4c-2.757,0-5,2.243-5,5v6c0,2.757,2.243,5,5,5h14c2.757,0,5-2.243,5-5v-6c0-2.757-2.243-5-5-5ZM7,4c0-1.103,.897-2,2-2h6c1.103,0,2,.897,2,2V12H7V4Zm-2,6v3c0,.552,.448,1,1,1h12c.553,0,1-.448,1-1v-3c1.654,0,3,1.346,3,3v4H2v-4c0-1.654,1.346-3,3-3Zm14,12H5c-1.654,0-3-1.346-3-3H22c0,1.654-1.346,3-3,3Z" />
  </svg>
);

const InvoiceIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    viewBox="0 0 24 24"
    width={props.width}
    height={props.height}
    className={props.className}
  >
    <path d="M16,23c0,.552-.447,1-1,1H6c-2.757,0-5-2.243-5-5V5C1,2.243,3.243,0,6,0h4.515c1.869,0,3.627,.728,4.95,2.05l3.484,3.486c.271,.271,.523,.568,.748,.883,.321,.449,.217,1.074-.232,1.395-.449,.32-1.075,.217-1.395-.233-.161-.225-.341-.438-.534-.63l-3.485-3.486c-.318-.318-.671-.587-1.051-.805V7c0,.551,.448,1,1,1h3c.553,0,1,.448,1,1s-.447,1-1,1h-3c-1.654,0-3-1.346-3-3V2.023c-.16-.015-.322-.023-.485-.023H6c-1.654,0-3,1.346-3,3v14c0,1.654,1.346,3,3,3H15c.553,0,1,.448,1,1Zm5.685-6.733l-3.041-.507c-.373-.062-.644-.382-.644-.76,0-.551,.448-1,1-1h2.268c.356,0,.688,.192,.867,.5,.275,.478,.885,.641,1.366,.365,.478-.277,.642-.888,.364-1.366-.534-.925-1.53-1.5-2.598-1.5h-.268v-1c0-.552-.447-1-1-1s-1,.448-1,1v1c-1.654,0-3,1.346-3,3,0,1.36,.974,2.51,2.315,2.733l3.041,.507c.373,.062,.644,.382,.644,.76,0,.551-.448,1-1,1h-2.268c-.356,0-.688-.192-.867-.5-.275-.479-.886-.642-1.366-.365-.478,.277-.642,.888-.364,1.366,.534,.925,1.53,1.499,2.598,1.499h.268v1c0,.552,.447,1,1,1s1-.448,1-1v-1c1.654,0,3-1.346,3-3,0-1.36-.974-2.51-2.315-2.733Zm-14.185-1.267h5.5c.553,0,1-.448,1-1s-.447-1-1-1H7.5c-1.378,0-2.5,1.122-2.5,2.5v2c0,1.378,1.122,2.5,2.5,2.5h5.5c.553,0,1-.448,1-1s-.447-1-1-1H7.5c-.276,0-.5-.224-.5-.5v-2c0-.276,.224-.5,.5-.5Zm-1.5-4h2c.552,0,1-.448,1-1s-.448-1-1-1h-2c-.552,0-1,.448-1,1s.448,1,1,1Zm0-4h2c.552,0,1-.448,1-1s-.448-1-1-1h-2c-.552,0-1,.448-1,1s.448,1,1,1Z" />
  </svg>
);

const TransferIcon = (props) => (
  <svg
    id="Layer_1"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    width={props.width}
    height={props.height}
    className={props.className}
  >
    <path d="m8 0h-4a4 4 0 0 0 -4 4v1a4 4 0 0 0 4 4h1v2h-2a1 1 0 0 0 0 2h6a1 1 0 0 0 0-2h-2v-2h1a4 4 0 0 0 4-4v-1a4 4 0 0 0 -4-4zm2 5a2 2 0 0 1 -2 2h-4a2 2 0 0 1 -2-2v-1a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2zm10 6h-2a4 4 0 0 0 -4 4v5a4 4 0 0 0 4 4h2a4 4 0 0 0 4-4v-5a4 4 0 0 0 -4-4zm2 9a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2-2v-5a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2zm-8-16a1 1 0 0 1 1-1h2a3 3 0 0 1 3 3v2a1 1 0 0 1 -2 0v-2a1 1 0 0 0 -1-1h-2a1 1 0 0 1 -1-1zm-2 16a1 1 0 0 1 -1 1h-3a3 3 0 0 1 -3-3v-2a1 1 0 0 1 2 0v2a1 1 0 0 0 1 1h3a1 1 0 0 1 1 1zm8 0a1 1 0 1 1 -1-1 1 1 0 0 1 1 1z" />
  </svg>
);
const Icons = {
  GridView,
  ArrowDown,
  ArrowUp,
  DoubleArrowLeft,
  DoubleArrowRight,
  DoubleUser,
  WarningAmberRoundedIconThin,
  EyeIcon,
  EyeIconOff,
  SearchIcon,
  CloseIcon,
  PencilEdit,
  AddPlusIcon,
  GroupOfPeopleIcon,
  PersonAddIcon: PersonAddAltOutlinedIcon,
  DeleteIcon,
  ComputerIcon: ComputerOutlinedIcon,
  AssignmentIcon: AssignmentOutlinedIcon,
  ToDoIcon,
  MultipleUsersIcon,
  DashboardGridIcon,
  MoneyLotIcon,
  MoneyCoinIcon,
  UserAddIcon,
  AppSortIcon,
  DoneIcon,
  PlayIcon,
  PlayFilledIcon,
  ChangeIcon,
  CalendarIcon,
  NewTabIcon,
  UploadIcon,
  DownloadIcon,
  DownloadFilledIcon,
  BoxMinusOutlineIcon,
  SearchAppOutlineIcon,
  TickLine,
  BoxBallotIcon,
  InvoiceIcon,
  TransferIcon,
  FeedOutlinedIcon
};
export default Icons;
