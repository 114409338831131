// import React from "react";
import { useEffect } from "react";
import { API_STATUS_SUCCESS, SYSTEM_PAGE_TITLE } from "util/constants";
import { useNavigate } from "react-router-dom";
import SignUpForm from "./SignUpForm";
import { REGISTRATION } from "network/Endpoints";
import * as RouteConfig from "routes/config";
import { showToast } from "util/helper";
// import { DispatchContext, StateContext } from "context/context";
import { Grid } from "components/ui";

import { useFetchAPI } from "network/useFetchAPI";

const Login = () => {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = SYSTEM_PAGE_TITLE.REGISTRATION_SCREEN;
  }, []);

  const {
    execute: registration,
    response: registrationResponse,
    loading: registrationLoading,
  } = useFetchAPI(REGISTRATION, {
    method: "POST",
  });

  useEffect(() => {
    if (!registrationLoading && registrationResponse) {
      let { status, message } = registrationResponse;
      debugger;
      if (status === API_STATUS_SUCCESS) {
        setTimeout(() => {
          showToast("success", " User Created Successfully.");
        }, 1500);
        navigate(RouteConfig.LOGIN);
      } else {
        showToast("error", message);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registrationResponse, registrationLoading]);

  const onSubmit = (data) => {
    registration({
      data: {
        firstName: data.firstName,
        lastName: data.lastName,
        username: data.username,
        mobileNo: data.phoneNumber,
        password: data.password,
        usertype: "Customer",
      },
    });
  };

  return (
    <Grid
      container
      item={true}
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      style={{ background: "#FFF" }}
    >
      <>
        <Grid
          container
          spacing={0}
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: "100vh" }}
        >
          <Grid item xs={10} sm={4} md={4}>
            <SignUpForm
              onSubmit={onSubmit}
              checkLoginLoading={registrationLoading}
            />
          </Grid>
        </Grid>
      </>
    </Grid>
  );
};

export default Login;
