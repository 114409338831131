import { Box, Grid } from "components/material-ui";
import { MButton, MRadio, MTextField } from "components/ui";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { API_STATUS_SUCCESS, SYSTEM_PAGE_TITLE } from "util/constants";
import { REGISTRATION } from "network/Endpoints";
import { showToast } from "util/helper";
import { setApplicationTitle } from "util/helper";
import { getRule } from "util/validations";
import { useFetchAPI } from "network/useFetchAPI";
import Loader from "components/loader";

export default function CreateUser() {
  const { control, handleSubmit, reset } = useForm({
    mode: "onTouched",
    defaultValues: {
      firstName: "",
      lastName: "",
      username: "",
      mobileNo: "",
      password: "",
      userType: "",
    },
  });
  useEffect(() => {
    document.title = SYSTEM_PAGE_TITLE.USER_CREATE_USERS;
    setApplicationTitle("Create User");
  }, []);
  const {
    execute: registration,
    response: registrationResponse,
    loading: registrationLoading,
  } = useFetchAPI(REGISTRATION, {
    method: "POST",
  });

  useEffect(() => {
    if (!registrationLoading && registrationResponse) {
      let { status, message } = registrationResponse;
      debugger;
      if (status === API_STATUS_SUCCESS) {
        reset();
        showToast("success", " User Created Successfully.");
      } else {
        showToast("error", message);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registrationResponse, registrationLoading]);

  const onSubmit = (data) => {
    debugger;
    registration({
      data: {
        firstName: data.firstName,
        lastName: data.lastName,
        username: data.username,
        mobileNo: data.mobileNo,
        password: data.password,
        usertype: data.userType,
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
        <Grid
          container
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          spacing={0}
        ></Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <span className="loginText1">Create User</span>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <span className="loginText2">Create user with Email address</span>
        </Grid>
        <Box style={{ marginTop: 20, width: "100%" }}>
          <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
            <MTextField
              name={"firstName"}
              label={"First Name"}
              control={control}
              grid={6}
              required={true}
            />
            <MTextField
              name={"lastName"}
              label={"Last Name"}
              control={control}
              grid={6}
              required={true}
            />
          </Grid>
        </Box>
        <Box style={{ marginTop: 20, width: "100%" }}>
          <MTextField
            name={"username"}
            label={"Username (Email)"}
            control={control}
            grid={12}
            required={true}
            ruleList={getRule("emailAddress")}
          />
          <MTextField
            number={true}
            name={"mobileNo"}
            label={"Phone Number (optional)"}
            control={control}
            grid={12}
            ruleList={getRule("mobileNo")}
          />
        </Box>
        <Box style={{ marginTop: 10, width: "100%" }}>
          <MTextField
            password={true}
            name={"password"}
            label={"Password"}
            control={control}
            grid={12}
            required={true}
            ruleList={getRule("password")}
          />
        </Box>
        <Box style={{ marginTop: 10, width: "100%" }}>
          <MRadio
            name={"userType"}
            label={"User Type"}
            control={control}
            grid={12}
            required={true}
            options={[
              { value: "Customer" },
              { value: "Admin" },
              { value: "Technician" },
            ]}
            onInputChange={(e) => {}}
          />
        </Box>
        <Box
          style={{
            marginTop: 10,
            marginLeft: 5,
            marginRight: 5,
            width: "100%",
          }}
        >
          <MButton
            type={"submit"}
            style={{
              marginTop: 10,
              width: "100%",
            }}
            disabled={registrationLoading}
            loading={registrationLoading}
          >
            Create User
          </MButton>
        </Box>
        <Loader enable={registrationLoading} />
      </Grid>
    </form>
  );
}
