import { Grid, MTextField } from "components/ui";
import { useFetchAPI } from "network/useFetchAPI";
import * as Endpoints from "network/Endpoints";
import {
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
  useRef,
} from "react";
import { useForm } from "react-hook-form";
import { API_STATUS_SUCCESS } from "util/constants";
import { getErrorMessage, showToast } from "util/helper";
import MashModal from "common/modal";
import { getRule } from "util/validations";

interface CommentAddProps {
  onClose: any;
  applicationId: any;
}

const CommentAdd = forwardRef((props: CommentAddProps, ref) => {
  const [isEditable, setIsEditable] = useState(false);
  const [applicationStageId, setApplicationStageId] = useState<any>(null);
  const modalRef = useRef<any>();
  const { control, handleSubmit, setValue, clearErrors } = useForm({
    mode: "onTouched",
    defaultValues: {
      commentText: "",
    },
  });
  const {
    execute: setCommentSave,
    response: setCommentSaveResponse,
    loading: setCommentSaveLoading,
    reqHeaders: setCommentSaveHeaders,
  } = useFetchAPI(Endpoints.COMMENTS_DETAILS_SAVE, {
    method: "POST",
  });

  useEffect(() => {
    if (!setCommentSaveLoading && setCommentSaveResponse) {
      let { status, errorCode } = setCommentSaveResponse;
      if (status === API_STATUS_SUCCESS) {
        showToast("success", "Comments Updated");
        modalRef.current.setClose();
        props.onClose();
      } else {
        showToast(
          "error",
          getErrorMessage(errorCode),
          setCommentSaveHeaders["X-CORRELATION-ID"]
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setCommentSaveLoading, setCommentSaveResponse]);

  useImperativeHandle(
    ref,
    () => ({
      setIsOpen(text, isEditable, appStageId) {
        setApplicationStageId(appStageId);
        clearErrors(["commentText"]);
        setValue("commentText", text);
        setIsEditable(isEditable);
        modalRef.current.setOpen();
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setValue]
  );

  const onSubmit = (data) => {
    setCommentSave({
      data: {
        applicationId: props.applicationId,
        applicationStageId: applicationStageId,
        commentList: [
          {
            comment: data.commentText,
          },
        ],
      },
    });
  };
  return (
    <MashModal
      ref={modalRef}
      title={"Enter Comment"}
      maxWidth="md"
      onClose={(e) => {}}
      onSubmit={(e) => {
        handleSubmit(onSubmit)(e);
      }}
      hideSubmit={!isEditable}
      dismissBackgroud={false}
      submitLoading={setCommentSaveLoading}
      submitButtonText={"Add"}
    >
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        style={{ height: "250px", position: "relative" }}
      >
        <form onSubmit={handleSubmit(onSubmit)} style={{ height: "100%" }}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            style={{
              height: "100%",
            }}
          >
            <MTextField
              name={"commentText"}
              textarea={true}
              control={control}
              grid={12}
              disabled={!isEditable || setCommentSaveLoading}
              areaRow={-1}
              containerStyle={{ height: "99%" }}
              required={true}
              label={"Comment"}
              ruleList={getRule("applicationComment")}
            />
          </Grid>
        </form>
      </Grid>
    </MashModal>
  );
});
export default CommentAdd;
