//@ts-nocheck
import React, { useImperativeHandle, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Icons,
} from "components/material-ui";
import { MButton } from "components/ui";

interface MasModalProps {
  title?: any;
  children: any;
  maxWidth: string;
  onClose?: any;
  onSubmit?: any;
  sideMenu?: any;
  dismissBackgroud?: any;
  hideSubmit?: any;
  submitLoading?: any;
  submitButtonText?: any;
  footerAction?: any;
}
export const MasModalFunc = React.forwardRef((props: MasModalProps, ref) => {
  const {
    title,
    children,
    onClose,
    onSubmit,
    maxWidth,
    sideMenu,
    dismissBackgroud,
    hideSubmit,
    submitLoading,
    submitButtonText,
    footerAction,
  } = props;
  const [isOpen, setIsOpen] = useState(false);

  useImperativeHandle(
    ref,
    () => ({
      setOpen: () => {
        setIsOpen(true);
      },
      setClose: () => {
        setIsOpen(false);
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setIsOpen]
  );

  return (
    <>
      <Dialog
        maxWidth={maxWidth}
        open={isOpen}
        sx={{ "& .MuiDialog-paper": { width: "100%" } }}
        className={sideMenu ? "sideModal" : ""}
        onClick={(e) => {
          if (dismissBackgroud) {
            setIsOpen(false);
            onClose();
          }
        }}
      >
        <DialogTitle
          style={{ background: "#e92220", color: "#FFF", padding: "8px 24px" }}
        >
          {title}
          <IconButton
            aria-label="close"
            sx={{
              position: "absolute",
              right: 8,
              top: 5,
              color: "#FFF",
            }}
            onClick={(e) => {
              setIsOpen(false);
              onClose();
            }}
          >
            <Icons.CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent dividers>{children}</DialogContent>
        {onSubmit !== undefined || (hideSubmit !== undefined && !hideSubmit) ? (
          <DialogActions>
            {footerAction !== undefined
              ? footerAction.map((rows, index) => rows.render)
              : ""}
            <MButton
              type="submit"
              onClick={(e) => onSubmit(e)}
              loading={submitLoading}
            >
              {submitButtonText !== undefined ? submitButtonText : "Submit"}
            </MButton>
          </DialogActions>
        ) : (
          ""
        )}
      </Dialog>
    </>
  );
});
const MashModal = React.memo(MasModalFunc);
export default MashModal;
