//@ts-nocheck
import { Grid, Paper, InputLabel } from "components/material-ui";
import { MTextField, MButton, MashTablePro } from "components/ui";
import React, { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { SYSTEM_PAGE_TITLE } from "util/constants";
// import { APPLICATION_STATUS_TYPES } from "util/constants";
import MashModal from "common/modal";
import YouMarker from "../../../assets/images/you.png";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { setApplicationTitle } from "util/helper";
import { API_STATUS_SUCCESS } from "util/constants";
import * as Endpoints from "network/Endpoints";
import { replaceStr, showToast } from "util/helper";
import { useFetchAPI } from "network/useFetchAPI";
import { StateContext } from "context/context";
import { getAuthUserData } from "util/helper";
import * as RouteConfig from "routes/config";

import Loader from "components/loader";
const img = {
  display: "block",
  width: "100%",
  height: "100%",
};

declare global {
  interface Window {
    initMap: () => void;
  }
}

export const DashboardFunc = React.forwardRef((props: any, ref) => {
  const location: any = useLocation();
  const contextData = React.useContext(StateContext);
  let authUser = getAuthUserData(contextData);
  const navigate = useNavigate();

  const appInfo =
    location.state && location.state !== undefined ? location.state : {};
  const [applicationData, setApplicationData] = useState(null);
  const [errorFlag, setErrorFlag] = useState({ image: false, map: false });
  const [stateMarker, setStateMarker] = useState(null);
  const [statemarkerFlag, setStatemarkerFlag] = useState(true);

  const modalRef = useRef<any>();
  const technicalModelRef = useRef<any>();
  const newUsersColumn = [
    {
      field: "selectUser",
      title: "Select",
      width: "40%",
      render: (rowData) => (
        <MButton
          onClick={() => {
            technicalModelRef.current.setClose();
            submitRequest("TechnicianAssign", rowData.id);
          }}
          small={true}
        >
          Select
        </MButton>
      ),
    },

    {
      field: "username",
      title: "Username",
      width: "60%",
    },
  ];

  const [tempTechnician, setTechnician] = useState<any>([]);
  let map: google.maps.Map;
  let infoWindow: google.maps.InfoWindow;

  function initMap() {
    var map = new google.maps.Map(
      document.getElementById("map") as HTMLElement,
      {
        zoom: 13,
        center: new google.maps.LatLng(25.19949381105219, 55.27968956834465),
        disableDoubleClickZoom: true,
        mapTypeControl: false,
        mapTypeControlOptions: {
          style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
          mapTypeIds: ["roadmap", "SATELLITE"],
          position: google.maps.ControlPosition.LEFT_TOP,
        },
        zoomControl: true,
        zoomControlOptions: {
          position: google.maps.ControlPosition.LEFT_CENTER,
        },
        gestureHandling: "greedy",
        scaleControl: true,
        streetViewControl: false,
        streetViewControlOptions: {
          position: google.maps.ControlPosition.LEFT_TOP,
        },
        fullscreenControl: true,
        fullscreenControlOptions: {
          position: google.maps.ControlPosition.LEFT_CENTER,
        },
        styles: [
          {
            stylers: [
              {
                visibility: "on",
              },
            ],
          },
          {
            elementType: "geometry",
            stylers: [
              {
                color: "#f5f5f5",
              },
            ],
          },
          {
            elementType: "labels.icon",
            stylers: [
              {
                visibility: "on",
              },
            ],
          },
          {
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#616161",
              },
            ],
          },
          {
            elementType: "labels.text.stroke",
            stylers: [
              {
                color: "#f5f5f5",
              },
            ],
          },
          {
            featureType: "administrative.land_parcel",
            elementType: "labels.icon",
            stylers: [
              {
                visibility: "on",
              },
            ],
          },
          {
            featureType: "administrative.land_parcel",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#bdbdbd",
              },
            ],
          },
          {
            featureType: "poi",
            elementType: "geometry",
            stylers: [
              {
                color: "#eeeeee",
              },
              {
                weight: 8,
              },
            ],
          },
          {
            featureType: "poi",
            elementType: "labels.icon",
            stylers: [
              {
                visibility: "on",
              },
            ],
          },
          {
            featureType: "poi",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#757575",
              },
            ],
          },
          {
            featureType: "poi.park",
            stylers: [
              {
                weight: 8,
              },
            ],
          },
          {
            featureType: "poi.park",
            elementType: "geometry",
            stylers: [
              {
                color: "#e5e5e5",
              },
            ],
          },
          {
            featureType: "poi.park",
            elementType: "labels.icon",
            stylers: [
              {
                visibility: "on",
              },
            ],
          },
          {
            featureType: "poi.park",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#9e9e9e",
              },
            ],
          },
          {
            featureType: "road",
            elementType: "geometry",
            stylers: [
              {
                color: "#ffffff",
              },
            ],
          },
          {
            featureType: "road",
            elementType: "labels.icon",
            stylers: [
              {
                visibility: "on",
              },
            ],
          },
          {
            featureType: "road.arterial",
            elementType: "labels.icon",
            stylers: [
              {
                visibility: "on",
              },
            ],
          },
          {
            featureType: "road.arterial",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#757575",
              },
            ],
          },
          {
            featureType: "road.highway",
            elementType: "geometry",
            stylers: [
              {
                color: "#dadada",
              },
            ],
          },
          {
            featureType: "road.highway",
            elementType: "labels.icon",
            stylers: [
              {
                visibility: "on",
              },
            ],
          },
          {
            featureType: "road.highway",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#616161",
              },
            ],
          },
          {
            featureType: "road.local",
            elementType: "labels.icon",
            stylers: [
              {
                visibility: "on",
              },
            ],
          },
          {
            featureType: "road.local",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#9e9e9e",
              },
            ],
          },
          {
            featureType: "transit.line",
            elementType: "geometry",
            stylers: [
              {
                color: "#e5e5e5",
              },
            ],
          },
          {
            featureType: "transit.line",
            elementType: "labels.icon",
            stylers: [
              {
                visibility: "on",
              },
            ],
          },
          {
            featureType: "transit.station",
            elementType: "geometry",
            stylers: [
              {
                color: "#eeeeee",
              },
            ],
          },
          {
            featureType: "transit.station",
            elementType: "labels.icon",
            stylers: [
              {
                visibility: "on",
              },
            ],
          },
          {
            featureType: "water",
            elementType: "geometry",
            stylers: [
              {
                color: "#c9c9c9",
              },
            ],
          },
          {
            featureType: "water",
            elementType: "geometry.fill",
            stylers: [
              { color: "#2f618a" },
              { saturation: -80 },
              { lightness: -15 },
            ],
          },
          {
            featureType: "water",
            elementType: "labels.icon",
            stylers: [
              {
                color: "#757575",
              },
            ],
          },
          {
            featureType: "water",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#9e9e9e",
              },
            ],
          },
        ],
      }
    );

    var marker = new google.maps.Marker({
      map: map,
      draggable: false,
      icon: YouMarker,
      animation: google.maps.Animation.DROP,
    });
    setStateMarker(marker);
    infoWindow = new google.maps.InfoWindow();

    var input = document.getElementById("pac-input");

    var autocomplete = new google.maps.places.Autocomplete(input);

    map.controls[google.maps.ControlPosition.TOP].push(input);
    autocomplete.bindTo("bounds", map);

    autocomplete.addListener("place_changed", function () {
      marker.setVisible(false);
      var place = autocomplete.getPlace();
      if (!place.geometry) {
        // User entered the name of a Place that was not suggested and
        // pressed the Enter key, or the Place Details request failed.
        window.alert("No details available for input: '" + place.name + "'");
        return;
      }

      // If the place has a geometry, then present it on a map.
      if (place.geometry.viewport) {
        map.fitBounds(place.geometry.viewport);
      } else {
        map.setCenter(place.geometry.location);
        map.setZoom(17); // Why 17? Because it looks good.
      }
      marker.setPosition(place.geometry.location);
      marker.setVisible(true);
    });
  }

  const { control, getValues, reset } = useForm({
    mode: "onTouched",
    defaultValues: {
      requestType: "",
      complaintDescription: "",
      adminComment: "",
      technicianComment: "",
    },
  });

  useEffect(() => {
    document.title = SYSTEM_PAGE_TITLE.VIEW_APPLICATION;
    if (appInfo !== undefined && Object.keys(appInfo).length > 0) {
      setApplicationTitle("View Application ID | #" + appInfo.applicationId);
    }
    const script = document.createElement("script");

    script.src =
      "https://maps.googleapis.com/maps/api/js?libraries=places,drawing&callback=initMap";
    script.async = true;

    document.body.appendChild(script);
    window.initMap = initMap;

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {}, [stateMarker]);

  const {
    execute: getApplicationData,
    response: getApplicationDataResponse,
    loading: getApplicationDataLoading,
  } = useFetchAPI(
    replaceStr(Endpoints.APPLICATION_RETRIEVE, "id", appInfo.applicationId),
    {
      method: "GET",
    }
  );

  useEffect(() => {
    if (!getApplicationDataLoading && getApplicationDataResponse) {
      let { status, data, message } = getApplicationDataResponse;
      if (status === API_STATUS_SUCCESS) {
        if (data) {
          setApplicationData({
            requestType: data.requestType,
            complaintDescription: data.desc,
            image: data.image,
            lat: data.lat,
            lng: data.lng,
            appStatus: data.appStat,
          });
          reset({
            requestType: data.requestType,
            complaintDescription: data.desc,
            adminComment: data.adminComment ? data.adminComment : "",
            technicianComment: data.technicianComment
              ? data.technicianComment
              : "",
          });
        }
      } else {
        showToast("error", message);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getApplicationDataLoading, getApplicationDataResponse]);

  const {
    execute: updateApplicationData,
    response: updateApplicationDataResponse,
    loading: updateApplicationDataLoading,
  } = useFetchAPI(
    replaceStr(Endpoints.APPLICATION_UPDATE, "id", appInfo.applicationId),
    {
      method: "PUT",
    }
  );

  useEffect(() => {
    if (!updateApplicationDataLoading && updateApplicationDataResponse) {
      let { status, data, message } = updateApplicationDataResponse;
      if (status === API_STATUS_SUCCESS) {
        showToast("success", message);
        // getApplicationData();
        navigate(RouteConfig.DASHBOARD);
      } else {
        showToast("error", message);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateApplicationDataLoading, updateApplicationDataResponse]);

  const {
    execute: getTechnicianData,
    response: getTechnicianDataResponse,
    loading: getTechnicianDataLoading,
  } = useFetchAPI(Endpoints.USER_TECHNICIAN, {
    method: "GET",
  });

  useEffect(() => {
    if (!getTechnicianDataLoading && getTechnicianDataResponse) {
      let { status, data, message } = getTechnicianDataResponse;
      if (status === API_STATUS_SUCCESS) {
        setTechnician([...data]);
      } else {
        showToast("error", message);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTechnicianDataLoading, getTechnicianDataResponse]);

  useEffect(() => {
    getApplicationData();
    if (authUser["userType"] === "Admin") {
      getTechnicianData();
    }
  }, []);
  useEffect(() => {
    if (statemarkerFlag && stateMarker && applicationData) {
      stateMarker.setPosition(
        new google.maps.LatLng(applicationData.lat, applicationData.lng)
      );
      stateMarker.map.setCenter(
        new google.maps.LatLng(applicationData.lat, applicationData.lng)
      );
      setStatemarkerFlag(false);
    }
  }, [stateMarker, applicationData]);

  const submitRequest = (action = "default", data = "") => {
    let request = {};
    debugger;
    if (getValues("adminComment") !== "") {
      request.adminComment = getValues("adminComment");
    }

    if (getValues("technicianComment") !== "") {
      request.technicianComment = getValues("tedatachnicianComment");
    }
    if (action === "Resolved") {
      request.resolved = true;
    }
    if (action === "TechnicianAssign") {
      request.technicianAssigned = data;
    }
    if (action === "Reject") {
      request.rejected = data;
    }

    updateApplicationData({
      data: request,
    });
  };

  return (
    <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
      <Grid container xs={12} sm={12} md={12} lg={12} xl={12} mt={1}>
        <Paper className="paperFullWidth">
          <form onSubmit={() => {}} style={{ width: "100%" }}>
            <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
              <MTextField
                name={"requestType"}
                label={"Request Type"}
                grid={4}
                required={true}
                control={control}
                disabled={true}
              />
            </Grid>
            <Grid container xs={12} sm={12} md={12} lg={4} xl={4}>
              <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputLabel shrink id="mutiple-select-label">
                  <span
                    style={{ color: errorFlag.image ? "#b00020" : "#313131" }}
                  >
                    Image
                  </span>
                  <span style={{ color: "red" }}> *</span>
                </InputLabel>
              </Grid>

              <Grid container xs={12} sm={12} md={12} lg={6} xl={6}>
                {applicationData && (
                  <img
                    style={img}
                    className={"viewImg"}
                    src={applicationData.image}
                    alt={"Complaint Image"}
                    onClick={() => modalRef.current.setOpen()}
                  />
                )}
              </Grid>
            </Grid>
            <Grid container xs={12} sm={12} md={12} lg={4} xl={4}>
              <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputLabel shrink id="mutiple-select-label">
                  <span
                    style={{ color: errorFlag.map ? "#b00020" : "#313131" }}
                  >
                    Location
                  </span>
                  <span style={{ color: "red" }}> *</span>
                </InputLabel>
              </Grid>

              <input
                type="hidden"
                id="lat"
                name="lat"
                style={{ width: "30%" }}
                value=""
              />
              <input
                type="hidden"
                id="lon"
                name="lon"
                style={{ width: "30%" }}
                value=""
              />
              <div
                id="location_content"
                style={{ textAlign: "center", color: "W" }}
              ></div>
              <div
                style={{
                  width: "100%",
                  height: "300px",
                  marginLeft: 5,
                  borderRadius: 5,
                  border: "1px #313131 solid",
                }}
                id="map"
              ></div>
              <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
                {errorFlag.map && (
                  <p
                    style={{
                      fontSize: 12,
                      color: "#b00020",
                      fontWeight: "600",
                      marginTop: 0,
                      marginLeft: 5,
                    }}
                  >
                    Location is required
                  </p>
                )}
              </Grid>
              <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
                <MButton
                  onClick={() => {
                    let url = `https://maps.google.com/?q=${applicationData.lat},${applicationData.lng}`;
                    window.open(url);
                  }}
                  style={{ marginTop: 8, marginLeft: 3 }}
                >
                  Open in Google Maps
                </MButton>
              </Grid>
            </Grid>
            <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
              <MTextField
                name={"complaintDescription"}
                label={"Complaint"}
                grid={4}
                textarea={true}
                areaRow={8}
                required={true}
                control={control}
                disabled={true}
              />
            </Grid>

            {(authUser["userType"] === "Admin" ||
              authUser["userType"] === "Technician") && (
              <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
                <MTextField
                  name={"adminComment"}
                  label={"Admin Comment"}
                  grid={4}
                  textarea={true}
                  areaRow={8}
                  control={control}
                  disabled={!(authUser["userType"] === "Admin")}
                />
              </Grid>
            )}
            {(authUser["userType"] === "Admin" ||
              authUser["userType"] === "Technician") && (
              <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
                <MTextField
                  name={"technicianComment"}
                  label={"Technician Comment"}
                  grid={4}
                  textarea={true}
                  areaRow={8}
                  control={control}
                  disabled={!(authUser["userType"] === "Technician")}
                />
              </Grid>
            )}

            <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid
                item
                style={{
                  display: "inline-block",
                  alignSelf: "center",
                  marginTop: "2.4vh",
                }}
              >
                {(authUser["userType"] === "Admin" ||
                  authUser["userType"] === "Technician") && (
                  <MButton
                    onClick={() => submitRequest()}
                    style={{ marginLeft: 10, padding: 5 }}
                  >
                    Save
                  </MButton>
                )}
                {authUser["userType"] === "Admin" &&
                  applicationData &&
                  applicationData["appStatus"] !== "Completed" && (
                    <MButton
                      onClick={() => {
                        technicalModelRef.current.setOpen();
                      }}
                      style={{ marginLeft: 10, padding: 5 }}
                    >
                      Assign to Technician
                    </MButton>
                  )}
                {authUser["userType"] === "Technician" && (
                  <MButton
                    onClick={() => submitRequest("Resolved", true)}
                    style={{ marginLeft: 10, padding: 5 }}
                  >
                    Resolved
                  </MButton>
                )}
                {authUser["userType"] === "Admin" &&
                  applicationData &&
                  applicationData["appStatus"] !== "Completed" && (
                    <MButton
                      onClick={() => submitRequest("Reject", true)}
                      style={{ marginLeft: 10, padding: 5 }}
                    >
                      Reject
                    </MButton>
                  )}
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
      <MashModal
        ref={modalRef}
        title={`Complaint Picture`}
        maxWidth="xl"
        onClose={(e) => {}}
        hideSubmit={true}
        dismissBackgroud={false}
      >
        <Grid container item={true} xs={12} sm={12} md={12} lg={12} xl={12}>
          {applicationData && (
            <img src={applicationData.image} className="center-fit"></img>
          )}
        </Grid>
      </MashModal>
      <MashModal
        ref={technicalModelRef}
        title={"Select Technician"}
        maxWidth="sm"
        onClose={(e) => {}}
        dismissBackgroud={false}
      >
        <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
          <MashTablePro
            id="addUserToGroup"
            columns={newUsersColumn}
            rows={tempTechnician}
            height={300}
            isLoading={false}
          />
        </Grid>
      </MashModal>
      <Loader
        enable={
          updateApplicationDataLoading ||
          getApplicationDataLoading ||
          getTechnicianDataLoading
        }
      />
    </Grid>
  );
});
const Dashboard = React.memo(DashboardFunc);
export default Dashboard;
