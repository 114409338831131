import { useState, useEffect } from "react";
import {
  InputAdornment,
  IconButton,
  Icons,
  styled,
  TextField as BaseTextField,
  Grid,
} from "components/material-ui";
import {
  ALLOWED_SPECIAL_CHARACTERS,
  NOT_ALLOWED_NUMBER,
  NOT_ALLOWED_SPECIAL_CHARACTERS,
} from "../../utils/constants";
import { TextFieldProps, TextFieldStyleProps } from "./type";
import { Controller } from "react-hook-form";
import "./index.css";
import CircularProgress from "../CircularProgress";

export const TextInputField = styled(BaseTextField)(
  ({ disabled, errorTrigger, search }: TextFieldStyleProps) => ({
    "& .MuiInput-root": {
      color: "#313131",
      fontWeight: "500",
      border: "1px solid #dde0e9",
      backgroundColor: search ? "#f3f5fa" : "#fff",
      fontSize: "clamp(0.7rem, 1rem, 1.2rem) !important",
      lineHeight: "unset",
      "&.Mui-focused": {
        color: "#313131",
        fontWeight: "500",
        borderColor: search ? "#313131 !important" : "rgba(0, 0, 0, 0.23)",
        borderWidth: search ? "3px !important" : "1px",
        backgroundColor: "#fff",
      },
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: "20px",
        borderColor: "#000fff",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#C52328",
        borderWidth: "2px",
      },
    },
    "&.MuiInputBase-inputAdornedEnd": {
      visibility: "hidden",
    },
    "& .MuiFormHelperText-root": {
      fontFamily: "Google-Sans !important",
    },
    "& .MuiInputLabel-root": {
      color: "#313131",
      fontWeight: "500",
      fontFamily: "Google-Sans !important",
      "&.Mui-focused": {
        color: "#313131",
        fontWeight: "600",
      },
      "&.Mui-error": {
        color: "#b00020",
        fontWeight: "600",
        marginTop: 1,
      },
    },
    "& .MuiFormHelperText-root.Mui-error": {
      color: "#b00020",
      fontWeight: "600",
      fontFamily: "Google-Sans-Bold !important",
      margin: 0,
    },
    "& .MuiInput-input": {
      padding: "0px !important",
    },
  })
);

const MTextField = (props: TextFieldProps) => {
  let {
    label,
    name,
    number,
    password,
    onInputChange,
    onInputBlur,
    disabled,
    control,
    required,
    errorTrigger,
    ruleList,
    areaRow,
    textarea,
    grid,
    tooltip,
    onInputFocus,
    inputValue,
    fixedText,
    isLoading,
    placeholder,
    inputRef,
    ref,
    style,
    search,
    noPendingLeftRightGrid,
    small,
    containerStyle,
    underline,
  } = props;

  const [showPassword, setShowPassword] = useState(false);
  const [fieldError, setFieldError] = useState({
    status: false,
    message: "",
  });
  const [showTooltip, setShowTooltip] = useState(false);
  let rules: object = {};
  let styles: object = {};
  if (!textarea) {
    if (underline) {
      styles = {
        style: {
          height: "25px !important",
          borderBottom: "1px solid rgba(0, 0, 0, 0.23)",
          padding: 5,
          fontFamily: "Google-Sans",
          borderTop: 0,
          borderLeft: 0,
          borderRight: 0,
          borderRadius: 0,
        },
      };
    } else {
      styles = {
        style: {
          ...style,
          height:
            areaRow !== null && areaRow !== 1 ? "inherit" : small ? 30 : 35,
          border: "1px solid rgba(0, 0, 0, 0.23)",
          borderRadius: 5,
          padding: 5,
          fontFamily: "Google-Sans",
        },
      };
    }
  } else {
    styles = {
      style: {
        border: "1px solid rgba(0, 0, 0, 0.23)",
        borderRadius: 5,
        padding: 5,
        height: "inherit",
        fontFamily: "Google-Sans",
      },
    };
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = () => {};

  useEffect(() => {
    if (errorTrigger) {
      setFieldErrorData(true);
    } else {
      setFieldErrorData(false);
    }
  }, [errorTrigger]);

  const setFieldErrorData = (status) => {
    if (status) {
      if (!fieldError.status) {
        setFieldError({
          status: true,
          message: "Required",
        });
      }
    }
    if (!status) {
      if (fieldError.status) {
        setFieldError({
          status: false,
          message: "",
        });
      }
    }
  };
  const numberValidation = (onChange: any, e) => {
    let value = e.target.value;
    if (number) {
      try {
        if (value !== null) {
          if (value.includes(".")) {
            if (
              value.substr(value.indexOf(".") + 1, value.length).length ===
              (rules["decimal"] !== undefined ? rules["decimal"] + 1 : 3)
            ) {
            } else onChange(e);
          } else onChange(e);
        }
      } catch (e) {
        console.log(e);
      }
    } else onChange(e);
  };

  const onTextValueChange = (onChange: any) => (e) => {
    numberValidation(onChange, e);
    if (onInputChange !== null) {
      numberValidation(onInputChange, e);
    }
  };
  const onTextValueBlur = (onBlur: any) => (e) => {
    onBlur(e);
    if (onInputBlur !== null) {
      onInputBlur(e);
    }
  };

  if (required) {
    rules = {
      required: " Required",
      ...ruleList,
    };
  } else {
    rules = {
      required: false,
      ...ruleList,
    };
  }

  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      lg={grid}
      xl={grid}
      style={
        search
          ? { paddingLeft: 5, paddingRight: 5, marginBottom: 5 }
          : {
              ...containerStyle,
              paddingLeft: noPendingLeftRightGrid ? 0 : 5,
              paddingRight: noPendingLeftRightGrid ? 0 : 5,
              marginTop: 5,
            }
      }
    >
      {control !== undefined && control !== null ? (
        <Controller
          name={name}
          control={control}
          rules={rules}
          render={({
            field: { onChange, value, onBlur },
            fieldState: { error },
          }) => (
            <>
              <TextInputField
                variant="standard"
                type={
                  number
                    ? "number"
                    : password && !showPassword
                    ? "password"
                    : "text"
                }
                className={`
              ${areaRow === -1 && "fullScreenTextArea"}`}
                label={label}
                ref={ref}
                inputRef={inputRef}
                placeholder={placeholder !== null ? placeholder : ""}
                InputLabelProps={{ shrink: true, required: required }}
                value={textarea ? (value === null ? "" : value) : value}
                error={!!error}
                helperText={
                  error
                    ? error.message !== ""
                      ? label + error.message
                      : ""
                    : null
                }
                fullWidth
                size="small"
                disabled={disabled}
                InputProps={{
                  ...styles,
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      {password && (
                        <IconButton
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? (
                            <Icons.EyeIconOff />
                          ) : (
                            <Icons.EyeIcon />
                          )}
                        </IconButton>
                      )}

                      {fixedText !== undefined && fixedText !== "" && (
                        <>{fixedText}</>
                      )}
                      {isLoading && (
                        <CircularProgress size={20} colors={"#e92220"} />
                      )}
                      {error && (
                        <IconButton
                          style={{ fill: "#b00020", fontWeight: "100" }}
                        >
                          <Icons.WarningAmberRoundedIconThin />
                        </IconButton>
                      )}
                    </InputAdornment>
                  ),
                  inputProps: {
                    min: 0,
                    step: "any",
                  },
                }}
                multiline={textarea}
                rows={textarea ? (areaRow !== null ? areaRow : 3) : 1}
                onChange={onTextValueChange(onChange)}
                onBlur={onTextValueBlur(onBlur)}
                onKeyDown={(e) => {
                  e.stopPropagation();
                  if (number) {
                    if (e.key === "ArrowDown" || e.key === "ArrowUp") {
                      e.preventDefault();
                    }
                  }
                }}
                onKeyPress={(e) => {
                  if (number) {
                    if (e.key === "e" || e.key === "E") {
                      e.preventDefault();
                    }
                  }
                  if (
                    rules["maxLength"] !== undefined &&
                    value !== undefined &&
                    value !== null
                  ) {
                    if (rules["maxLength"].value === value.length) {
                      e.preventDefault();
                    }
                  }
                  if (NOT_ALLOWED_SPECIAL_CHARACTERS.includes(e.key)) {
                    if (rules["allowed"] !== undefined) {
                      if (
                        rules["allowed"].includes("special_character") ||
                        rules["allowed"].includes("all_special_character")
                      ) {
                        if (
                          !rules["allowed"].includes("all_special_character")
                        ) {
                          if (!ALLOWED_SPECIAL_CHARACTERS.includes(e.key)) {
                            e.preventDefault();
                          }
                        }
                      }
                      if (rules["allowed"].includes("dot")) {
                        if (!(e.key === ".")) {
                          e.preventDefault();
                        }
                      }
                      if (rules["allowed"].includes("slash")) {
                        if (!(e.key === "/")) {
                          e.preventDefault();
                        }
                      }
                    } else {
                      e.preventDefault();
                    }
                  }
                  if (rules["notAllowed"] !== undefined) {
                    if (rules["notAllowed"].includes("space")) {
                      if (e.key === " ") {
                        e.preventDefault();
                      }
                    }
                    if (rules["notAllowed"].includes("numbers")) {
                      if (NOT_ALLOWED_NUMBER.includes(e.key)) {
                        e.preventDefault();
                      }
                    }
                    if (rules["notAllowed"].includes("startWithZero")) {
                      if (e.key === "0" && value === "") {
                        e.preventDefault();
                      }
                    }
                  }
                }}
                onMouseEnter={(e) => {
                  if (tooltip !== undefined && tooltip !== null) {
                    setShowTooltip(true);
                  }
                }}
                onMouseLeave={(e) => {
                  if (tooltip !== undefined && tooltip !== null) {
                    setShowTooltip(false);
                  }
                }}
              />
              {showTooltip && tooltip !== "" ? (
                <span className="tooltip">
                  {tooltip} <span className="tooltipTick"></span>
                </span>
              ) : (
                ""
              )}
            </>
          )}
        />
      ) : (
        <TextInputField
          variant="standard"
          type={
            number ? "number" : password && !showPassword ? "password" : "text"
          }
          className={`
          ${areaRow === -1 && "fullScreenTextArea"}`}
          label={label}
          value={inputValue}
          ref={ref}
          inputRef={inputRef}
          placeholder={
            errorTrigger !== null
              ? fieldError.status
                ? fieldError.message
                : ""
              : placeholder !== null
              ? placeholder
              : ""
          }
          search={search}
          fullWidth
          size="small"
          disabled={disabled}
          InputLabelProps={{
            shrink: true,
          }}
          style={style}
          InputProps={{
            ...styles,
            disableUnderline: true,
            startAdornment: search && (
              <InputAdornment position="start">
                <IconButton>
                  <Icons.SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                {password && (
                  <IconButton
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Icons.EyeIconOff /> : <Icons.EyeIcon />}
                  </IconButton>
                )}

                {fixedText !== undefined && fixedText !== "" && (
                  <>{fixedText}</>
                )}
                {errorTrigger && (
                  <IconButton style={{ fill: "#b00020", fontWeight: "100" }}>
                    <Icons.WarningAmberRoundedIconThin />
                  </IconButton>
                )}
                {isLoading && <CircularProgress size={20} colors={"#e92220"} />}
              </InputAdornment>
            ),
            inputProps: {
              min: 0,
              step: "any",
            },
          }}
          error={fieldError.status}
          helperText={
            !errorTrigger ? "" : fieldError.status ? fieldError.message : ""
          }
          multiline={textarea}
          rows={textarea ? (areaRow !== null ? areaRow : 3) : 1}
          //Events
          onChange={(e) => {
            if (required) {
              if (e.target.value === "") {
                setFieldErrorData(true);
              } else {
                setFieldErrorData(false);
              }
            }
            if (number) {
              numberValidation(onInputChange, e);
            } else onInputChange(e);
          }}
          onBlur={(e) => {
            if (required) {
              if (e.target.value === "") {
                setFieldErrorData(true);
              } else {
                setFieldErrorData(false);
              }
            }
          }}
          onClick={onInputFocus}
          onKeyDown={(e) => {
            e.stopPropagation();
            if (number) {
              if (e.key === "ArrowDown" || e.key === "ArrowUp") {
                e.preventDefault();
              }
            }
          }}
          onKeyPress={(e) => {
            if (number) {
              if (e.key === "e" || e.key === "E") {
                e.preventDefault();
              }
            }
            if (
              rules["maxLength"] !== undefined &&
              inputValue !== undefined &&
              inputValue !== null
            ) {
              if (rules["maxLength"].value === inputValue.length) {
                e.preventDefault();
              }
            }
            if (NOT_ALLOWED_SPECIAL_CHARACTERS.includes(e.key)) {
              if (rules["allowed"] !== undefined) {
                if (
                  rules["allowed"].includes("special_character") ||
                  rules["allowed"].includes("all_special_character")
                ) {
                  if (!rules["allowed"].includes("all_special_character")) {
                    if (!ALLOWED_SPECIAL_CHARACTERS.includes(e.key)) {
                      e.preventDefault();
                    }
                  }
                }
                if (rules["allowed"].includes("dot")) {
                  if (!(e.key === ".")) {
                    e.preventDefault();
                  }
                }
                if (rules["allowed"].includes("slash")) {
                  if (!(e.key === "/")) {
                    e.preventDefault();
                  }
                }
              } else {
                e.preventDefault();
              }
            }
            if (rules["notAllowed"] !== undefined) {
              if (rules["notAllowed"].includes("space")) {
                if (e.key === " ") {
                  e.preventDefault();
                }
              }
              if (rules["notAllowed"].includes("startWithZero")) {
                if (e.key === "0" && inputValue === "") {
                  e.preventDefault();
                }
              }
            }
          }}
        />
      )}
    </Grid>
  );
};
MTextField.defaultProps = {
  number: false,
  password: false,
  disabled: false,
  required: false,
  onInputChange: null,
  onInputBlur: null,
  errorTrigger: null,
  ruleList: {},
  textarea: false,
  areaRow: null,
  inputValue: null,
  isLoading: false,
  placeholder: null,
  inputRef: null,
  ref: null,
  style: {},
  search: false,
  containerStyle: {},
};
export default MTextField;
