import React, { useEffect, useState } from "react";
import { Grid } from "components/material-ui";
import { MashTablePro } from "components/ui";
import * as Endpoints from "network/Endpoints";
import {
  getDateTimeForDisp,
  getErrorMessage,
  getTimeDiff,
  replaceStr,
  showToast,
} from "util/helper";
import { useFetchAPI } from "network/useFetchAPI";
import { API_STATUS_SUCCESS } from "util/constants";

interface AuditHistoryProps {
  applicationId: any;
}

export const AuditHistoryFunc = React.forwardRef(
  (props: AuditHistoryProps, ref) => {
    const { applicationId } = props;
    const [auditList, setAuditList] = useState<any>([]);

    let auditColumns = [
      {
        field: "createdBy",
        title: "Created By",
        width: "10%",
      },
      {
        field: "groupId",
        title: "Group",
        width: "15%",
        render: (rowData) => (
          <span>{`${rowData.groupId}${
            rowData.assignee !== null ? " - " + rowData.assignee : ""
          }`}</span>
        ),
      },
      {
        field: "stage",
        title: "Stage",
        width: "10%",
      },
      {
        field: "createdOn",
        title: "Assigned Time",
        width: "8%",
        render: (rowData) => (
          <>
            {rowData["createdOn"] !== null
              ? getDateTimeForDisp(new Date(rowData["createdOn"]))
              : ""}
          </>
        ),
      },
      {
        field: "inTime",
        title: "In Time",
        width: "8%",
        render: (rowData) => (
          <>
            {rowData["inTime"] !== null
              ? getDateTimeForDisp(new Date(rowData["inTime"]))
              : ""}
          </>
        ),
      },
      {
        field: "outTime",
        title: "Out Time",
        width: "8%",
        render: (rowData) => (
          <>
            {rowData["outTime"] !== null
              ? getDateTimeForDisp(new Date(rowData["outTime"]))
              : ""}
          </>
        ),
      },
      {
        field: "waitTime",
        title: "Wait Time",
        width: "8%",
        render: (rowData) => (
          <>
            {rowData["inTime"] === null || rowData["createdOn"] === null
              ? ""
              : getTimeDiff(
                  new Date(rowData["inTime"]),
                  new Date(rowData["createdOn"])
                )}
          </>
        ),
      },
      {
        field: "processTime",
        title: "Process Time",
        width: "8%",
        render: (rowData) => (
          <>
            {rowData["inTime"] === null || rowData["outTime"] === null
              ? ""
              : getTimeDiff(
                  new Date(rowData["outTime"]),
                  new Date(rowData["inTime"])
                )}
          </>
        ),
      },
      {
        field: "comments",
        title: "Action Taken",
        width: "25%",
        render: (rowData) => (
          <>
            {rowData.comments !== null && (
              <span>
                <b>Comments:</b> {rowData.comments}
              </span>
            )}
            {rowData.decision !== null && (
              <span>
                <b>Decision:</b> {rowData.decision}
              </span>
            )}
            {rowData.reason !== null && (
              <span>
                <b>Reason:</b> {rowData.reason}
              </span>
            )}
          </>
        ),
      },
    ];

    useEffect(() => {
      getAuditHistoryData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const {
      execute: getAuditHistoryData,
      response: getAuditTrailDataResponse,
      loading: getAuditTrailDataLoading,
      reqHeaders: getAuditTrailDataHeaders,
    } = useFetchAPI(
      replaceStr(Endpoints.AUDIT_HISTORY_LIST, "applicationId", applicationId),
      {
        method: "GET",
        applicationId: applicationId,
      }
    );

    useEffect(() => {
      if (!getAuditTrailDataLoading && getAuditTrailDataResponse) {
        let { status, data, errorCode } = getAuditTrailDataResponse;

        if (status === API_STATUS_SUCCESS) {
          if (data !== undefined && data !== null) {
            setAuditList(data);
          } else {
            setAuditList([]);
          }
        } else {
          showToast(
            "error",
            getErrorMessage(errorCode),
            getAuditTrailDataHeaders["X-CORRELATION-ID"]
          );
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getAuditTrailDataLoading, getAuditTrailDataResponse]);

    return (
      <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
        <MashTablePro
          id={"AuditHistory"}
          title={"Audit History"}
          rows={auditList}
          columns={auditColumns}
          height={400}
          isLoading={getAuditTrailDataLoading}
        />
      </Grid>
    );
  }
);
const AuditHistory = React.memo(AuditHistoryFunc);
export default AuditHistory;
