export const getRule = (fieldName: string) => {
  var rules: any = {
    userName: {
      minLength: {
        value: 5,
        message: " should be more than 5 Characters.",
      },
      maxLength: {
        value: 50,
        message: " should be less than 50 Characters.",
      },
      notAllowed: ["space"],
    },
    otp: {
      minLength: {
        value: 4,
        message: " should be 4 digits.",
      },
      maxLength: {
        value: 4,
        message: " should be 4 digits.",
      },
      notAllowed: ["space"],
    },
    firstName: {
      maxLength: {
        value: 100,
        message: " should be less than 100 Characters.",
      },
      notAllowed: ["numbers"],
      pattern: {
        value: /^[a-zA-Z ]{1,100}$/i,
        message: " is Invalid",
      },
    },
    lastName: {
      maxLength: {
        value: 100,
        message: " should be less than 100 Characters.",
      },
      pattern: {
        value: /^[a-zA-Z ]{1,100}$/i,
        message: " is Invalid",
      },
      notAllowed: ["numbers"],
    },
    emailAddress: {
      pattern: {
        value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/i,
        message: " is Invalid",
      },

      maxLength: {
        value: 100,
        message: " should be less than 100 Characters.",
      },
      allowed: ["special_Characters"],
    },
    staffId: {
      maxLength: {
        value: 10,
        message: " should be less than 10 Characters.",
      },
    },
    password: {
      minLength: {
        value: 6,
        message: " min 6 characters required",
      },
      maxLength: {
        value: 20,
        message: " should be less than 20 Characters.",
      },
      allowed: ["special_Characters"],
    },
    mobileNo: {
      minLength: {
        value: 10,
        message: " should be 10 digits",
      },
      maxLength: {
        value: 10,
        message: " should be 10 digits",
      },
    },
    applicationComment: {
      maxLength: {
        value: 4000,
        message: " should be less than 4000 Characters.",
      },
      allowed: ["all_special_Characters"],
    },
    multipleApplicationIds: {
      allowed: ["special_Characters"],
    },
  };
  return rules[fieldName];
};
