import { Box, Grid, Skeleton } from "components/material-ui";
import { ReadOnlyHeader, ReadOnlyText } from "components/ui";

interface renderGridProps {
  grid: any;
  label: any;
  value: any;
  isLoading?: boolean;
}

const RenderGridBox = ({
  grid,
  label,
  value,
  isLoading = false,
}: renderGridProps) => {
  return (
    <Grid
      item
      xs={grid}
      sm={grid}
      md={grid}
      lg={grid}
      xl={grid}
      style={{ paddingRight: 2, paddingLeft: 2 }}
    >
      {isLoading ? (
        <Skeleton height={70} />
      ) : (
        <Box className="readOnlyField_Box">
          <ReadOnlyHeader>{label}</ReadOnlyHeader>
          <ReadOnlyText value={value} />
        </Box>
      )}
    </Grid>
  );
};

export default RenderGridBox;
