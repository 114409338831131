import {
  Dialog,
  DialogContent,
  DialogActions,
  Box,
  Grid,
  Icons,
} from "components/material-ui";
import { MButton } from "components/ui";
import create from "zustand";

type AlertDialogStore = {
  severity: string;
  message: string;
  onConfirm?: () => void;
  close: () => void;
  actionMessage?: string;
  onAction?: () => void;
};

const useAlertDialogStore = create<AlertDialogStore>((set) => ({
  severity: "",
  message: "",
  actionMessage: "",
  onConfirm: undefined,
  onAction: undefined,
  close: () => set({ onConfirm: undefined, onAction: undefined }),
}));

export const alertDialog = (
  severity: string,
  message: string,
  actionMessage: string,
  onConfirm: () => void,
  onAction?: () => void
) => {
  useAlertDialogStore.setState({
    severity,
    message,
    actionMessage,
    onConfirm,
    onAction,
  });
};

const AlertDialog = () => {
  const { message, onConfirm, close, severity, actionMessage, onAction } =
    useAlertDialogStore();

  const SuccessIcon = () => (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      style={{
        backgroundColor: "green",
        width: 70,
        height: 70,
        fill: "white",
        borderRadius: 50,
        margin: "auto",
        marginTop: 20,
      }}
    >
      <Icons.TickLine width={30} height={40} />
    </Grid>
  );

  return (
    <Dialog open={Boolean(onConfirm)} onClose={close} maxWidth="xs" fullWidth>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        style={{ position: "relative" }}
      >
        {severity === "success" && <SuccessIcon />}
      </Box>
      <DialogContent style={{ textAlign: "center" }}>
        <span>{message}</span>
      </DialogContent>
      <DialogActions
        style={{
          padding: "0px 15px 15px 0px",
          textAlign: "center",
          display: "block",
        }}
      >
        <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
          <MButton
            style={{ margin: "auto" }}
            onClick={() => {
              if (onConfirm) {
                onConfirm();
              }
              close();
            }}
          >
            Ok, Thanks
          </MButton>
        </Grid>
        {actionMessage !== undefined && actionMessage !== "" && (
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            alignItems="center"
            mt={1}
          >
            <span
              className="linkUI"
              style={{ fontSize: 14 }}
              onClick={(e) => {
                if (onAction) {
                  onAction();
                }
                close();
              }}
            >
              {actionMessage}
            </span>
          </Grid>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
