import { Grid } from "components/material-ui";
import Lottie from "react-lottie";
import riseAndShineLogo from "../../utils/lottie/riseAndShineLogorj.json";

const OpeningLoading = () => {
  return (
    <Grid
      container
      item={true}
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      style={{ height: "100vh" }}
    >
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: riseAndShineLogo,
          rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
          },
        }}
        style={{ margin: "auto auto" }}
        height={100}
        width={300}
      />
    </Grid>
  );
};

export default OpeningLoading;
