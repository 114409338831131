import { ERROR_TYPES, networkErrorType } from "../utils/constant";

const defaultError: networkErrorType = {
  errorType: ERROR_TYPES.GLOBAL_NETWORK_ERROR,
};

export default function defaultResponseError(error: any = {}) {
  const data = error && error.response ? error.response.data : {};
  if (error && error.response && error.response.status) {
    switch (error.response.status) {
      case 200:
        return Promise.reject({ data, errorType: ERROR_TYPES.API_ERROR });
      case 401:
        return Promise.reject({ data, errorType: ERROR_TYPES.UNAUTHORIZED });
      case 400:
        return Promise.reject({ data, errorType: ERROR_TYPES.BAD_REQUEST });
      case 500:
        return Promise.reject({
          data,
          errorType: ERROR_TYPES.INTERNAL_SERVER_ERROR,
        });
      case 502:
      case 503:
        return Promise.reject({
          data,
          errorType: ERROR_TYPES.GLOBAL_SERVER_ERROR,
        });
      default:
        return Promise.reject({ ...data, defaultError });
    }
  } else {
    return Promise.reject(error);
  }
}
