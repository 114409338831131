import { useState } from "react";
import {
  Box,
  CircularProgress as BaseCircularProgress,
  CssBaseline,
  Drawer,
  Icons,
  styled,
  Grid,
} from "components/material-ui";
import MButton from "../MButton";
import { SideBarWrapperProps } from "./types";
import AppMenu from "./AppMenu";
import "./index.css";

export const MUICircularProgress = styled(BaseCircularProgress)(({}: any) => ({
  color: "white",
}));

const MainContainer = styled.div`
  .drawerPaperSmall {
    position: relative;
    white-space: nowrap;
    padding-bottom: 4px;
    background: #fff;
    width: 280px;
    overflow: hidden;
    transition: width 0.5s;
    height: 100vh;
    border: none;
    box-shadow: 0px 59px 10px #e8e8e8;
  }
  .drawerPaperBig {
    white-space: nowrap;
    padding-bottom: 4px;
    background: #fff;
    width: 55px;
    overflow: hidden;
    transition: width 0.5s;
    height: 100vh;
    border: none;
    box-shadow: 0px 59px 10px #e8e8e8;
  }
  .drawerPaperSmallIcon {
    position: absolute;
    bottom: 0;
  }
  .menuItemText {
    padding-left: 35px !important;
  }
`;

const SideBarWrapper = (props: SideBarWrapperProps) => {
  const { children, options, onRedirect, mashreqLogo, rcmsLogo } = props;
  const [isHideShowMenu, setHideShowMenu] = useState(false);

  const hideSideMenu = () => {
    setHideShowMenu(false);
  };
  const toggleMenu = () => {
    setHideShowMenu(!isHideShowMenu);
  };

  return (
    <MainContainer style={{ flex: 1, width: "100%" }}>
      <CssBaseline />
      <Box style={{ position: "fixed", zIndex: 999 }}>
        <Drawer
          variant="permanent"
          classes={{
            paper: isHideShowMenu ? "drawerPaperSmall" : "drawerPaperBig",
          }}
        >
          <AppMenu
            isHideShowMenu={isHideShowMenu}
            appMenuItems={options}
            onRedirect={onRedirect}
            rcmsLogo={rcmsLogo}
            mashreqLogo={mashreqLogo}
          />
          <Box
            className={`sideShowHideIcon ${
              isHideShowMenu
                ? "ShowIconLeft drawerPaperSmallIcon"
                : "HideIconLeft drawerPaperBigIcon"
            }`}
            onClick={() => toggleMenu()}
          >
            {isHideShowMenu ? (
              <Icons.DoubleArrowLeft />
            ) : (
              <Icons.DoubleArrowRight />
            )}
          </Box>
        </Drawer>
      </Box>
      <Box
        style={{
          width: isHideShowMenu ? "calc(100% - 55px)" : "calc(100% - 55px)",
          marginLeft: "55px",
        }}
      >
        <Box>{children}</Box>
      </Box>
      <Grid item xs={6} sm={6} md={6} lg={6} xl={6} style={{ display: "none" }}>
        <MButton id="hideSideWrapper" onClick={hideSideMenu}>
          Hide Side Wrapper
        </MButton>
      </Grid>
    </MainContainer>
  );
};

export default SideBarWrapper;
