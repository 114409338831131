import { useEffect, useState } from "react";
import { SYSTEM_PAGE_TITLE } from "util/constants";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { replaceStr, setApplicationTitle } from "util/helper";
import { MSwitch } from "components/ui";
import { confirmDialog } from "common/confirm_dialog";
import { API_STATUS_SUCCESS } from "util/constants";
import * as Endpoints from "network/Endpoints";
import { showToast } from "util/helper";
import { useFetchAPI } from "network/useFetchAPI";
import Loader from "components/loader";
export default function DataTable() {
  useEffect(() => {
    document.title = SYSTEM_PAGE_TITLE.USER_MANAGEMENT_USERS;
    setApplicationTitle("User Management");
    getUserDetailsData();
  }, []);

  const [selectedUser, setSelectedUser] = useState<any>({
    id: "",
    status: false,
  });
  //@ts-ignore
  const [userList, setUserList] = useState<any>([]);

  const columns: GridColDef[] = [
    {
      field: "username",
      headerName: "UserName",
      width: 160,
      // renderCell: (rowData) => <span>{Number(rowData.id) + 2}</span>,
    },
    {
      field: "usertype",
      headerName: "User Type",
      width: 160,
      // renderCell: (rowData) => <span>{Number(rowData.id) + 2}</span>,
    },

    {
      field: "fullName",
      headerName: "Full name",
      sortable: true,
      width: 160,
      renderCell: (rowData) => (
        <span>{`${rowData["row"]["firstName"]} ${rowData["row"]["lastName"]}`}</span>
      ),
    },
    {
      field: "mobileNo",
      headerName: "Mobile No.",
      sortable: true,
      width: 160,
    },
    {
      field: "status",
      headerName: "Active/InActive",
      width: 70,
      renderCell: (rowData) => (
        <MSwitch
          checked={rowData["row"]["status"]}
          onChange={() => {
            debugger;

            confirmDialog(
              `Are you sure you want to ${
                !rowData["row"].status
                  ? "inactive this user?"
                  : "activate this user?"
              }`,
              () => {
                setSelectedUser({
                  id: rowData["row"].id,
                  status: !rowData["row"].status,
                });
              }
            );
          }}
        />
      ),
    },
  ];

  const {
    execute: getUserDetailsData,
    response: getUserDetailsDataResponse,
    loading: getUserDetailsDataLoading,
  } = useFetchAPI(Endpoints.USER_LIST, {
    method: "GET",
  });
  useEffect(() => {
    if (!getUserDetailsDataLoading && getUserDetailsDataResponse) {
      let { status, data, message } = getUserDetailsDataResponse;
      if (status === API_STATUS_SUCCESS) {
        if (data && data.length > 0) {
          setUserList([...data]);
        }
      } else {
        showToast("error", message);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUserDetailsDataLoading, getUserDetailsDataResponse]);

  const {
    execute: updateStatus,
    response: updateStatusResponse,
    loading: updateStatusLoading,
  } = useFetchAPI(replaceStr(Endpoints.USER_UPDATE, "id", selectedUser.id), {
    method: "PUT",
  });
  useEffect(() => {
    if (!updateStatusLoading && updateStatusResponse) {
      let { status, message } = updateStatusResponse;
      if (status === API_STATUS_SUCCESS) {
        getUserDetailsData();
      } else {
        showToast("error", message);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatusLoading, updateStatusResponse]);

  useEffect(() => {
    if (selectedUser && selectedUser.id !== "") {
      updateStatus({
        data: {
          status: selectedUser.status,
        },
      });
    }
  }, [selectedUser]);

  return (
    <>
      <div style={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={userList}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[5]}
          isRowSelectable={() => false}
        />
      </div>
      <Loader enable={updateStatusLoading} />
    </>
  );
}
