//@ts-nocheck
import { useEffect, useState } from "react";
import { API_STATUS_SUCCESS, SYSTEM_PAGE_TITLE } from "util/constants";
import { useNavigate } from "react-router-dom";
import { MTextField, MButton, CircularProgress } from "components/ui";
import { Box } from "components/material-ui";
import { getRule } from "util/validations";
import EmpireAELogo from "assets/images/rjlogolg.png";
import { useForm } from "react-hook-form";

import {
  USER_EMAIL_OTP,
  USER_EMAIL_VERIFY,
  USER_PASSWORD_RESET,
} from "network/Endpoints";
import * as RouteConfig from "routes/config";
import { getErrorMessage, showToast } from "util/helper";
import { Grid } from "components/ui";
import { useFetchAPI } from "network/useFetchAPI";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [userName, setUserName] = useState("");
  const [displayFlags, setDisplayFlags] = useState({
    otp: true,
    verify: false,
    reset: false,
  });
  const { control: control1, handleSubmit: handleSubmit1 } = useForm({
    mode: "onTouched",
  });
  const { control: control2, handleSubmit: handleSubmit2 } = useForm({
    mode: "onTouched",
  });
  const { control: control3, handleSubmit: handleSubmit3 } = useForm({
    mode: "onTouched",
  });
  useEffect(() => {
    document.title = SYSTEM_PAGE_TITLE.FORGOT_PASSWORD;
  }, []);

  const {
    execute: emailOtp,
    response: emailOtpResponse,
    loading: emailOtpLoading,
  } = useFetchAPI(USER_EMAIL_OTP, {
    method: "POST",
  });

  useEffect(() => {
    if (!emailOtpLoading && emailOtpResponse) {
      let { status, message } = emailOtpResponse;
      if (status === API_STATUS_SUCCESS) {
        showToast("success", "Otp sent to your mail!");
        setDisplayFlags({
          ...displayFlags,
          otp: false,
          verify: true,
          reset: false,
        });
      } else {
        showToast("error", message);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailOtpLoading, emailOtpResponse]);

  const {
    execute: verifyOtp,
    response: verifyOtpResponse,
    loading: verifyOtpLoading,
  } = useFetchAPI(USER_EMAIL_VERIFY, {
    method: "POST",
  });

  useEffect(() => {
    if (!verifyOtpLoading && verifyOtpResponse) {
      let { status, message } = verifyOtpResponse;
      if (status === API_STATUS_SUCCESS) {
        showToast("success", "OTP verified Successfully");
        setDisplayFlags({
          ...displayFlags,
          otp: false,
          verify: false,
          reset: true,
        });
      } else {
        showToast("error", message);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verifyOtpLoading, verifyOtpResponse]);

  const {
    execute: passwordReset,
    response: passwordResetResponse,
    loading: passwordResetLoading,
  } = useFetchAPI(USER_PASSWORD_RESET, {
    method: "PUT",
  });

  useEffect(() => {
    if (!passwordResetLoading && passwordResetResponse) {
      let { status, message } = passwordResetResponse;
      if (status === API_STATUS_SUCCESS) {
        showToast("success", message);
        setTimeout(() => {
          navigate(RouteConfig.LOGIN);
        }, 2500);
      } else {
        showToast("error", message);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passwordResetLoading, passwordResetResponse]);

  const onSubmit1 = (data) => {
    setUserName(data.username);
    emailOtp({
      data: {
        username: data.username,
      },
    });
  };

  const onSubmit2 = (data) => {
    verifyOtp({
      data: {
        otp: data.otp,
      },
    });
  };

  const onSubmit3 = (data) => {
    if (data.newPassword !== data.confirmPassword) {
      showToast("error", "Password does not match");
      return;
    }
    passwordReset({
      data: {
        username: userName,
        password: data.newPassword,
      },
    });
  };

  return (
    <Grid
      container
      item={true}
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      style={{ background: "#FFF" }}
    >
      <>
        <Grid
          container
          spacing={0}
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: "100vh" }}
        >
          <Grid item xs={10} sm={4} md={4}>
            <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid
                container
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                style={{ minHeight: "15vh" }}
              >
                <Box
                  style={{
                    padding: "3%",
                    borderRadius: 20,
                  }}
                >
                  <img
                    src={EmpireAELogo}
                    alt="Empire AE Logo"
                    style={{
                      margin: "auto",
                      width: "45vh",
                      display: "block",
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <span className="loginText1">Reset Password</span>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <span className="loginText2">Enter your new password</span>
              </Grid>
            </Grid>
            {displayFlags.otp && (
              <form onSubmit={handleSubmit1(onSubmit1)}>
                <Box style={{ marginTop: 10, width: "100%" }}>
                  <MTextField
                    name={"username"}
                    label={"User Name"}
                    control={control1}
                    grid={12}
                    required={true}
                    ruleList={getRule("emailAddress")}
                  />
                </Box>

                <Box
                  style={{
                    marginTop: 10,
                    marginLeft: 5,
                    marginRight: 5,
                    width: "100%",
                  }}
                >
                  <MButton
                    type={"submit"}
                    style={{
                      marginTop: 10,
                      width: "100%",
                    }}
                    disabled={emailOtpLoading}
                  >
                    {emailOtpLoading ? (
                      <CircularProgress colors={"white"} size={20} />
                    ) : (
                      "Send OTP to Mail"
                    )}
                  </MButton>
                </Box>
              </form>
            )}

            {displayFlags.verify && (
              <form onSubmit={handleSubmit2(onSubmit2)}>
                <Box style={{ marginTop: 10, width: "100%" }}>
                  <MTextField
                    number={true}
                    name={"otp"}
                    label={"Enter OTP"}
                    control={control2}
                    grid={12}
                    required={true}
                    ruleList={getRule("otp")}
                  />
                </Box>

                <Box
                  style={{
                    marginTop: 10,
                    marginLeft: 5,
                    marginRight: 5,
                    width: "100%",
                  }}
                >
                  <MButton
                    type={"submit"}
                    style={{
                      marginTop: 10,
                      width: "100%",
                    }}
                    disabled={emailOtpLoading}
                  >
                    {emailOtpLoading ? (
                      <CircularProgress colors={"white"} size={20} />
                    ) : (
                      "Enter OTP"
                    )}
                  </MButton>
                </Box>
              </form>
            )}
            {displayFlags.reset && (
              <form onSubmit={handleSubmit3(onSubmit3)}>
                <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Box style={{ marginTop: 20, width: "100%" }}>
                    <MTextField
                      password={true}
                      name={"newPassword"}
                      label={"New Password"}
                      control={control3}
                      grid={12}
                      required={true}
                      ruleList={getRule("password")}
                    />
                  </Box>
                  <Box style={{ marginTop: 10, width: "100%" }}>
                    <MTextField
                      password={true}
                      name={"confirmPassword"}
                      label={"Confirm Password"}
                      control={control3}
                      grid={12}
                      required={true}
                      ruleList={getRule("password")}
                    />
                  </Box>

                  <Box
                    style={{
                      marginTop: 10,
                      marginLeft: 5,
                      marginRight: 5,
                      width: "100%",
                    }}
                  >
                    <MButton
                      type={"submit"}
                      style={{
                        marginTop: 10,
                        width: "100%",
                      }}
                      disabled={passwordResetLoading}
                    >
                      {passwordResetLoading ? (
                        <CircularProgress colors={"white"} size={20} />
                      ) : (
                        "Reset Password"
                      )}
                    </MButton>
                  </Box>
                </Grid>
              </form>
            )}
          </Grid>
        </Grid>
      </>
    </Grid>
  );
};

export default ForgotPassword;
