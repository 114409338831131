import animationData from './lottie/logoAnimation.json'

export const getDateForDisp = (date) => {
  if (date !== null) {
    var onlyDate = date.getDate()
    var stringOnlyDate = onlyDate.toString()
    if (onlyDate <= 9) {
      stringOnlyDate = '0' + onlyDate.toString()
    }
    var onlyMonth = date.getMonth()
    var onlyYear = date.getFullYear().toString()
    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sept',
      'Oct',
      'Nov',
      'Dec'
    ]
    return stringOnlyDate + '-' + monthNames[onlyMonth] + '-' + onlyYear
  } else {
    return ''
  }
}
export const replaceAll = (str, find, replace) => {
  return str.replace(new RegExp(find, 'g'), replace)
}

export const lottieDefaultOptions = () => {
  return {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }
}
