export const LOGIN = "users/authenticate";
export const REGISTRATION = "users/register";

/**************************************** User Management **************************************/
//Users
export const USER_LIST = "users/";
export const USER_UPDATE = `users/${"id"}`;
export const USER_TECHNICIAN = `users/technician`;
export const USER_EMAIL_OTP = `users/emailotp`;
export const USER_EMAIL_VERIFY = `users/emailverify`;
export const USER_PASSWORD_RESET = `users/reset/password`;

export const USER_SEARCH_LDAP = ``;
export const USER_TRANSACTION = "core/api/v1/user";

//
export const APPLICATION_CREATE = `application/create`;
export const APPLICATION_GET = `application/getapplication?userType=${"usertype"}&id=${"uid"}`;
export const APPLICATION_RETRIEVE = `application/getapp/${"id"}`;
export const APPLICATION_UPDATE = `application/updateapplication/${"id"}`;

//Teams
export const TEAMS_LIST = "core/api/v1/team";
export const TEAM_USERS = "core/api/v1/team/searchUserInTeam";
export const ADD_USER_INTO_TEAM = "core/api/v1/team/addUser";
export const DELETE_USER_FROM_TEAM = "core/api/v1/team/removeUser";

/**************************************** Applications **************************************/
export const APPLICATION_DETAILS_FETCH = `v1/cancellation/${"appId"}`;

//Comments
export const COMMENTS_DETAILS_FETCH = `application/api/v1/comment-details?applicationId=${"appId"}`;
export const COMMENTS_DETAILS_SAVE = `application/api/v1/comment-details`;

//Audit History
export const AUDIT_HISTORY_LIST = `bpm/api/v1/audit/${"applicationId"}`;

//Card Cancellation

export const GET_CARD_CANCELLATION = `card/api/v1/cancellation/${"crmRefId"}`;
export const GET_DECISION_LIST = `card/api/v1/cancellation/decision/${"stagecode"}`;
