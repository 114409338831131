export const ALLOWED_SPECIAL_CHARACTERS = ['@', '-', '_', '.']
export const ALLOWED_ALL_SPECIAL_CHARACTERS = [
  '±',
  '!',
  '@',
  '#',
  '$',
  '%',
  '^',
  '&',
  '*',
  '(',
  ')',
  '_',
  '-',
  '+',
  '[',
  ']',
  ':',
  ';',
  '.',
  '?'
]
export const NOT_ALLOWED_NUMBER = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '0'
]
export const NOT_ALLOWED_SPECIAL_CHARACTERS = [
  '+',
  '-',
  '@',
  '&',
  '|',
  '!',
  '(',
  ')',
  '{',
  '}',
  '[',
  ']',
  '^',
  '~',
  '*',
  '?',
  ':',
  '"',
  '\\',
  '/',
  '#',
  '$',
  '%',
  ';',
  "'",
  '_',
  '.',
  '<',
  '>',
  '='
]
export const COMPONENTS_BUTTON = 'button'
export const COMPONENTS_ACTION_DISMISS = 'dismiss'
export const COMPONENTS_ACTION_DEFAULT = 'default'
