import {
  styled,
  Icons,
  Pagination as BasePagination,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Tooltip,
} from "components/material-ui";
import React, { useRef, useState, useImperativeHandle, useEffect } from "react";
import { replaceAll, getDateForDisp } from "../../utils/helper";
import CellMeasurerCache from "./CellMeasurerCache";
import clsx from "clsx";
import MTextField from "../MTextField";
import CellMeasurer from "./CellMeasurer";
import { MultiGrid } from "react-virtualized";
import "./index.css";

export const Pagination = styled(BasePagination)(() => ({
  display: "inline-flex",
  "& .MuiPaginationItem-root": {
    backgroundColor: "#FFF",
    border: "none",
    minWidth: 32,
    height: "clamp(1rem, 1.7rem, 2rem)",
    fontSize: "clamp(0.8rem, 0.9rem, 1.2rem)",
    borderRadius: 5,
  },
  "& .MuiPaginationItem-root.Mui-selected": {
    backgroundColor: "#e92220",
    color: "white",
    border: "none",
  },
  "& .MuiPaginationItem-root:hover": {
    backgroundColor: "rgb(250 92 1 / 15%) !important",
  },
}));

interface MashTableProProps {
  rows: any;
  columns: any;
  actions?: any;
  title?: string;
  isLoading?: boolean;
  footerAction?: any;
  height?: any;
  toolbar?: boolean;
  fileExport?: any;
  search?: any;
  id?: any;
  onPageChange?: any;
  totalPages?: any;
  selection?: boolean;
  paginationInfo?: any;
}
export const MashTableProFunc = React.forwardRef(
  (props: MashTableProProps, ref) => {
    const {
      rows,
      selection,
      title,
      isLoading,
      columns,
      footerAction,
      height,
      toolbar,
      fileExport,
      search,
      id,
      onPageChange,
      paginationInfo,
    } = props;

    var maxColumnWidth = 0;
    var columnLabels: any = new Object();
    var offsetHeight: any =
      document.getElementById(id) !== null
        ? document.getElementById(id)!.offsetTop
        : 0;

    // var windowOffSet = document.getElementsByTagName('body').clientHeight
    var selectedIndex: any[] = [];
    const columnsParamList = columns;
    if (selection) {
      if (
        columns.length === columnsParamList.length &&
        columnsParamList[0].field !== "checkbox"
      ) {
        columnsParamList.unshift({
          field: "checkbox",
          title: "",
          width: "3%",
          render: (rowData, rowIndex) => (
            <>
              <div className="selectCheck">
                <label className="container">
                  <input
                    type="checkbox"
                    name={"check" + rowIndex}
                    defaultChecked={selectedIndex.indexOf(rowIndex) !== -1}
                    onChange={(e) => {
                      if (selectedIndex.indexOf(rowIndex) === -1) {
                        selectedIndex.push(rowIndex);
                      } else {
                        selectedIndex.splice(
                          selectedIndex.indexOf(rowIndex),
                          1
                        );
                      }
                      selectedIndex = selectedIndex;
                      let element: any =
                        document.getElementById("selectedItemList");
                      if (element !== undefined && element !== null) {
                        element.value = selectedIndex.join(",");
                      }
                    }}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
            </>
          ),
        });
      }
    }

    for (let colList of columnsParamList) {
      if (colList.hidden === undefined || !colList.hidden) {
        columnLabels[colList.field] = colList.title;
        let lengthCharacter = colList.width;
        if (lengthCharacter > maxColumnWidth) {
          maxColumnWidth = lengthCharacter;
        }
      }
    }
    const searchRef = useRef<any>();
    const [data, setData] = useState<any>([columnLabels]);
    const [columnsList, setColumnsList] = useState<any>([]);
    const [sortStatus, setSortStatus] = useState<any>({
      sort: null,
      column: null,
    });

    useImperativeHandle(
      ref,
      () => ({
        getSelectedItem: () => {
          let element: any = document.getElementById("selectedItemList");
          let listSelectedIndex = [];
          let selectedRecords: any = [];
          try {
            if (
              element !== undefined &&
              element !== null &&
              element.value !== ""
            ) {
              listSelectedIndex = element.value.split(",");
            }
          } catch (e) {}

          selectedRecords = listSelectedIndex.map((index: any) => rows[index]);
          return selectedRecords;
        },
      }),
      [selectedIndex]
    );

    useEffect(() => {
      let tempRow: any = [...rows];
      tempRow.unshift(columnLabels);

      tempRow.forEach(function (row, index) {
        row.tableData = { rowIndex: index };
      });

      setData(tempRow);
      let searchText = searchRef !== null ? searchRef.current?.value : "";
      if (searchText != "") {
        handleSearch(searchText);
      }
    }, [rows]);

    useEffect(() => {
      let tempColumnsList: any = [];
      for (let colRows of columnsParamList) {
        if (colRows.hidden === undefined || !colRows.hidden) {
          tempColumnsList.push(colRows);
        }
      }
      setColumnsList(tempColumnsList);
    }, [columnsParamList]);

    let divObject = document.getElementById(id);
    let divWidth: any = 0;
    if (
      divObject !== undefined &&
      document.getElementById(id)?.offsetWidth !== undefined
    ) {
      divWidth = document.getElementById(id)!.offsetWidth;
    }

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };
    const _cache = new CellMeasurerCache({
      defaultWidth: 100,
      fixedHeight: false,
      fixedWidth: true,
      minHeight: 40,
      defaultHeight: 40,
    });

    const getClassName = ({ columnIndex, rowIndex }) => {
      const rowClass = rowIndex % 2 === 0 ? "evenRow" : "oddRow";

      return clsx(
        rowClass,
        `cell ${"row" + id + rowIndex}`,
        {
          ["centeredCell"]: columnIndex > 2,
        },
        {
          ["tableRow"]: rowIndex !== 0,
        }
      );
    };

    const _cellRenderer = ({ columnIndex, key, parent, rowIndex, style }) => {
      return (
        <CellMeasurer
          cache={_cache}
          columnIndex={columnIndex}
          key={key}
          parent={parent}
          rowIndex={rowIndex}
        >
          {({ measure }) => (
            <Content
              onLoad={measure}
              style={style}
              columnIndex={columnIndex}
              rowIndex={rowIndex}
            />
          )}
        </CellMeasurer>
      );
    };
    const sortRows = (columnIndex) => {
      if (
        columnsList[columnIndex].render !== undefined &&
        columnsList[columnIndex].sort === undefined
      ) {
        return;
      }
      let prop = columnsList[columnIndex].field;
      let result: any = Object.assign([], rows);
      if (sortStatus.sort === "dsc") {
        setSortStatus({
          sort: null,
          column: null,
        });
      } else {
        result.sort(function (a, b) {
          if (sortStatus.sort === null) {
            setSortStatus({
              sort: "asc",
              column: prop,
            });
            return a[prop] > b[prop] ? 1 : a[prop] < b[prop] ? -1 : 0;
          } else if (sortStatus.sort === "asc") {
            setSortStatus({
              sort: "dsc",
              column: prop,
            });
            return b[prop] > a[prop] ? 1 : b[prop] < a[prop] ? -1 : 0;
          }
        });
      }
      result.unshift(columnLabels);
      setData(result);
    };

    const Content = ({ rowIndex, columnIndex, style, onLoad }) => {
      const classNames = getClassName({ columnIndex, rowIndex });

      return (
        <>
          {rowIndex === 0 ? (
            <div
              className={`${classNames} tableHeaderCustom`}
              style={{
                ...style,
                backgroundColor: "rgb(234, 234, 234)",
                color: "rgb(0, 0, 0)",
                fontWeight: "bold",

                whiteSpace: "break-spaces",
                cursor: "pointer",
              }}
              onClick={(e) => sortRows(columnIndex)}
            >
              <span className="columnArrowLabel">
                {data !== null &&
                data[rowIndex][Object.keys(data[rowIndex])[columnIndex]] !==
                  undefined &&
                data[rowIndex][Object.keys(data[rowIndex])[columnIndex]] !==
                  null &&
                data[rowIndex][Object.keys(data[rowIndex])[columnIndex]][
                  "rowIndex"
                ] === undefined
                  ? data[rowIndex][Object.keys(data[rowIndex])[columnIndex]]
                  : ""}
              </span>
              {sortStatus.sort !== null &&
              sortStatus.column === columnsList[columnIndex].field ? (
                <span className="columnArrowSize">
                  {sortStatus.sort === "asc" && <Icons.ArrowUp />}
                  {sortStatus.sort === "dsc" && <Icons.ArrowDown />}
                </span>
              ) : (
                ""
              )}
            </div>
          ) : (
            <div
              className={classNames}
              style={{
                ...style,
              }}
            >
              {columnsList[columnIndex].render !== undefined
                ? columnsList[columnIndex].render(
                    data[rowIndex],
                    data[rowIndex].tableData.rowIndex !== undefined
                      ? data[rowIndex].tableData.rowIndex - 1
                      : rowIndex - 1
                  )
                : data[rowIndex][columnsList[columnIndex].field]}
            </div>
          )}
        </>
      );
    };
    const columnWidth = ({ index }) => {
      let columnWid: any = columnsList[index].width;
      let actualWidth = 0;
      if (typeof columnWid === "string") {
        actualWidth =
          ((divWidth - 10) / 100) * parseInt(columnWid.replace("%", ""));
      } else {
        actualWidth = columnWid;
      }
      if (isNaN(actualWidth)) {
        return 100;
      } else {
        return actualWidth;
      }
    };

    // @ts-ignore
    const _rowGetter = ({ index }) => {
      return data.get(index % data.size);
    };

    const handleSearch = (event) => {
      let value = "";
      if (typeof event === "string") {
        value = event.toLowerCase();
      }
      if (typeof event === "object") {
        value = event.target.value.toLowerCase();
      }

      let columnField: any[] = [];
      columnsParamList.map((column) =>
        columnField.push({
          field: column.field,
          searchValue:
            column.searchValue !== undefined ? column.searchValue : null,
        })
      );

      let newList: any[] = [];
      newList = rows.filter((row) =>
        columnField.some(
          (k) =>
            String(row[k.field]).toLowerCase().includes(value) ||
            (k.searchValue !== null &&
              k.searchValue[row[k.field]].toLowerCase().includes(value))
        )
      );
      newList.unshift(columnLabels);
      setData(newList);
    };

    function convertToCSV(objArray) {
      var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
      var str = "";

      for (var i = 0; i < array.length; i++) {
        var line = "";
        for (var index in array[i]) {
          if (line != "") line += ",";
          line += array[i][index];
        }
        str += line + "\r\n";
      }

      return str;
    }
    function exportCSVFile(headers, items, fileTitle) {
      if (headers) {
        items.unshift(headers);
      }

      // Convert Object to JSON
      var jsonObject = JSON.stringify(items);
      var csv = convertToCSV(jsonObject);
      var exportedFilenmae = fileTitle + ".csv" || "export.csv";

      var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

      var link = document.createElement("a");
      if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", exportedFilenmae);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
    function exportDownload(e) {
      var fileTitle =
        fileExport != undefined && fileExport.exportFileName != undefined
          ? fileExport.exportFileName
          : "Report";
      let tempRows = rows;
      let csvRows: any[] = [];
      tempRows.map((rows) => {
        csvRows.push({
          name: rows.name !== undefined && rows.name !== null ? rows.name : "-",
          applicationId:
            rows.applicationId !== undefined && rows.applicationId !== null
              ? rows.applicationId
              : "-",
          module:
            rows.module !== undefined && rows.module !== null
              ? rows.module
              : "-",
          customerName:
            rows.customerName !== undefined && rows.customerName !== null
              ? rows.customerName
              : "-",
          productName: replaceAll(rows.productName, "</br>", " // "),
          cif: rows.cif !== undefined && rows.cif !== null ? rows.cif : "",
          due:
            rows.due !== undefined && rows.due !== null && rows.due !== ""
              ? getDateForDisp(new Date(rows.due))
              : "-",
          assignee:
            rows.assignee !== undefined && rows.assignee !== null
              ? rows.assignee
              : "-",
          source:
            rows.source !== undefined && rows.source !== null
              ? rows.source
              : "-",
        });
      });
      if (e.target.innerText === "Export as PDF")
        console.log(columnLabels, csvRows, fileTitle);
      else exportCSVFile(columnLabels, csvRows, fileTitle); // call the exportCSVFile() function to process the JSON and trigger the download
    }

    const loadMenuItem = (label) => {
      return (
        <MenuItem
          onClick={(e) => {
            handleClose();
            exportDownload(e);
          }}
        >
          <ListItem>
            <ListItemText>{label}</ListItemText>
          </ListItem>
        </MenuItem>
      );
    };

    return (
      <Grid
        container
        item={true}
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        id={id}
      >
        {toolbar === undefined && (
          <Grid
            container
            item={true}
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            alignContent="flex-end"
          >
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              {title !== undefined && title !== null && title !== "" ? (
                <span className="tableTitle">{title}</span>
              ) : (
                ""
              )}
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}></Grid>
            <Grid
              item
              xs={5}
              sm={5}
              md={5}
              lg={5}
              xl={5}
              alignContent="flex-end"
            >
              {search == undefined || search ? (
                <>
                  <MTextField
                    name={"Mashtable Search"}
                    onInputChange={(e) => {
                      handleSearch(e);
                    }}
                    inputRef={searchRef}
                    placeholder={"Search"}
                    style={{ marginBottom: 5 }}
                  />
                </>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        )}
        {isLoading && (
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="tableLoadingDiv"
            style={{
              height:
                height !== undefined
                  ? height
                  : (window.innerHeight - offsetHeight - (search ? 85 : 40)) /
                    2,
              paddingTop:
                height !== undefined
                  ? height / 2
                  : (window.innerHeight - offsetHeight - (search ? 85 : 40)) /
                    2,
            }}
          >
            {<CircularProgress size={40} />}
          </Grid>
        )}
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ overflowX: rows.length === 0 ? "unset" : "auto" }}
        >
          <MultiGrid
            cellRenderer={_cellRenderer}
            columnWidth={columnWidth}
            columnCount={columnsList.length}
            fixedRowCount={1}
            height={
              height !== undefined
                ? height
                : window.innerHeight - offsetHeight - (search ? 85 : 40)
            }
            rowHeight={_cache.rowHeight}
            overscanColumnCount={0}
            overscanRowCount={2}
            rowCount={data.length}
            width={divWidth}
            rowGetter={_rowGetter}
          />
        </Grid>
        {rows.length === 0 && (
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="noRecordFoundMainDiv"
            style={{
              height:
                height !== undefined ? height : `calc(100vh - ${offsetHeight}`,

              marginTop: 40,
            }}
          >
            <div className="noRecordFoundChilDiv">
              <span style={{ display: "block", color: "#969696" }}>
                No Records Found
              </span>
            </div>
          </Grid>
        )}

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{
            height: 36,
            background: "#f3f2f7",
            padding: "5px 10px 5px 5px",
            color: "black",
            fontSize: 12,
            textAlign: "right",
          }}
        >
          <Box className="pagiBox">
            <>
              {footerAction !== undefined
                ? footerAction.map((buttonRows, index) => buttonRows.render)
                : ""}
              {fileExport !== undefined && (
                <>
                  <Box className="pagiBox" onClick={handleClick}>
                    <Tooltip title={"Export"} placement="right" arrow={true}>
                      <div className="tooltip-inner-wrapper">
                        <Button
                          className="smallButton iconButton pt10"
                          variant="contained"
                          color="secondary"
                          size="small"
                          style={{
                            marginLeft: "5px",
                            marginTop: "1px",
                          }}
                        >
                          Export
                        </Button>
                      </div>
                    </Tooltip>
                  </Box>
                  <Menu
                    id="list"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    // getContentAnchorEl={null}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    className="menuBottom0"
                  >
                    <>
                      {Boolean(anchorEl) && (
                        <>
                          <MenuList className="menuBottom0">
                            {fileExport["csv"] && loadMenuItem("Export as CSV")}

                            <Divider />
                            {fileExport["pdf"] && loadMenuItem("Export as PDF")}
                          </MenuList>
                        </>
                      )}
                    </>
                  </Menu>
                </>
              )}
            </>
          </Box>

          {paginationInfo !== undefined && paginationInfo !== null ? (
            <>
              {paginationInfo.totalRecords >
              paginationInfo.noOfRecordsPerPage ? (
                <Grid
                  container
                  item={true}
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                >
                  <Grid
                    item
                    xs={6}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    style={{ textAlign: "left", paddingLeft: 5 }}
                  >
                    <span style={{ verticalAlign: "sub" }}>
                      Results:{" "}
                      {(paginationInfo.currentPage - 1) *
                        paginationInfo.noOfRecordsPerPage +
                        1}{" "}
                      -{" "}
                      {(paginationInfo.currentPage - 1) *
                        paginationInfo.noOfRecordsPerPage +
                        paginationInfo.noOfRecordsPerPage}{" "}
                      of {paginationInfo.totalRecords} Records
                    </span>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Pagination
                      count={paginationInfo.totalPages}
                      variant="outlined"
                      shape="rounded"
                      onChange={(e, key) => {
                        selectedIndex = [];
                        let element: any =
                          document.getElementById("selectedItemList");
                        if (element !== undefined && element !== null) {
                          element.value = selectedIndex.join(",");
                        }
                        onPageChange(key);
                      }}
                      page={paginationInfo.currentPage}
                    />
                  </Grid>
                </Grid>
              ) : (
                <span style={{ verticalAlign: "sub" }}>
                  Showing {data.length - 1} Records
                </span>
              )}
            </>
          ) : (
            <span style={{ verticalAlign: "sub" }}>
              Showing {data.length - 1} Records
            </span>
          )}
        </Grid>
        <input type="hidden" id={"selectedItemList"} />
      </Grid>
    );
  }
);
const MashTablePro = React.memo(MashTableProFunc);
export default MashTablePro;
