import Lottie from "react-lottie";
import animationData from "assets/lottie/logoAnimatinrj.json";
import { Box, Backdrop } from "@mui/material";

// const useStyles = makeStyles((theme: Theme) => ({
//   root: {
//     zIndex: 10000,
//     opacity: "0.7 !important",
//     backgroundColor: theme?.palette?.common?.white,
//   },
//   lottieStyle: {
//     "& path": {
//       fill: theme.palette.secondary.main,
//     },
//   },
// }));

interface LoaderProps {
  enable: boolean;
}

const Loader = (props: LoaderProps) => {
  const { enable } = props;

  /* Lottie Configuration */
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  if (enable) {
    return (
      <Backdrop
        style={{
          zIndex: "10000",
          opacity: "0.7",
          backgroundColor: "white",
        }}
        open={true}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Box style={{ width: 150 }}>
            <Lottie options={defaultOptions} />
          </Box>
        </Box>
      </Backdrop>
    );
  }
  return null;
};

Loader.defaultProps = {
  enable: false,
};

export default Loader;
