import { Switch as SwitchUnStyled, styled } from "components/material-ui";
import { SwitchProps, SwitchStyleProps } from "./types";

export const MUISwitch = styled(SwitchUnStyled)(
  ({ disabled }: SwitchStyleProps) => ({
    zIndex: 0,
    verticalAlign: "middle",
    width: 39,
    height: 16,
    padding: 0,
    "& .MuiSwitch-thumb": {
      borderRadius: 2,
      backgroundColor: "white",
      width: 16,
      height: 14,
      position: "relative",
      top: "-8px",
      left: "-7px",
    },
    "& .MuiSwitch-track ": {
      borderRadius: 2,
      opacity: 0.1,
      backgroundColor: "black",
      height: 17,
      width: 76,
    },
    "& .Mui-checked+.MuiSwitch-track": {
      borderRadius: 2,
      opacity: "1 !important",
      backgroundColor: disabled ? "#979797 !important" : "black !important",
      height: 17,
      width: 76,
    },
  })
);

const MSwitch = (props: SwitchProps) => {
  let { checked, onChange, name, disabled = false } = props;

  return (
    <MUISwitch
      name={name}
      checked={checked}
      onChange={onChange}
      disabled={disabled}
    />
  );
};

export default MSwitch;
