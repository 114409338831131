import {
  Dialog,
  Box,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogActions,
  Icons,
} from "components/material-ui";
import { MButton } from "components/ui";
import create from "zustand";

type ConfirmDialogStore = {
  message: string;
  onConfirm?: () => void;
  close: () => void;
};

const useConfirmDialogStore = create<ConfirmDialogStore>((set) => ({
  message: "",
  onConfirm: undefined,
  close: () => set({ onConfirm: undefined }),
}));

export const confirmDialog = (message: string, onConfirm: () => void) => {
  useConfirmDialogStore.setState({
    message,
    onConfirm,
  });
};

const ConfirmDialog = () => {
  const { message, onConfirm, close } = useConfirmDialogStore();
  return (
    <Dialog open={Boolean(onConfirm)} onClose={close} maxWidth="sm" fullWidth>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <DialogTitle>Confirm</DialogTitle>
        <IconButton onClick={close}>
          <span style={{ fontSize: 20, marginRight: 10 }}>
            <Icons.CloseIcon />
          </span>
        </IconButton>
      </Box>
      <DialogContent>
        <span>{message}</span>
      </DialogContent>
      <DialogActions>
        <MButton theme={"dismiss"} onClick={close}>
          Cancel
        </MButton>
        <MButton
          onClick={() => {
            if (onConfirm) {
              onConfirm();
            }
            close();
          }}
        >
          Confirm
        </MButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
