import { useState, useEffect } from "react";
import BaseDatePicker from "react-datepicker";
import {
  InputAdornment,
  Icons,
  TextField,
  styled,
  Grid,
} from "components/material-ui";
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";
import { DateFieldProps } from "./type";
import { Controller } from "react-hook-form";

export const MDatePicker = styled(BaseDatePicker)(({}) => ({
  "& .MuiFormControl-root .MuiInput-root  input": {
    width: "100%",
    fontFamily: "Google-Sans !important",
  },
  "& .MuiFormControl-root .MuiInput-root": {
    "&:before": {
      border: "none",
    },
    "&:after": {
      border: "none",
    },
    "&:hover": {
      border: "1px solid rgba(0, 0, 0, 0.23)",
    },
  },
}));

const MDatefield = (props: DateFieldProps) => {
  const {
    inputValue,
    onInputChange,
    errorTrigger,
    maxDate,
    minDate,
    disabled,
    label,
    grid,
    required,
    control,
    name,
    ruleList,
    isClearable,
  } = props;
  const [fieldError, setFieldError] = useState({
    status: false,
    message: "",
  });
  let rules: object = {};

  let styles: object = {};
  styles = {
    style: {
      border: "1px solid rgba(0, 0, 0, 0.23)",
      borderRadius: 5,
      padding: 5,
      height: "inherit",
    },
  };

  const setFieldErrorData = (status) => {
    if (status) {
      if (!fieldError.status) {
        setFieldError({
          status: true,
          message: "Required",
        });
      }
    }
    if (!status) {
      if (fieldError.status) {
        setFieldError({
          status: false,
          message: "",
        });
      }
    }
  };
  if (required) {
    rules = {
      required: " Required",
      ...ruleList,
    };
  } else {
    rules = {
      required: false,
      ...ruleList,
    };
  }

  useEffect(() => {
    if (errorTrigger) {
      setFieldErrorData(true);
    } else {
      setFieldErrorData(false);
    }
  }, [errorTrigger]);
  return (
    <Grid
      item
      xs={grid}
      sm={grid}
      md={grid}
      lg={grid}
      xl={grid}
      style={{ paddingLeft: 5, paddingRight: 5, marginTop: 5 }}
    >
      {control !== undefined && control !== null ? (
        <Controller
          name={name}
          control={control}
          render={({
            field: { onChange, value, onBlur },
            fieldState: { error },
          }) => (
            <MDatePicker
              selected={value}
              onChange={(e) => {
                onChange(e);
                if (onInputChange !== null) {
                  onInputChange(e);
                }
              }}
              onBlur={onBlur}
              peekNextMonth
              showMonthDropdown
              dateFormat="dd/MMM/yyyy"
              showYearDropdown
              dropdownMode="select"
              maxDate={maxDate}
              minDate={minDate}
              className={disabled ? "disabledInput" : ""}
              disabled={disabled}
              isClearable={isClearable}
              popperProps={{
                positionFixed: true,
              }}
              customInput={
                <TextField
                  label={label}
                  variant={"standard"}
                  value={inputValue}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                    required: required,
                  }}
                  InputProps={{
                    ...styles,
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icons.CalendarIcon />
                      </InputAdornment>
                    ),
                  }}
                  size="small"
                  type={"text"}
                  required={required}
                  error={error !== undefined ? Boolean(error) : false}
                  helperText={
                    error
                      ? error.message !== ""
                        ? label + error.message
                        : ""
                      : null
                  }
                />
              }
            />
          )}
          rules={rules}
        />
      ) : (
        <MDatePicker
          selected={inputValue}
          onChange={(e) => {
            if (onInputChange !== null) {
              onInputChange(e);
            }
          }}
          placeholderText={
            errorTrigger !== null
              ? fieldError.status
                ? fieldError.message
                : ""
              : ""
          }
          peekNextMonth
          showMonthDropdown
          dateFormat="dd/MMM/yyyy"
          showYearDropdown
          dropdownMode="select"
          maxDate={maxDate}
          minDate={minDate}
          className={disabled ? "disabledInput" : ""}
          disabled={disabled}
          popperProps={{
            positionFixed: true,
          }}
          isClearable={isClearable}
          customInput={
            <TextField
              label={label}
              variant={"standard"}
              value={inputValue}
              fullWidth
              className={`${disabled ? "disabledInput" : ""} ${
                errorTrigger !== null && fieldError.status
                  ? "errorMessageIn"
                  : ""
              }`}
              error={fieldError.status}
              InputLabelProps={{
                shrink: true,
                required: required,
              }}
              InputProps={{
                ...styles,
                endAdornment: (
                  <InputAdornment position="end">
                    <Icons.CalendarIcon />
                  </InputAdornment>
                ),
              }}
              size="small"
              type={"text"}
              required={required}
            />
          }
        />
      )}
    </Grid>
  );
};

MDatefield.defaultProps = {
  maxDate: null,
  minDate: null,
  required: false,
  onInputChange: null,
  inputValue: null,
  errorTrigger: null,
  ruleList: {},
};

export default MDatefield;
