export const DEFAULT_VALUE = {
  TIMEOUT: 240000,
  CONTENT_TYPE: "application/json",
};
export enum ERROR_TYPES {
  API_ERROR = "API_ERROR",
  BAD_REQUEST = "BAD_REQUEST",
  INTERNET_NETWORK_ERROR = "INTERNET_NETWORK_ERROR",
  TIMEOUT = "TIMEOUT",
  INTERNAL_SERVER_ERROR = "INTERNAL_SERVER_ERROR",
  GLOBAL_NETWORK_ERROR = "GLOBAL_NETWORK_ERROR",
  GLOBAL_SERVER_ERROR = "GLOBAL_SERVER_ERROR",
  UNAUTHORIZED = "UNAUTHORIZED",
}
export type networkErrorType = {
  data?: {
    errorId?: string;
    errorDetails?: string;
    uriPath?: string;
    errorMessage?: string;
  };
  errorType: ERROR_TYPES;
};
