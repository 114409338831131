const LOGIN = "/";
const SIGNUP = "/signup";

const FORGOTPASSWORD = "/forgotpassword";
const ENTRY = "/entry";
const DASHBOARD = "/dashboard";
const NEW_REQUEST = "/new_request";
const VIEW_APPLICATION = "/view_application";
const UPDATE_APPLICATION = "/update_application";
const USERS = "/management/users";
const CREATE_USERS = "/management/create_users";
const TEAMS = "/management/teams";

const MY_TASKLIST = "/management/tasklist/my";
const REASSIGN_TASKLIST = "/management/tasklist/reassign";

const SEARCH_APP = "/search/application";

const CANCELLATION_LINK = "/application/cancellation";

export {
  LOGIN,
  SIGNUP,
  FORGOTPASSWORD,
  DASHBOARD,
  NEW_REQUEST,
  VIEW_APPLICATION,
  UPDATE_APPLICATION,
  ENTRY,
  USERS,
  TEAMS,
  MY_TASKLIST,
  REASSIGN_TASKLIST,
  SEARCH_APP,
  CANCELLATION_LINK,
  CREATE_USERS,
};
